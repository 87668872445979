import { HttpRastreadorService } from '../services/http-rastreador.service';
import { Foto } from './../../../model/produto.model';
import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AdminGlobalService } from '../services/admin-global.service';
import { UploadFotoProdutoComponent } from '../../admin-home/estoque/upload-foto-produto/upload-foto-produto.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmarFinalizarCadastroProdutoDialogComponent } from '../estoque/confirmar-finalizar-cadastro-produto-dialog/confirmar-finalizar-cadastro-produto-dialog.component';
import {Router, ActivatedRoute} from "@angular/router";
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-cadastrar-produtos',
  templateUrl: './cadastrar-produtos.component.html',
  styleUrls: ['./cadastrar-produtos.component.css']
})
export class CadastrarProdutosComponent implements OnInit {

  nomeProduto:string = '';
  descricaoHtmlContent:any = '';
  fonteInformacaoProduto:string = '';
  fotosProduto:Foto[] = [];
  idNovoProduto:any = null;
  showProgressbar:boolean = false;
  editMode:boolean = true;
  statusProduto:any = 0;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    placeholder: 'Insira sua descrição do produto aqui!',
    translate: 'no',
    defaultFontName: 'Arial',
    uploadUrl:'http://127.0.0.1:8000/api/uploadimagemproduto'
  };

  constructor(
    private route: ActivatedRoute,
    public _snackBar: MatSnackBar,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService: AdminGlobalService,
    public dialog: MatDialog,
    private router: Router
  ) { }
  ngOnInit() {
    this.adminGlobalService.setPageTitle('Cadastrar produto');
    let idprodutoRota = this.route.snapshot.paramMap.get("idproduto");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(idprodutoRota != null){
      this.editMode = false;
      this.adminGlobalService.setPageTitle('Editar produto');
      this.idNovoProduto = idprodutoRota;
      this.buscarDadosProduto();
    }
  }
  openDialogUploadFotoProduto():void{
    const dialogRef = this.dialog.open(UploadFotoProdutoComponent, {
      width: '550px',
      data: {id_produto:this.idNovoProduto}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        let fotos:Foto = {
          foto_id:1,
          foto_id_produto:this.idNovoProduto,
          foto_ordem:1,
          foto_url:"https://rast.thothcompany.com.br/uploads/images/produto/" + result
        }
        this.fotosProduto.push(fotos);
        //this.cadastrarProduto();
      }
    });

  }
  removerImagem(index:number){
    this.fotosProduto.splice(index,1);
  }
  salvarProduto(){
    this.showProgressbar = true;
    this.httpRastreadorService.criarNovoProduto(
      this.idNovoProduto,
      this.nomeProduto,
      this.descricaoHtmlContent,
      this.fonteInformacaoProduto,
      this.fotosProduto
      ).subscribe((data)=>{
        this.showProgressbar = false;
        let d:any = data;
        this.idNovoProduto = d.rastreio_produto_id;
        this.openSnackBar("Produto salvo com sucesso!", "fechar");
    });
  }

  removerProdutoCadastrado(){

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover produto', 'pergunta':'Você realmente deseja remover este produto?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.httpRastreadorService.removerProdutoCadastrado(this.idNovoProduto).subscribe((d:any)=>{
            this.openSnackBar("Produto removido com sucesso!", "fechar");
            window.history.back();
        });
      }else{
        this.openSnackBar("Não foi possível remover este produto!", "fechar");
      }
    });
  }

  buscarDadosProduto(){
    this.showProgressbar = true;
    this.httpRastreadorService.criarNovoProduto(
      this.idNovoProduto,
      this.nomeProduto,
      this.descricaoHtmlContent,
      this.fonteInformacaoProduto,
      this.fotosProduto
      ).subscribe((data)=>{
        this.editMode = true;
        this.showProgressbar = false;
        let d:any = data;
        this.idNovoProduto = d.rastreio_produto_id;
        this.nomeProduto = d.rastreio_produto_nome;
        this.descricaoHtmlContent = d.rastreio_produto_descricao;
        this.fonteInformacaoProduto = d.rastreio_produto_url_fonte;
        this.statusProduto = d.rastreio_produto_status;
        if(d.rastreio_produto_imagem != undefined){
          let fotos:Foto = {
            foto_id:1,
            foto_id_produto:this.idNovoProduto,
            foto_ordem:1,
            foto_url:d.rastreio_produto_imagem
          }
          this.fotosProduto.push(fotos);
        }
    });
  }
  openDialogConfirmarFinalizarCadastro(){
    const dialogRef = this.dialog.open(ConfirmarFinalizarCadastroProdutoDialogComponent, {
      width: '550px',
      data: {id_produto:this.idNovoProduto}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.finalizarCadastro();
      }
    });
  }
  finalizarCadastro(){
    this.showProgressbar = true;
    this.httpRastreadorService.criarNovoProduto(
      this.idNovoProduto,
      this.nomeProduto,
      this.descricaoHtmlContent,
      this.fonteInformacaoProduto,
      this.fotosProduto
      ).subscribe((data)=>{
        let d:any = data;
        this.idNovoProduto = d.rastreio_produto_id;
        this.httpRastreadorService.finalizarCadastroProduto(d.rastreio_produto_id).subscribe((data) =>{
          this.showProgressbar = false;
          this.openSnackBar("Produto salvo e finalizado com sucesso!", "fechar");
          //this.router.navigate(['admin/produtos']);
          window.history.back();
        });
    });

  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
