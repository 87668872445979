import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { Compra } from 'src/app/model/compra.model';
import { AdminGlobalService } from '../services/admin-global.service';

@Component({
  selector: 'app-rastreio-editar-compra',
  templateUrl: './rastreio-editar-compra.component.html',
  styleUrls: ['./rastreio-editar-compra.component.css']
})
export class RastreioEditarCompraComponent implements OnInit {

  public NF:string = "";
  public progessbarSalvarDados:boolean = false;

  constructor(
    public dialog: MatDialog,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService,
    public dialogRef: MatDialogRef<RastreioEditarCompraComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { 
    this.NF = data.compra.compra_nota_fiscal;
  }
  ngOnInit() {
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }
  salvarDados(){
    this.progessbarSalvarDados = true;
    this.data.compra.compra_nota_fiscal = this.NF;
    this.httpRastreadorService.atualizarDadosCompra(this.data.compra).subscribe((data)=>{
      this.dialogRef.close();
      this.adminGlobalService.openSnackBar("Atualizado com sucesso!", "fechar");
      this.progessbarSalvarDados = false;
    }, error=>{
      this.progessbarSalvarDados = false;
      this.adminGlobalService.openSnackBar("Erro ao tentar atualizar os dados, verifique sua conexão com a internet!", "fechar");
    });
  }
}
