import { Component, OnInit, Inject } from '@angular/core';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminGlobalService } from '../services/admin-global.service';
import { Produto, ProdutoEstoque } from 'src/app/teste/teste/lista.model';

@Component({
  selector: 'app-buscar-produto-estoque-dialog',
  templateUrl: './buscar-produto-estoque-dialog.component.html',
  styleUrls: ['./buscar-produto-estoque-dialog.component.css']
})
export class BuscarProdutoEstoqueDialogComponent implements OnInit {

  termoBusca:string = "";
  progressbar:boolean = false;
  tempArrProdutos:ProdutoEstoque[] = [];
  arrProdutos:ProdutoEstoque[] = [];
  buscaRealizada:boolean = false;

  constructor(
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<BuscarProdutoEstoqueDialogComponent>,
    public adminGlobalService:AdminGlobalService,
    @Inject(MAT_DIALOG_DATA) public data:ProdutoEstoque[]
  ) { }

  ngOnInit() {
  }
  closeWindow(status:boolean): void {
    this.dialogRef.close(status);
  }
  getProdutos(){
    this.progressbar = true;
    this.httpRastreadorService.getListaProdutosEstoque(this.termoBusca, 'id', 'ASC').subscribe((dataResult:any)=>{
      
      this.tempArrProdutos = dataResult;
      this.tempArrProdutos.map(localData=>{
        let count:number = 0;
        this.data.map(previousData=>{
          if(previousData.id_lote == localData.id_lote){
            count++;
          }
        });
        if(count == 0){
          this.arrProdutos.push(localData);
        }
      });
      this.progressbar = false;
      this.buscaRealizada = true;
    });

  }

  selecionarProduto(produto:Produto){
    this.dialogRef.close(produto);
  }
  
}
