import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';
import { map } from  'rxjs/operators';
import { ApiDataService } from 'src/app/admin/admin-home/services/api-data.service';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions, Headers } from "@angular/http";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService extends ApiDataService{

  //SERVER_URL: string = "http://127.0.0.1:8000/api/uploadimagemproduto";

  requestOptions: any;

  constructor(private httpClient: HttpClient) {
    super();
  }


  public uploadImagemProduto(data, userId) {

    let uploadURL = `${this.API_URL}uploadimagemproduto`;

    
   this.requestOptions = new RequestOptions({ headers: this.reqHeader });

    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


  public uploadArquivoPlu(data){

    let uploadURL = `${this.API_URL}uploadArquivoPlu`;

   this.requestOptions = new RequestOptions({ headers: this.reqHeader });

    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
