import { Injectable } from '@angular/core';
import { ApiDataService } from 'src/app/admin/admin-home/services/api-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { Router } from '@angular/router';
import { PerfilEmpresa } from '../../../rastreador/model/util.model';
import { Cliente } from 'src/app/model/cliente.model';
import { Venda } from 'src/app/model/venda.model';
import { AutenticacaoService } from './autenticacao.service';
import { Api } from './http-admin.service';
import { RequestOptions } from '@angular/http';
import { CodigoBarras } from 'src/app/model/codigo-barras.model';
import { NovaVenda } from 'src/app/teste/teste/lista.model';
import { ConfigStoreService } from './config-store.service';


@Injectable({
  providedIn: 'root'
})
export class HttpRastreadorService {

  readonly api: Api = {
    url: "http://192.168.137.1:8000/api/"
  }
  public reqHeader: any;
  readonly requestOptions: any;

  constructor(
    public autenticacaoService: AutenticacaoService,
    private http: HttpClient,
    public config: ConfigStoreService,
    private router: Router) {
    this.api.url = config.API_SERVER_URL;
  }
  getRequestOption(): RequestOptions {
    this.reqHeader = new HttpHeaders({
      'Access-Control-Allow-Origin': 'https://localhost:4200',
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.autenticacaoService.tokenAutenticacao.access_token,
      'Accept': '*/*',
    });
    return new RequestOptions({ headers: this.reqHeader });
  }
  public checarAutenticacaoUsuario() {
    this.checkUserAutenticacao().subscribe((data: any) => {
      if (data.status === true) {
        console.log("Autenticacao : Logged true");
      } else if (data.status === false) {
        this.sairSistema();
        console.log("Autenticacao : Not Logged");
      }
    }, error => {
      console.log("Autenticacao : Error check");
    });
  }
  public getLoggedDataUser() {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getLoggedDataUser/", requestOptions);
  }
  public login(password, email) {
    let dataLogin = {
      password: password,
      email: email
    }
    return this.http.post(this.api.url + "auth/login/", dataLogin);
  }
  public sairSistema() {
    try {
      let data: any = JSON.parse(atob(localStorage.getItem('access_data')));
      this.fazerLogout(data.p, data.p).subscribe((data) => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_data');
        localStorage.removeItem('logged_data_user');
        this.router.navigate(['/login']);
      }, error => {
        localStorage.removeItem('logged_data_user');
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_data');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 500);
      });
    } catch (error) {
      localStorage.removeItem('logged_data_user');
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_data');
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 500);
    }
  }
  public checkUserAutenticacao() {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "checkAutenticacao", requestOptions);
  }
  public fazerLogout(password, email) {
    let requestOptions: any = this.getRequestOption();
    let dataLogin = {
      password: password,
      email: email
    }
    return this.http.post(this.api.url + "auth/logout", dataLogin, requestOptions);
  }
  getProdutoGerarEtiqueta(id_produto: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutoGerarEtiqueta?rastreio_id=" + id_produto, requestOptions);
  }
  getProdutoGerarEtiquetaPorVenda(id_produto: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutoGerarEtiquetaPorVenda?venda_id=" + id_produto, requestOptions);
  }
  getProdutoGerarEtiquetaPorProduto(arrProdutos: any) {
    let arr = JSON.stringify(arrProdutos);
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutoGerarEtiquetaPorProduto?produtos=" + arr, requestOptions);
  }
  getDataRastreioProduto(idRastreio) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "rastrearproduto?idRastreio=" + idRastreio, requestOptions);
  }
  criarNovoProduto(idProduto, nomeProduto, descricaoHtmlContent, fonteInformacaoProduto, fotosProduto) {
    let requestOptions: any = this.getRequestOption();
    let data = {
      idProduto: idProduto,
      nomeProduto: nomeProduto,
      descricaoHtmlContent: descricaoHtmlContent,
      fonteInformacaoProduto: fonteInformacaoProduto,
      fotosProduto: fotosProduto
    }
    return this.http.post(this.api.url + "cadastrarnovoproduto", data, requestOptions);
  }
  finalizarCadastroProduto(idProduto) {
    let requestOptions: any = this.getRequestOption();
    let data = {
      idProduto: idProduto
    }
    return this.http.post(this.api.url + "finalizarcadastronovoproduto", data, requestOptions);
  }
  getListaProdutos(paginaAtual: number, itensPorPagina: number, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "rastreamentoListaProdutos?page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getUltimoLoteProdutoEspecifico(idProduto: number) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getUltimoLoteProdutoEspecifico?produto=" + idProduto, requestOptions);
  }
  getListaRastreios(paginaAtual: number, itensPorPagina: number, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "rastreamentoListaRastreios?page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getListaProdutosTeste(termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "rastreamentoListaProdutosTeste?termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getListaFornecedor(paginaAtual: number, itensPorPagina: number, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "rastreamentoListaFornecedor?page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getListaCompra(tipoFiltro: string, dataBuscaInicial: string, dataBuscaFinal: string, paginaAtual: number, itensPorPagina: string, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getListaCompras?dataBuscaFinal=" + dataBuscaFinal + "&dataBuscaInicial=" + dataBuscaInicial + "&tipoFiltro=" + tipoFiltro + "&page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getListaVenda(tipoFiltro: string, dataBuscaInicial: string, dataBuscaFinal: string, paginaAtual: number, itensPorPagina: string, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getListaVendas?dataBuscaFinal=" + dataBuscaFinal + "&dataBuscaInicial=" + dataBuscaInicial + "&tipoFiltro=" + tipoFiltro + "&page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getProdutosVenda(idVenda: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutosVenda?venda_id=" + idVenda, requestOptions);

  }
  getProdutosCompra(idVenda: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutosCompra?compra_id=" + idVenda, requestOptions);

  }
  cadastrarFornecedor(fornecedor: Fornecedor) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "cadastrarnovofornecedor", fornecedor, requestOptions);
  }
  atualizarFornecedor(fornecedor: Fornecedor) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "atualizarfornecedor", fornecedor, requestOptions);
  }
  getListaCliente(paginaAtual: number, itensPorPagina: number, termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "clientes?page=" + paginaAtual + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  cadastrarCliente(cliente: Cliente) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "cadastrarnovocliente", cliente, requestOptions);
  }
  atualizarCliente(cliente: Cliente) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "atualizarcliente", cliente, requestOptions);
  }
  buscarFornecedorAtualizar(idFornecedor: number) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getFornecedorAtualizar?idFornecedor=" + idFornecedor, requestOptions);

  }
  buscarClienteAtualizar(idCliente: number) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getClienteAtualizar?idCliente=" + idCliente, requestOptions);

  }
  salvarVenda(venda: Venda) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "salvarVenda", venda, requestOptions);
  }
  salvarCompra(compra: Compra) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "salvarCompra", compra, requestOptions);
  }
  getCompra(idCompra): any {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getCompra?id_compra=" + idCompra, requestOptions);
  }
  getVenda(idVenda): any {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getVenda?id_venda=" + idVenda, requestOptions);
  }
  getListaEstados() {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getListaEstados", requestOptions);
  }
  getListaMunicipios(estado: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getListaMunicipios?estado=" + estado, requestOptions);
  }
  getMunicipioById(idMunicipio: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getMunicipioById?idMunicipio=" + idMunicipio, requestOptions);
  }
  criarContaUsuario(perfilEmpresa: PerfilEmpresa) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "criarContaUsuario", perfilEmpresa, requestOptions);
  }
  atualizarSenha(senhaAtual: string, novaSenha: string) {
    let requestOptions: any = this.getRequestOption();
    let data = {
      senhaAtual: senhaAtual,
      novaSenha: novaSenha
    }
    return this.http.put(this.api.url + "atualizarSenhaUsuario", data, requestOptions);
  }
  atualizarDadosCliente(perfilEmpresa: PerfilEmpresa) {
    let requestOptions: any = this.getRequestOption();
    return this.http.put(this.api.url + "atualizarDadosCliente", perfilEmpresa, requestOptions);
  }
  obterCoordenadasPorEndereco(endereco: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "obterCoordenadasPorEndereco?endereco=" + endereco, requestOptions);
  }
  atualizarDadosCompra(compra: Compra) {
    let requestOptions: any = this.getRequestOption();
    return this.http.put(this.api.url + "atualizarDadosCompra", compra, requestOptions);
  }
  atualizarDadosVenda(venda: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.put(this.api.url + "atualizarDadosVenda", venda, requestOptions);
  }
  atualizarProdutoComprado(compraProduto: CompraProduto) {
    let requestOptions: any = this.getRequestOption();
    return this.http.put(this.api.url + "atualizarProdutoComprado", compraProduto, requestOptions);
  }
  removerProdutoComprado(compraProduto: CompraProduto) {
    let requestOptions: any = this.getRequestOption();
    return this.http.delete(this.api.url + "removerProdutoComprado?compra_produto_id=" + compraProduto.compra_produto_id, requestOptions);
  }
  removerProdutoCadastrado(produto_id: number) {
    let requestOptions: any = this.getRequestOption();
    return this.http.delete(this.api.url + "removerProdutoCadastrado?produto_id=" + produto_id, requestOptions);
  }
  removerPLUCompra(idCompra) {
    let requestOptions: any = this.getRequestOption();
    return this.http.delete(this.api.url + "removerPLUCompra?idCompra=" + idCompra, requestOptions);
  }
  getListaCodBarrasCliente(idCliente: number) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getListaCodBarrasCliente?idCliente=" + idCliente, requestOptions);
  }

  removerCodigoBarras(idCodigoBarras) {
    let requestOptions: any = this.getRequestOption();
    return this.http.delete(this.api.url + "removerCodigoBarras?idCodigoBarras=" + idCodigoBarras, requestOptions);
  }
  criarCodigoBarras(codigoBarras: CodigoBarras) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "criarCodigoBarras", codigoBarras, requestOptions);
  }
  atualizarCodigoBarras(codigoBarras: CodigoBarras) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "updateCodigoBarras", codigoBarras, requestOptions);
  }
  getListaProdutosEstoque(termoBusca: string, orderBy: string, order: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutoEstoque?termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  salvarNovaVenda(novaVenda: NovaVenda) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "novaVenda", novaVenda, requestOptions);
  }
  getHistoricoRastreio(rastreio_id: string) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getHistoricoRastreio?rastreio_id=" + rastreio_id, requestOptions);
  }
  criarNovaEtiqueta(etiqueta: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.post(this.api.url + "criarNovaEtiqueta", etiqueta, requestOptions);
  }
  buscarEtiqueta() {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "buscarEtiqueta", requestOptions);
  }
  criarImpressaoEtiqueta(arrEtiqueta: any) {
    let requestOptions: any = this.getRequestOption();
    let data: any = {
      etiquetas: arrEtiqueta
    }
    return this.http.post(this.api.url + "criarImpressaoEtiqueta", data, requestOptions);
  }



  getProdutoEstoquePorCompra(idCompra: any) {
    let requestOptions: any = this.getRequestOption();
    return this.http.get(this.api.url + "getProdutoEstoquePorCompra?id_compra=" + idCompra, requestOptions);
  }










}
