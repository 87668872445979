
import { MatDialog } from '@angular/material/dialog';
import { CadastrarProdutoDialogComponent } from '../estoque/cadastrar-produto-dialog/cadastrar-produto-dialog.component';
import { Component, OnInit, HostListener } from '@angular/core';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/scrolling';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Produto } from '../../../model/produto.model';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpAdminService } from '../services/http-admin.service';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
@Component({
  selector: 'app-estoque',
  templateUrl: './estoque.component.html',
  styleUrls: ['./estoque.component.css'],
  animations:[
    trigger('shadowScroll', [
      state('ready', style({
        boxShadow: '0px 6px 14px -11px rgba(0,0,0,0.75)'
      })),
      state('gone', style({
        boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)'
      })),
      transition('gone=>ready', animate('500ms')),
      transition('ready=>gone', animate('500ms'))
    ])
  ]
})
export class EstoqueComponent implements OnInit {

  produtos:Produto[]=[];

  screenHeight:any;
  screenWidth:any;
  lastOffset:any;
  produtosLista:Produto[] = [];
  shadowScrollState = 'gone';

  progressbarAtivo:boolean = false;
  termoBusca:string = "";

  //###############################################################
  //###############################################################
  //#################### Paginação ################################
  order:any = 'ASC';
  orderBy:any = 'id';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;

  buscaRealizada:boolean = false;
  //###############################################################
  //###############################################################
  //###############################################################

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    public dialog: MatDialog,
    public adminGlobalService: AdminGlobalService,
    public httpAdminService: HttpAdminService,
    private route: ActivatedRoute,
    private router: Router
    ) {
    this.scrollDispatcher.scrolled().subscribe((data: CdkScrollable) => {
      //this.onWindowScroll(data);
    });
  }
  ngOnInit() {
    this.adminGlobalService.setPageTitle('Produtos');
      //################################################
      let paginaRota = this.route.snapshot.paramMap.get("pagina");
      //verificamos se a rota com a paginação é nula, caso seja o
      //valor não é atribuido a variavel pagina attual.
      if(paginaRota != null){
        this.paginaAtual = paginaRota;
        this.getProdutosList(paginaRota);
      }else{
        this.getProdutosList(1);
      }
  }
  public getProdutosList(paginaAtual){

    this.progressbarAtivo = true;

    this.httpAdminService.getProdutoList(paginaAtual,this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe(data=>{

      let mapData:any = data;

      this.paginasProximas = [];
      this.paginasAnteriores = [];

      this.produtos = mapData.data;
      this.progressbarAtivo = false;
      this.ultimaPagina = mapData.last_page;

      if(mapData.last_page > 1){
        this.exibirPaginacao = true;
      }
      //###################################################
      //Criação das próximas páginas
      let prox = Number(paginaAtual) + 1;

      for( let i = prox; i <= mapData.last_page; i++){
        if(this.paginasProximas.length < this.linksPorPagina){
          this.paginasProximas.push(i);
        }
      }
      //###################################################
      //###################################################
      //###################################################
      //Criação das páginas anteriores
      let ant = paginaAtual - this.linksPorPagina;

      for( let j = ant; j < paginaAtual; j++){
        if(j>0){
          this.paginasAnteriores.push(j);
        }

      }
      //###################################################
      //###################################################
      this.paginaAtual = paginaAtual;
      this.buscaRealizada = true;
      this.scrollTop();

    }, error=>{
      this.progressbarAtivo = false;
      console.log('erro! : ' + error);
    });
  }
  public onWindowScroll(data: CdkScrollable) {

    const scrollTop = data.getElementRef().nativeElement.scrollTop || 0;
    let scroll:number = data.getElementRef().nativeElement.scrollTop;

    if(scroll == 0){
      this.shadowScrollState = 'gone';
      console.log("SCROLL : " + data.getElementRef().nativeElement.scrollTop);
    }else if(scroll > 0 && scroll < 100){
      this.shadowScrollState = 'ready';
      console.log("SCROLL : " + data.getElementRef().nativeElement.scrollTop);
    }
    /*
    if (this.lastOffset > scrollTop) {
       //console.log('Show toolbar');
    } else if (scrollTop < 10) {
       //console.log('Show toolbar');
    } else if (scrollTop > 100) {
       //console.log('Hide toolbar');
    }

    this.lastOffset = scrollTop;
    */
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;

    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
  }
  alteraAnimacao(){

    console.log("this.shadowScrollState 1: " + this.shadowScrollState);
  if(this.shadowScrollState == 'gone'){
      this.shadowScrollState = 'ready';
    }else{
      this.shadowScrollState = 'gone';
    }

    console.log("this.shadowScrollState 2: " + this.shadowScrollState);
  }
  openCadastrarProdutoDialog(): void {
    const dialogRef = this.dialog.open(CadastrarProdutoDialogComponent, {
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  changeRoute(paginaAtual:any) {
    this.router.navigate(['admin/estoque/'+paginaAtual]);
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getProdutosList(paginaAtual);
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag);
      this.getProdutosList(pag);
    }
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getProdutosList(pag);
    }
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.getProdutosList(1);
  }
}
