import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompraProduto } from 'src/app/model/compra.model';
import { SelecionarFornecedorCompraProdutoComponent } from '../selecionar-fornecedor-compra-produto/selecionar-fornecedor-compra-produto.component';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { AdminGlobalService } from '../services/admin-global.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-atualizar-produto-compra',
  templateUrl: './atualizar-produto-compra.component.html',
  styleUrls: ['./atualizar-produto-compra.component.css']
})
export class AtualizarProdutoCompraComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public adminGlobalService:AdminGlobalService,
    public dialogRef: MatDialogRef<AtualizarProdutoCompraComponent>,
    @Inject(MAT_DIALOG_DATA) public compraProduto:CompraProduto
  ) {
   
   }

  ngOnInit() {
  }

  adicionarFornecedor(){
    const dialogRef = this.dialog.open(SelecionarFornecedorCompraProdutoComponent, {
      width: '540px',
      data: {tipo:"compra"}
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.compraProduto.fornecedor_compra.unshift(result);
      }
    });
  }
  editarFornecedor(fornecedor:Fornecedor, index:any){
    const dialogRef = this.dialog.open(SelecionarFornecedorCompraProdutoComponent, {
      width: '540px',
      data: fornecedor
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.compraProduto.fornecedor_compra[index] = result;
        this.adminGlobalService.openSnackBar("Fornecedor Atualizado!", "fechar");
      }
    });
  }
  removerFornecedor(index:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover fornecedor', 'pergunta':'Você realmente deseja remover este fornecedor?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.compraProduto.fornecedor_compra.splice(index, 1);
        this.adminGlobalService.openSnackBar("Fornecedor Removido!", "fechar");
      }
    });
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(false);
  }

  salvarDados(){
    this.dialogRef.close(this.compraProduto);
  }

}
