import { Component, OnInit, Inject } from '@angular/core';
import { CarrinhoService } from '../carrinho.service';
import { ListaCompra, Produto } from '../teste/lista.model'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-criar-lista',
  templateUrl: './criar-lista.component.html',
  styleUrls: ['./criar-lista.component.css']
})
export class CriarListaComponent implements OnInit {

  nomeLista:string = '';

  constructor(
    public carrinhoService:CarrinhoService,
    public dialogRef: MatDialogRef<CriarListaComponent>,
    @Inject(MAT_DIALOG_DATA) public data:Produto
  ) {

  }

  ngOnInit() {
  }

  salvarLista(){
    let lista:ListaCompra = {
      nome:this.nomeLista,
      data:"30/08/2019",
      arrProdutos:[]
    }
    this.carrinhoService.arrListaCompra.push(lista);
    this.onNoClick();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
