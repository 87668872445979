import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-editar-fornecedor-compra-produto',
  templateUrl: './editar-fornecedor-compra-produto.component.html',
  styleUrls: ['./editar-fornecedor-compra-produto.component.css']
})
export class EditarFornecedorCompraProdutoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditarFornecedorCompraProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  salvarDados(): void {
    this.dialogRef.close(this.data);
  }
}
