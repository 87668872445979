import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmar-finalizar-cadastro-produto-dialog',
  templateUrl: './confirmar-finalizar-cadastro-produto-dialog.component.html',
  styleUrls: ['./confirmar-finalizar-cadastro-produto-dialog.component.css']
})
export class ConfirmarFinalizarCadastroProdutoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmarFinalizarCadastroProdutoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close();
  }
  finalizarCadastro(){
    this.dialogRef.close(true);
  }
}
