import { Atributo, AtributoGrupo } from '../../../model/atributos.model';
import { Produto, Tag, Foto } from '../../../model/produto.model';
import { Categoria } from '../../../model/categoria.model';
import { TransportadoraClass } from '../../../class/transportadora.class';
import { Transportadora } from './../../../model/transportadora.model';
import { Injectable } from '@angular/core';
import { ApiDataService } from 'src/app/admin/admin-home/services/api-data.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Fabricante } from 'src/app/model/fabricante.model';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { Embalagem } from 'src/app/model/embalagem.model';
import { RequestOptions } from '@angular/http';
import { AutenticacaoService } from './autenticacao.service';
import { ConfigStoreService } from './config-store.service';

export interface TokenAutenticacao{
  access_token:string;
  token_type:string;
  expires_in:number;

}
export interface Api{
  url:string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpAdminService {

  readonly api:Api = {
    url:"https://tracker.thothcompany.com.br/api/"
  }
  public reqHeader:any;
  readonly requestOptions: any;

  constructor(
    public autenticacaoService:AutenticacaoService,
    private http: HttpClient,
    public config:ConfigStoreService,
    ) {
      this.api.url = config.API_SERVER_URL;
  }
  getRequestOption():RequestOptions{
    this.reqHeader = new HttpHeaders({
      'Access-Control-Allow-Origin': 'https://localhost:4200',
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.autenticacaoService.tokenAutenticacao.access_token,
      'Accept': '*/*',
   });
    return new RequestOptions({ headers: this.reqHeader });
  }
  fazerLogin(password, email){
    let dataLogin = {
      password:password,
      email:email
    }
    return this.http.post(this.api.url + "auth/login", dataLogin, this.requestOptions);
  }
  fazerLogout(password, email){
    let dataLogin = {
      password:password,
      email:email
    }
    return this.http.post(this.api.url + "auth/logout", dataLogin, this.requestOptions);
  }
  getClientList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "clientes?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getTransportadoraList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "transportadoras?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getFornecedorList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "fornecedores?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getFabricanteList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "fabricantes?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getProdutoList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "produtos?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getCategoriaList(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any,idNovoProduto:Number){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"categoriascadastrado?id_produto=" +idNovoProduto+"&page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  createAndGetIdNewProduct(){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url + "novoproduto", this.requestOptions);
  }
  getListaDeAtributos(idGrupoAtributo:any,page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"atributos?page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order + "&id_grupo_atributo=" + idGrupoAtributo, requestOptions);
  }
  getListaGruposDeAtributos(page:any, itensPorPagina:any, termoBusca:string, orderBy:any, order:any,idNovoProduto:Number){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"gruposdeatributos?id_produto=" +idNovoProduto+"&page=" + page + "&itens_por_pagina=" + itensPorPagina + "&termo_busca=" + termoBusca + "&order_by=" + orderBy + "&order=" + order, requestOptions);
  }
  getListaFotosProduto(id_produto:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"fotosproduto?id_produto=" +id_produto, requestOptions);
  }
  getListaFotosGrupoAtributosProduto(idGrupoAtributo:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"fotosgrupoatributoproduto?id_grupo_atributo_produto=" +idGrupoAtributo, requestOptions);
  }
  updateProduto(
    produto_id:Number,
    produto_id_loja:Number,
    produto_cod_universal:string,
    produto_cod_barras:string,
    produto_nome_produto:string,
    produto_descricao:string,
    produto_foto_principal:string,
    produto_fabricante:any,
    produto_fornecedor:any,
    produto_peso:string,
    tags:Tag[],
    categorias:Categoria[],
    fotos:Foto[]
    ){
      let requestOptions:any = this.getRequestOption();
    let produto:Produto = {
      produto_id:produto_id,
      produto_id_loja:produto_id_loja,
      produto_cod_universal:produto_cod_universal,
      produto_cod_barras:produto_cod_barras,
      produto_nome_produto:produto_nome_produto,
      produto_descricao:produto_descricao,
      produto_foto_principal:produto_foto_principal,
      produto_fabricante,
      produto_fornecedor,
      produto_peso:produto_peso,
      produto_tag:tags,
      produto_categorias:categorias,
      produto_fotos:fotos
    };
    return this.http.put(this.api.url + "updateproduto", produto, requestOptions);
  }
  updateFotosGrupoAtributos(produto_fotos:Foto[], id_grupo_atributo_produto:number){
    let requestOptions:any = this.getRequestOption();
    let data = {
      id_grupo_atributo_produto:id_grupo_atributo_produto,
      produto_fotos:produto_fotos
    }
    return this.http.put(this.api.url + "updatefotosatributosproduto", data, requestOptions);
  }
  createNewTransportadora(nome:any, telefone:any, cnpj:any){
    let requestOptions:any = this.getRequestOption();
    let transportadora:TransportadoraClass;
    transportadora = new TransportadoraClass();

    transportadora.transportadora_nome = nome;
    transportadora.transportadora_telefone = telefone;
    transportadora.transportadora_cnpj = cnpj;

    return this.http.post(this.api.url + "novatransportadora", transportadora, requestOptions);

  }
  createNewFabricante(nome:any){
    let requestOptions:any = this.getRequestOption();
    let fabricante:Fabricante = {
      fabricante_id:null,
      fabricante_id_loja:null,
      fabricante_nome:nome
    };
    return this.http.post(this.api.url + "novofabricante", fabricante, requestOptions);
  }
  createNewCategoria(categoria_nome:any, categoria_parent:any){
    let requestOptions:any = this.getRequestOption();
    let categoria:Categoria = {
        categoria_id:null,
        categoria_id_loja:null,
        categoria_nome:categoria_nome,
        categoria_parent:categoria_parent,
        categoria_filhos:null,
        categoria_selecionada:null,
        categoria_nivel:null
      };
    return this.http.post(this.api.url + "novacategoria", categoria, requestOptions);
  }
  createNewAtributo(nomeAtributo:string, tipoAtributo:number, valorAtributo:string){
    let requestOptions:any = this.getRequestOption();
    let atributo:Atributo = {
      atributo_id:null,
      atributo_nome:nomeAtributo,
      atributo_valor:valorAtributo,
      atributo_tipo:tipoAtributo,
      atributo_status:false
    }
    return this.http.post(this.api.url + "novoatributo", atributo, requestOptions);
  }
  createNewGrupoAtributo(nomeGrupoAtributo:string, id_produto:number){
    let requestOptions:any = this.getRequestOption();
    let atributoGrupo:AtributoGrupo = {
      atributo_grupo_id:null,
      atributo_grupo_id_loja:null,
      atributo_grupo_id_produto:id_produto,
      atributo_grupo_nome:nomeGrupoAtributo,
      atributo_grupo_quantidade:null,
      atributo_grupo_lista:null,
      atributo_grupo_fotos:null
    }
    return this.http.post(this.api.url + "novogrupoatributo", atributoGrupo, requestOptions);
  }
  vincularGrupoAtributo(atributos:any, idGrupoAtributo:any){
    let requestOptions:any = this.getRequestOption();
    let arr = {
      data_atributo : atributos,
      id_grupo_atributos : idGrupoAtributo
    }
    return this.http.post(this.api.url + "vinculargrupoatributo", arr, requestOptions);
  }
  uploadImagemProduto(){
  }
  finalizarCadastro(id_produto:any){
    let requestOptions:any = this.getRequestOption();
    let data = {
      id_produto:id_produto
    }
    return this.http.post(this.api.url + "finalizarcadastroproduto", data, requestOptions);
  }
  getProdutoGerarEtiqueta(id_produto:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"getProdutoGerarEtiqueta?rastreio_id=" +id_produto, requestOptions);
  }
  salvarVendaSimples(data:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.post(this.api.url + "salvarVendaSimples", data, requestOptions);
  }
  criarNovaEmbalagem(embalagem:Embalagem){
    let requestOptions:any = this.getRequestOption();
    return this.http.post(this.api.url + "criarNovaEmbalagem", embalagem, requestOptions);
  }
  getListaEmbalagem(){
    let requestOptions:any = this.getRequestOption();
    return this.http.get(this.api.url +"getListaEmbalagens", requestOptions);
  }
  salvarVinculoPluDadosComVenda(data:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.post(this.api.url + "salvarVinculoPluDadosComVenda", data, requestOptions);

  }
  salvarVinculoPluDadosComCompra(data:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.post(this.api.url + "salvarVinculoPluDadosComCompra", data, requestOptions);

  }
  removerEmbalagens(data:any){
    let requestOptions:any = this.getRequestOption();
    return this.http.post(this.api.url + "removerEmbalagem", data, requestOptions);
  }
  atualizarNFCompraProduto(id_compra_produto_fornecedor:number, nf:string){
    let requestOptions:any = this.getRequestOption();
    let data:any = {
      id_compra_produto_fornecedor:id_compra_produto_fornecedor,
      nf:nf
    }
    return this.http.put(this.api.url + "updateNfCompraProdutoFornecedor", data, requestOptions);

  }

}

