import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-rastreio-gerar-etiqueta',
  templateUrl: './rastreio-gerar-etiqueta.component.html',
  styleUrls: ['./rastreio-gerar-etiqueta.component.css']
})
export class RastreioGerarEtiquetaComponent implements OnInit {

  idRastreio:any;
  etiquetas:any[] = [];
  quantidadeEtiquetas:number = 10;

  @ViewChild('screen', {static: false}) screen: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;
  @ViewChild('downloadLink', {static: false}) downloadLink: ElementRef;


  constructor(
    private route: ActivatedRoute
  ) {
    this.idRastreio = this.route.snapshot.paramMap.get("idRastreio");
   }

  ngOnInit() {
    for(let i=0; i<this.quantidadeEtiquetas;i++){
      this.etiquetas.push(i);
    }
  }


  gerarEtiquetas(event?){
    if(this.quantidadeEtiquetas > 0 && this.quantidadeEtiquetas <= 1000){
      this.etiquetas = [];
      for(let i=0; i<this.quantidadeEtiquetas;i++){
        this.etiquetas.push(i);
      }
    }
  }

  downloadImage(){
    html2canvas(this.screen.nativeElement).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
    });
  }

  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .etiqueta-main-container{
            font-family: Arial, Helvetica, sans-serif;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 6px;
          }
          .etiqueta-box{
            background: #FFFFFF;
            display: flex;
            width: 500px;
          }
          .etiqueta-titulo{
            font-size: 16px;
            font-family: 'Roboto';
            font-weight: 600;
          }
          .etiqueta-descricao{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
          }
          .etiqueta-titulo-grande{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 22px;
            font-weight: 600;
          }
          .etiqueta-box-qrcode{
            margin-left: auto;
          }
          .etiqueta-titulo-pequena{
            font-size: 10px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .etiqueta-box-container{
            width: 100%;
          }
          .etiqueta-text-align-center{
            text-align: center;
          }
          .margin-top-10{
            margin-top: 10px;
          }
          .display_flex{
            display: flex;
          }
          .etiqueta-list-buttons-action-box{
            margin-top: 16px;
          }
          .position-absolute{
            position: absolute;
          }
          .etiqueta-total-label{
            margin-top: 20px;
            margin-left: 30px;
            max-width: 520px;
          }
          .etiqueta-container-main{
            width: 100%;
            position: absolute;
          }
          #print-section{
            transform: rotate(-90deg);
            transform-origin: right, top;
            -ms-transform: rotate(-90deg);
            margin-top: 40px;
            -ms-transform-origin: right, top;
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: right, top;
          }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
