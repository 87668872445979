import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigStoreService {

  public API_SERVER_URL: string = 'https://tracker.thothcompany.com.br/api/';
  // public API_SERVER_URL:string = 'http://127.0.0.1:8000/api/'; //localhost
  // public API_SERVER_URL: string = 'http://127.0.0.1:8003/api/';  //localhost

  constructor() {

  }

}
