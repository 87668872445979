import { Component } from '@angular/core';
import { MessagingService } from './admin/admin-home/services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Rastreamento';
  message;


  constructor(
    //private msgService: MessagingService
  ){

  }
  ngOnInit() {
    //this.msgService.getPermission();
    //this.msgService.receiveMessage();
    //this.message = this.msgService.currentMessage;
  }

  //############################################################
  //############################################################
  //############################################################
  //Plugins úteis
  //
  //https://www.npmjs.com/package/ngx-csv
  //https://www.papaparse.com/
  //
  //############################################################
  //############################################################
  //############################################################

}
