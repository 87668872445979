import { Categoria } from '../../../../model/categoria.model';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpAdminService } from '../../services/http-admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-cadastrar-categoria-dialog',
  templateUrl: './cadastrar-categoria-dialog.component.html',
  styleUrls: ['./cadastrar-categoria-dialog.component.css']
})
export class CadastrarCategoriaDialogComponent implements OnInit {

  parentSelected = null;
  categoriaNome:string = '';
  showProgressbar:boolean = false;
  showProgressbarLoadCategory:boolean = false;

  form:FormGroup;

  categoriasLista:Categoria

  constructor(
    public _snackBar: MatSnackBar,
    public httpAdminService:HttpAdminService,
    public dialogRef: MatDialogRef<CadastrarCategoriaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder
  ) {
    this.categoriasLista = data.categorias;
   }
  ngOnInit() {
    this.form = this.formBuilder.group({
      categoriaNome: ['', Validators.required]
    }, {
    });
  }
  saveData(){
    if(this.form.value.categoriaNome.length > 0){
      this.showProgressbar = true;
      this.httpAdminService.createNewCategoria(this.form.value.categoriaNome, this.parentSelected).subscribe((data)=>{
        this.openSnackBar("Categoria cadastrada com sucesso!", "fechar");
        this.showProgressbar = false;
        this.dialogRef.close(data);
      });
    }else{
      this.openSnackBar("Informe o nome da nova categoria!", "fechar");
    }
  }
  close() {
    this.dialogRef.close();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
