import { HttpRastreadorService } from '../../admin/admin-home/services/http-rastreador.service';
import { Component, OnInit } from '@angular/core';
import { ProductInfoDialogComponent } from '../product-info-dialog/product-info-dialog.component';
import { MatDialog } from '@angular/material';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import { AdminGlobalService } from 'src/app/admin/admin-home/services/admin-global.service';
import { RastreioProduto, Marker, RastreioProdutoDestino } from '../model/util.model';


@Component({
  selector: 'app-rastrear-produto',
  templateUrl: './rastrear-produto.component.html',
  styleUrls: ['./rastrear-produto.component.css']
})
export class RastrearProdutoComponent implements OnInit {

  texto : string = '';
  lat: number = -23.8779431;
  lng: number = -49.8046873;
  zoom: number = 15;
  tipoPessoa = '';

  arrMarker:Marker[] = [];

  rastreioProduto:RastreioProduto = null;

  idRastreio:any = '';
  buscarRastreioCod:any = '';
  progressbarAtivo:boolean = true;
  erroRastreio:boolean = false;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public httpRastreadorService: HttpRastreadorService,
    public adminGlobalService:AdminGlobalService
  ) {
    this.idRastreio = this.route.snapshot.paramMap.get("idRastreio");
    this.getDataRastreio(this.idRastreio);
  }
  ngOnInit() {
    this.adminGlobalService.setPageTitle('Rastrear Produto');
  }
  openDialogSaibaMais(destino:RastreioProdutoDestino):void{
    debugger;
    if( destino.destino_inscricao_rural_estadual.length < 13 ){
      this.tipoPessoa = 'CPF'
    }else{
      this.tipoPessoa = 'CNPJ'
    }
   
    const dialogRef = this.dialog.open(ProductInfoDialogComponent, {
      width: '640px',
      data:destino
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  getDataRastreio(idRastreio){
    this.progressbarAtivo = true;
    this.httpRastreadorService.getDataRastreioProduto(idRastreio).subscribe((data)=>{

      this.arrMarker = [];

      let d:any = data;
      this.rastreioProduto = d;
      
      this.rastreioProduto.trajeto.map((data, index)=>{
          let p:Marker = {
            lat: Number(data.destino_latitude),
            lng: Number(data.destino_longitude),
            label: String(index+1),
            text:data.destino_nome,
            draggable: false,
          }

          if(index == 0){
            this.arrMarker.push(p);
          }
          
      });

      this.erroRastreio = false;
      this.progressbarAtivo = false;

    },err => {
      this.arrMarker = [];
      this.rastreioProduto = null;
      console.log(err)
      this.progressbarAtivo = false;
      this.erroRastreio = true;
    });

  }
}
