import { Component, OnInit, Inject } from '@angular/core';
import { CarrinhoService } from '../carrinho.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UnidMedida, Produto } from '../teste/lista.model';
import { AdicionarProdutoConfirmarDadosDialogComponent } from '../adicionar-produto-confirmar-dados-dialog/adicionar-produto-confirmar-dados-dialog.component';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';

@Component({
  selector: 'app-adicionar-produto',
  templateUrl: './adicionar-produto.component.html',
  styleUrls: ['./adicionar-produto.component.css']
})
export class AdicionarProdutoComponent implements OnInit {

  arrUnidMedida:UnidMedida[] = [];
  arrProduto:Produto[] = [];
  produto:Produto;
  palavraChaveBusca:string = "";

  order:any = 'ASC';
  orderBy:any = 'nome';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  termoBusca:string = '';

  progressBarBusca:boolean = false;


  constructor(
    public httpRastreadorService:HttpRastreadorService,
    public dialog: MatDialog,
    public carrinhoService:CarrinhoService,
    public dialogRef: MatDialogRef<AdicionarProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.buscarProdutos();
    this.arrUnidMedida.push(UnidMedida.UNIDADE);
    this.arrUnidMedida.push(UnidMedida.CAIXA);
    this.arrUnidMedida.push(UnidMedida.KILO);
    this.arrUnidMedida.push(UnidMedida.PACOTE);
  }
  adicionarProduto(produto:Produto): void {
    //Modal em Tela cheia
    /*
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    */
    const dialogRef = this.dialog.open(AdicionarProdutoConfirmarDadosDialogComponent, {
      width: '600px',
      data: produto,
      disableClose:true
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  buscarProdutos(){
    this.progressBarBusca = true;
    this.httpRastreadorService.getListaProdutosTeste(this.termoBusca, this.orderBy, this.order).subscribe((data:any)=>{
      this.arrProduto = [];
      data.map((data)=>{
        let prod:Produto = {
          id:data.rastreio_produtos_id,
          nome:data.rastreio_produtos_nome,
          descricao:data.rastreio_produtos_descricao,
          foto:data.rastreio_produtos_imagem,
          unidMedida:UnidMedida.KILO,
          valor:0,
          quantidade:0
        }
        this.arrProduto.push(prod);

        this.progressBarBusca = false;
      }, error =>{
        this.progressBarBusca = false;
      });
    });
  }

}
