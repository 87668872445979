import { UploadFotoProdutoComponent } from './../upload-foto-produto/upload-foto-produto.component';
import { CriarAtributoDialogComponent } from './../criar-atributo-dialog/criar-atributo-dialog.component';
import { HttpAdminService } from '../../services/http-admin.service';
import { CadastrarCategoriaDialogComponent } from './../cadastrar-categoria-dialog/cadastrar-categoria-dialog.component';
import { Categoria } from './../../../../model/categoria.model';
import { TipoAtributo, AtributoGrupo } from './../../../../model/atributos.model';
import { Atributo } from './../../../../model/atributos.model';
import { Fornecedor } from '../../../../model/fornecedor.model';
import { Fabricante } from './../../../../model/fabricante.model';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminGlobalService } from 'src/app/admin/admin-home/services/admin-global.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSnackBar } from '@angular/material';
import { Produto, Tag, Foto } from '../../../../model/produto.model';
import { CriarAtributoGrupoDialogComponent } from '../criar-atributo-grupo-dialog/criar-atributo-grupo-dialog.component';
import { VincularAtributoGrupoDialogComponent } from '../vincular-atributo-grupo-dialog/vincular-atributo-grupo-dialog.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ConfirmarFinalizarCadastroProdutoDialogComponent } from '../confirmar-finalizar-cadastro-produto-dialog/confirmar-finalizar-cadastro-produto-dialog.component';


@Component({
  selector: 'app-cadastrar-produto',
  templateUrl: './cadastrar-produto.component.html',
  styleUrls: ['./cadastrar-produto.component.css']
})
export class CadastrarProdutoComponent implements OnInit {

  order:any = 'ASC';
  orderBy:any = 'fabricante_nome';
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  tags: Tag[] = [];

  idNovoProduto:Number = null;

  nomeProduto:string = '';
  descricaoHtmlContent:any = '';

  codUniversalProduto:string = '';
  codBarrasProduto:string = '';

  //##########################################
  //Galeria de fotos do produto
  fotosProduto:Foto[] = [];
  //##########################################

  fotoPrincipal:string = '';
  fotosGaleria:string[] = [];

  fornecedorLista:Fornecedor[] = [];
  fornecedorSelecionado:Number;//Armazena o ID do fornecedor selecionado.

  fabricanteLista:Fabricante[] = [];
  fabricanteSelecionado:Number;//Armazena o ID do fabricante selecionado.

  pesoProduto:any = '';

  atributosLista:Atributo[] = [];
  atributosSelecionadoLista:Atributo[] = [];

  categoriasLista:Categoria[] = [];
  categoriasListaFull:Categoria[] = [];

  grupoAtributosList:AtributoGrupo[];

  fabricanteSel:Number;//Controla o indice do Fabricante selecionado para o produto.
  fornecedorSel:Number;//Controla o indice do Fornecedor selecionado para o produto.

  //###################################################
  categoriasDataSource
  //###################################################

  showProgressBar:boolean = false;
  showProgressbarLoadCategory:boolean = false;
  showProgressbarLoadGrupoAtributo:boolean = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '5rem',
    placeholder: 'Insira sua descrição do produto aqui!',
    translate: 'no',
    defaultFontName: 'Arial',
    uploadUrl:'http://127.0.0.1:8000/api/uploadimagemproduto'
  };

  constructor(
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public adminGlobalService: AdminGlobalService,
    public httpAdminService:HttpAdminService
    ) {
  }

  ngOnInit() {

    this.adminGlobalService.setPageTitle('Cadastrar produto');

    this.httpAdminService.createAndGetIdNewProduct().subscribe(data=>{
      let d:any = data;
      this.idNovoProduto = d.produto_id;
      this.codBarrasProduto = d.produto_cod_barras;
      this.codUniversalProduto = d.produto_cod_universal;
      this.descricaoHtmlContent = d.produto_descricao;
      this.fotoPrincipal = d.produto_foto_principal;
      this.nomeProduto = d.produto_nome_produto;
      this.fabricanteSelecionado = d.produto_id_fabricante;
      this.fabricanteSel = d.produto_id_fabricante;
      this.fornecedorSelecionado = d.produto_id_fornecedor;
      this.fornecedorSel = d.produto_id_fornecedor;
      this.pesoProduto = d.produto_peso;

      //##########################################################
      //##########################################################
      if(d.produto_tags != null){
        if(d.produto_tags.length > 0){
          let arr_tag:Tag[] = [];
          let tag_retorno = d.produto_tags.split(",");
          tag_retorno.map((data)=>{
            let t:Tag = {
              name:data
            }
            arr_tag.push(t);
          });
          this.tags = arr_tag ;
        }
      }
      //##########################################################
      //##########################################################
      this.getListaFabricante();
      this.getListaFornecedor();
      this.getListaCategorias();
      this.getListaGrupoAtributos();
      this.getListaFotosProduto();

      //this.adminGlobalService.showTollbarProgressbar = false;

    }, error=>{
      this.adminGlobalService.showTollbarProgressbar = false;
      console.log('erro! : ' + error);
    });
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(tags: Tag): void {
    const index = this.tags.indexOf(tags);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
  checkCategorySelect(event, index){
    this.categoriasLista[index].categoria_selecionada = event.checked;
  }
  //####################################################################################
  //####################################################################################
  //####################################################################################
  openDialogCadastrarFornecedor(): void {

  }
  openDialogCadastrarFabricante(): void {

  }
  openDialogCriarAtributo():void{
    const dialogRef = this.dialog.open(CriarAtributoDialogComponent, {
      width: '450px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {


    });
  }
  openDialogCriarGrupoAtributo():void{
    const dialogRef = this.dialog.open(CriarAtributoGrupoDialogComponent, {
      width: '450px',
      data: {id_produto:this.idNovoProduto}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.getListaGrupoAtributos();
      }
    });
  }
  openDialogCadastrarCategoria(): void{
    const dialogRef = this.dialog.open(CadastrarCategoriaDialogComponent, {
      width: '500px',
      data: {categorias:this.categoriasLista}
    });
    dialogRef.afterClosed().subscribe(result => {
      try {
        //###############################################################################
        //## Verificamos se o retorno da Modal vem com algum valor.                    ##
        //## Se for diferente de undefined o novo item é adicionado no inicio do Array.##
        //###############################################################################
        if(result !== undefined){
          //this.categoriasLista.unshift(result);
          this.categoriasLista = [];
          this.getListaCategorias();
        }
      } catch (error) {
        console.log("Erro ao tentar listar nova categoria : " + error);
      }
    });
  }
  openDilaogVincularGrupoAtributo(id_grupo_atributo):void{

    const dialogRef = this.dialog.open(VincularAtributoGrupoDialogComponent, {
      width: '550px',
      data: {id_grupo_atributo:id_grupo_atributo}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.getListaGrupoAtributos();
      }
    });
  }
  openDialogUploadFotoProduto():void{
    const dialogRef = this.dialog.open(UploadFotoProdutoComponent, {
      width: '550px',
      data: {id_produto:this.idNovoProduto}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        let fotos:Foto = {
          foto_id:1,
          foto_id_produto:this.idNovoProduto,
          foto_ordem:1,
          foto_url:"http://127.0.0.1:8000/uploads/images/produto/" + result
        }
        this.fotosProduto.push(fotos);
        this.cadastrarProduto();
      }
    });

  }
  openDialogConfirmarFinalizarCadastro():void{
    const dialogRef = this.dialog.open(ConfirmarFinalizarCadastroProdutoDialogComponent, {
      width: '550px',
      data: {id_produto:this.idNovoProduto}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.finalizarCadastro();
      }
    });
  }
  //####################################################################################
  //####################################################################################
  //####################################################################################
  cadastrarProduto(){
    this.adminGlobalService.showTollbarProgressbar = true;
    this.httpAdminService.updateProduto(
      this.idNovoProduto,
      0,
      this.codUniversalProduto,
      this.codBarrasProduto,
      this.nomeProduto,
      this.descricaoHtmlContent,
      'foto',
      this.fabricanteSelecionado,
      this.fornecedorSelecionado,
      this.pesoProduto,
      this.tags,
      this.categoriasLista,
      this.fotosProduto
    ).subscribe((data)=>{
      this.adminGlobalService.showTollbarProgressbar = false;
      try {
        let d:any = data;
        if(d.status == true){
          this.openSnackBar("Produto atualizado com sucesso!", "fechar");
        }else{
          this.openSnackBar("Houve um erro ao tentar atualizar o produto!", "fechar");
        }
      } catch (error) {
        this.openSnackBar("Houve um erro ao tentar atualizar o produto!", "fechar");
      }
    }, error=>{
      this.showProgressBar = false;
      this.openSnackBar("Houve um erro ao tentar atualizar o produto!", "fechar");
    });
  }
  //####################################################################################
  //####################################################################################
  //####################################################################################
  getListaGrupoAtributos(){
    this.showProgressbarLoadGrupoAtributo = true;
    this.httpAdminService.getListaGruposDeAtributos( 1, 100000, '', 'id', this.order, this.idNovoProduto ).subscribe(data=>{
      let mapData:any = data;
      this.grupoAtributosList = mapData;
      this.showProgressbarLoadGrupoAtributo = false;
    }, error=>{
      console.log('erro! : ' + error);
    });
  }
  getListaFabricante(){
    this.httpAdminService.getFabricanteList( 1, 100000, '', 'id', this.order ).subscribe(data=>{
      let mapData:any = data;
      this.fabricanteLista = mapData.data;
    }, error=>{
      console.log('erro! : ' + error);
    });
  }
  getListaFornecedor(){
    this.httpAdminService.getFornecedorList( 1, 100000, '', 'id', this.order ).subscribe(data=>{
      let mapData:any = data;
      this.fornecedorLista = mapData.data;
    }, error=>{
      console.log('erro!' + error);
    });
  }
  getListaCategorias(){
    this.showProgressbarLoadCategory = true;
    this.httpAdminService.getCategoriaList( 1, 100000, '', 'id', this.order, this.idNovoProduto ).subscribe(data=>{
      this.showProgressbarLoadCategory = false;
      let mapData:any = data;
      this.categoriasListaFull = mapData;
      this.getListaCategoria(this.categoriasListaFull);
    }, error=>{
      this.showProgressbarLoadCategory = false;
      console.log('erro!' + error);
    });
  }
  getListaFotosProduto(){
    this.httpAdminService.getListaFotosProduto(this.idNovoProduto).subscribe(data=>{
      let d:any = data;
      this.fotosProduto = d;
    });
  }
  //####################################################################################
  //####################################################################################
  //####################################################################################

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  setFornecedorSelecionado(data:any){
    this.fornecedorSelecionado = data;
  }
  setFabricanteSelecionado(data:any){
    this.fabricanteSelecionado = data;
  }
  //####################################################################################################################################
  //####################################################################################################################################
  //####################################################################################################################################
  //#### Organiza as categorias com seus respectivos Pais e Filhos                                                                  ####
  //#### Primeiro buscamos as categorias principais e em seguida iteramos cada item para buscar os itens Filho                      ####
  //#### Na busca por itens filho também é realizada a iteração para a busca de seus respectivos filhos e assim a recursão preenche ####
  //#### Todos os elementos necessários para o preenchimento do Array de categorias.                                                ####
  //####################################################################################################################################
  //####################################################################################################################################
  //####################################################################################################################################
  getCategoriasPrincipais(categorias:Categoria[]){
    this.categoriasLista = categorias.filter((item)=>item.categoria_parent === null);
    for(let i = 0; i< this.categoriasLista.length; i++){
      this.categoriasLista[i].categoria_filhos = this.getCategoriasFilho(this.categoriasLista[i].categoria_id);
    }
  }
  //Função recursiva que recebe uma lista de categorias e busca as categorias Filhas.
  getCategoriasFilho(idCategoriaParent:Number):Categoria[]{

    let catList = this.categoriasListaFull.filter((item)=>item.categoria_parent === idCategoriaParent);

    for(let i = 0; i< catList.length; i++){
      catList[i].categoria_filhos = this.getCategoriasFilho(catList[i].categoria_id);
    }
    return catList;
  }
  //#############################################################################################################
  //#############################################################################################################
  //#############################################################################################################
  getListaCategoria(categorias:Categoria[]){
    let listaParentTemp = categorias.filter((item)=>item.categoria_parent === null);
    let listaChildTemp = categorias.filter((item)=>item.categoria_parent !== null);
    listaParentTemp.map((item, index)=>{
      item.categoria_nivel = 0;
      //item.categoria_selecionada = false;
      this.categoriasLista.push(item);
      this.getChildCategory(listaChildTemp,item.categoria_id, 0);
    });
  }
  getChildCategory(categorias:Categoria[], idCategoriaParent:Number, level:number){
    let catList = categorias.filter((item)=>item.categoria_parent === idCategoriaParent);
    let newLevel = level + 1;
    catList.map((item)=>{
      item.categoria_nivel = newLevel;
      this.categoriasLista.push(item);
      this.getChildCategory(categorias,item.categoria_id, newLevel);
    });
  }
  removerImagem(index:number){
    this.fotosProduto.splice(index,1);
  }
  finalizarCadastro(){
    this.httpAdminService.finalizarCadastro(this.idNovoProduto).subscribe(data=>{
      console.log("data : " + JSON.stringify(data));
      this.openSnackBar("Cadastro finalizado!", "fechar");
    });

  }
}
