import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UnidMedida, Produtos } from 'src/app/rastreador/model/produto.model'
import { CompraService } from 'src/app/admin/admin-home/services/compra.service';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { VendaProduto } from 'src/app/model/venda.model';
import { VendaService } from 'src/app/admin/admin-home/services/venda.service';

@Component({
  selector: 'app-compra-venda-atualizar-produto-dialog',
  templateUrl: './compra-venda-atualizar-produto-dialog.component.html',
  styleUrls: ['./compra-venda-atualizar-produto-dialog.component.css']
})
export class CompraVendaAtualizarProdutoDialogComponent implements OnInit {

  arrUnidMedida:UnidMedida[] = [];

  compraProduto:CompraProduto = {
    compra_produto_id:0,
    compra_produto_id_compra:0,
    compra_produto_quantidade:0,
    compra_produto_unidade_medida:UnidMedida.CAIXA,
    compra_venda_produto_id:null,
    produto_comprado_id:0,
    produto_comprado_nome:"",
    produto_comprado_imagem:"",
    produto_comprado_valor:0,
    produto_comprado_cod_rastreio:"",
    produto_plu:[],
    fornecedor_compra:[],
    compra_produto_lote:"",
  }
  vendaProduto:VendaProduto = {
    venda_produto_id:0,
    venda_produto_id_compra:0,
    venda_produto_quantidade:0,
    venda_produto_unidade_medida:UnidMedida.CAIXA,
    produto_vendido_id:0,
    produto_vendido_nome:"",
    produto_vendido_imagem:"",
    produto_vendido_valor:0,
    produto_vendido_cod_rastreio:""
  }

  constructor(
    public compraService:CompraService,
    public vendaService:VendaService,
    public dialogRef: MatDialogRef<CompraVendaAtualizarProdutoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    if(data.tipo == 'compra'){
      this.compraProduto.compra_produto_id = data.compraProduto.compra_produto_id;
      this.compraProduto.compra_produto_id_compra = data.compraProduto.compra_produto_id_compra;
      this.compraProduto.compra_produto_quantidade = data.compraProduto.compra_produto_quantidade;
      this.compraProduto.compra_produto_unidade_medida = data.compraProduto.compra_produto_unidade_medida;
      this.compraProduto.produto_comprado_id = data.compraProduto.produto_comprado_id;
      this.compraProduto.produto_comprado_nome = data.compraProduto.produto_comprado_nome;
      this.compraProduto.produto_comprado_imagem = data.compraProduto.produto_comprado_imagem;
      this.compraProduto.produto_comprado_valor = data.compraProduto.produto_comprado_valor;
    }else if(data.tipo == 'venda'){
      this.vendaProduto.venda_produto_id = data.vendaProduto.venda_produto_id;
      this.vendaProduto.venda_produto_id_compra = data.vendaProduto.venda_produto_id_compra;
      this.vendaProduto.venda_produto_quantidade = data.vendaProduto.venda_produto_quantidade;
      this.vendaProduto.venda_produto_unidade_medida = data.vendaProduto.venda_produto_unidade_medida;
      this.vendaProduto.produto_vendido_id = data.vendaProduto.produto_vendido_id;
      this.vendaProduto.produto_vendido_nome = data.vendaProduto.produto_vendido_nome;
      this.vendaProduto.produto_vendido_imagem = data.vendaProduto.produto_vendido_imagem;
      this.vendaProduto.produto_vendido_valor = data.vendaProduto.produto_vendido_valor;
    }else if(data.tipo == 'atualizarCompraFinalizada'){
      this.compraProduto.compra_produto_id = data.compraProduto.compra_produto_id;
      this.compraProduto.compra_produto_id_compra = data.compraProduto.compra_produto_id_compra;
      this.compraProduto.compra_produto_quantidade = data.compraProduto.compra_produto_quantidade;
      this.compraProduto.compra_produto_unidade_medida = data.compraProduto.compra_produto_unidade_medida;
      this.compraProduto.produto_comprado_id = data.compraProduto.produto_comprado_id;
      this.compraProduto.produto_comprado_nome = data.compraProduto.produto_comprado_nome;
      this.compraProduto.produto_comprado_imagem = data.compraProduto.produto_comprado_imagem;
      this.compraProduto.produto_comprado_valor = data.compraProduto.produto_comprado_valor;
    }
   }

  ngOnInit() {

  }
  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }
  atualizarProduto(){
    if(this.data.tipo == 'compra'){
      this.compraService.compra.compra_produtos[this.data.index] = this.compraProduto;
      this.compraService.openSnackBar("Produto atualizado!", "fechar");
      this.dialogRef.close();
      this.compraService.salvarStorage();
    }else if(this.data.tipo == 'venda'){
      this.vendaService.venda.venda_produtos[this.data.index] = this.vendaProduto;
      this.vendaService.openSnackBar("Produto atualizado!", "fechar");
      this.dialogRef.close();
      this.vendaService.salvarStorage();
    }else if(this.data.tipo == 'atualizarCompraFinalizada'){
      this.dialogRef.close(this.compraProduto);
    }
  }
}
