import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

export interface NovaEtiqueta{
  nome:string;
  largura:string;
  altura:string;
  itens_por_pagina:string;
}

@Component({
  selector: 'app-criar-etiqueta-personalizada',
  templateUrl: './criar-etiqueta-personalizada.component.html',
  styleUrls: ['./criar-etiqueta-personalizada.component.css']
})
export class CriarEtiquetaPersonalizadaComponent implements OnInit {

  nomeEtiqueta:string = "";
  larguraEtiqueta:string = "";
  alturaEtiqueta:string = "";
  etiquetasPorFolha:string = '2';
  showProgressbar:boolean = false;
  etiquetaForm: FormGroup;
  
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService,
    public dialogRef: MatDialogRef<CriarEtiquetaPersonalizadaComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.createForm();
  }
  ngOnInit() {
  }
  createForm() {
    this.etiquetaForm = this.fb.group({
      nomeEtiqueta: ['', Validators.required ],
      larguraEtiqueta: ['', Validators.required ],
      alturaEtiqueta: ['', Validators.required ],
      etiquetasPorFolha: ['', Validators.required ]
    });
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }
  numberOnly(event): boolean {
    try {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }
  criarEtiqueta(){
    let etiqueta:NovaEtiqueta = {
      nome:this.nomeEtiqueta,
      largura:this.larguraEtiqueta,
      altura:this.alturaEtiqueta,
      itens_por_pagina:this.etiquetasPorFolha
    }
    this.showProgressbar = true;
    this.httpRastreadorService.criarNovaEtiqueta(etiqueta).subscribe((d:any)=>{
      this.showProgressbar = false;
      if(d.status){
        this.onNoClick(true);
        this.adminGlobalService.openSnackBar("Etiqueta criada com sucesso!", "fechar");
      }else{
        this.adminGlobalService.openSnackBar("Não foi possível criar a etiqueta, tente novamente em instantes!", "fechar");
      }
    }, error=>{
      this.showProgressbar = false;
      this.adminGlobalService.openSnackBar("Não foi possível criar a etiqueta, tente novamente em instantes!", "fechar");
    });
  }
}
