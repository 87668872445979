import { ListasComponent } from './teste/listas/listas.component';
/* import { TesteComponent } from './teste/teste/teste.component'; */
import { Page404Component } from './page404/page404.component';
import { CompraComponent } from './admin/admin-home/compra/compra.component';
import { DashboardComponent } from './admin/admin-home/dashboard/dashboard.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VendasComponent } from './admin/admin-home/vendas/vendas.component';
import { ClientesComponent } from './admin/admin-home/clientes/clientes.component';
import { RastrearProdutoComponent } from './rastreador/rastrear-produto/rastrear-produto.component';
import { RastreiosComponent } from './admin/admin-home/rastreios/rastreios.component';
import { ProdutosComponent } from './admin/admin-home/produtos/produtos.component';
import { OrigensComponent } from './admin/admin-home/origens/origens.component';
import { CadastrarProdutosComponent } from './admin/admin-home/cadastrar-produtos/cadastrar-produtos.component';
import { CriarRastreioComponent } from './rastreador/criar-rastreio/criar-rastreio.component';
import { RastreioGerarEtiquetaComponent } from './admin/admin-home/rastreio-gerar-etiqueta/rastreio-gerar-etiqueta.component';
import { RastreioLoginComponent } from './admin/admin-home/rastreio-login/rastreio-login.component';
import { GerarEtiquetaComponent } from './admin/admin-home/gerar-etiqueta/gerar-etiqueta.component';
import { AdicionarProdutoComponent } from './teste/adicionar-produto/adicionar-produto.component';
import { LoginListaComponent } from './teste/login-lista/login-lista.component';
import { FornecedorComponent } from './admin/admin-home/fornecedor/fornecedor.component';
import { CadastrarFornecedorComponent } from './admin/admin-home/cadastrar-fornecedor/cadastrar-fornecedor.component';
import { NovaCompraComponent } from './admin/admin-home/nova-compra/nova-compra.component';
import { VerCompraComponent } from './admin/admin-home/ver-compra/ver-compra.component';
import { NovaVendaComponent } from './admin/admin-home/nova-venda/nova-venda.component';
import { CriarContaComponent } from './rastreador/criar-conta/criar-conta.component';
import { RastreioEditarPerfilUsuarioComponent } from './admin/admin-home/rastreio-editar-perfil-usuario/rastreio-editar-perfil-usuario.component';
import { RastreioAlterarSenhaComponent } from './admin/admin-home/rastreio-alterar-senha/rastreio-alterar-senha.component';
import { CadastrarClienteComponent } from './admin/admin-home/cadastrar-cliente/cadastrar-cliente.component';
import { NovaVendaSimplesComponent } from './admin/admin-home/nova-venda-simples/nova-venda-simples.component';
import { VerVendaComponent } from './admin/admin-home/ver-venda/ver-venda.component';
import { GerarEtiquetaPersonalizadaComponent } from './admin/admin-home/gerar-etiqueta-personalizada/gerar-etiqueta-personalizada.component';
import { ConsultarHistoricoRastreioComponent } from './admin/admin-home/consultar-historico-rastreio/consultar-historico-rastreio.component';

const routes: Routes = [
  {
    path: '', component: RastreioLoginComponent, children: [
    ]
  },
  { path: 'lista', component: ListasComponent },
  { path: 'Login', component: LoginListaComponent },
  { path: 'CriarConta', component: CriarContaComponent },
  { path: 'adicionarProduto', component: AdicionarProdutoComponent },
  { path: 'rastrear/:idRastreio', component: RastrearProdutoComponent },
  { path: 'rastrear', component: RastrearProdutoComponent },
  // { path: 'rast/:idRastreio', loadChildren: () => import('./rastreio/rastreio.module').then(m => m.RastreioModule) },
  // { path: 'rast', loadChildren: () => import('./rastreio/rastreio.module').then(m => m.RastreioModule) },
  { path: 'login', component: RastreioLoginComponent },
  {
    path: 'admin', component: AdminHomeComponent, children: [
      { path: '', redirectTo: 'rastreios/1', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'fornecedor', component: FornecedorComponent },
      { path: 'criarrastreio', component: CriarRastreioComponent },
      { path: 'rastreios', component: RastreiosComponent },
      { path: 'rastreios/:pagina', component: RastreiosComponent },
      { path: 'produtos', component: ProdutosComponent },
      { path: 'produtos/:pagina', component: ProdutosComponent },
      { path: 'origens', component: OrigensComponent },
      { path: 'geraretiqueta/:idRastreio', component: GerarEtiquetaPersonalizadaComponent },
      { path: 'geraretiqueta', component: GerarEtiquetaPersonalizadaComponent },
      { path: 'cadastrarproduto', component: CadastrarProdutosComponent },
      { path: 'cadastrarproduto/edit/:idproduto', component: CadastrarProdutosComponent },
      { path: 'compras', component: CompraComponent },
      { path: 'compras/:pagina', component: CompraComponent },
      { path: 'compras/:pagina/:qtdPorPagina', component: CompraComponent },
      { path: 'vercompra/:idcompra', component: VerCompraComponent },
      { path: 'vervenda/:idvenda', component: VerVendaComponent },
      { path: 'novacompra', component: NovaCompraComponent },
      { path: 'vendas', component: VendasComponent },
      { path: 'vendas/:pagina', component: VendasComponent },
      { path: 'vendas/:pagina/:qtdPorPagina', component: VendasComponent },
      { path: 'novavenda', component: NovaVendaComponent },
      { path: 'novavenda/:idCompra', component: NovaVendaComponent },
      { path: 'fornecedor', component: FornecedorComponent },
      { path: 'fornecedor/:pagina', component: FornecedorComponent },
      { path: 'fornecedor/edit/:idfornecedor', component: CadastrarFornecedorComponent },
      { path: 'cliente/:pagina', component: ClientesComponent },
      { path: 'cliente/edit/:idcliente', component: CadastrarClienteComponent },
      { path: 'cadastrarfornecedor', component: CadastrarFornecedorComponent },
      { path: 'cadastrarCliente', component: CadastrarClienteComponent },
      { path: 'configuracoes', component: RastreioEditarPerfilUsuarioComponent },
      { path: 'editarSenha', component: RastreioAlterarSenhaComponent },
      { path: 'consultarHistoricoRastreio', component: ConsultarHistoricoRastreioComponent },
      { path: 'consultarHistoricoRastreio/:rastreio_id', component: ConsultarHistoricoRastreioComponent }
    ]
  },
  // { path: 'rotaTeste', loadChildren: () => import('./lazy/lazy.module').then(m => m.LazyModule) },
  { path: '**', component: Page404Component }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

// INSTALAR AS 3 DEPENDENCIAS

export class AppRoutingModule { }
