import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FornecedorCompra } from 'src/app/model/compra.model';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { AdminGlobalService } from '../services/admin-global.service';
import { UnidMedida } from 'src/app/rastreador/model/produto.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Embalagem } from 'src/app/model/embalagem.model';
import { HttpAdminService } from '../services/http-admin.service';
import { CadastrarEmbalagemComponent } from '../cadastrar-embalagem/cadastrar-embalagem.component';
import { RemoverEmbalagemComponent } from '../remover-embalagem/remover-embalagem.component';

@Component({
  selector: 'app-selecionar-fornecedor-compra-produto',
  templateUrl: './selecionar-fornecedor-compra-produto.component.html',
  styleUrls: ['./selecionar-fornecedor-compra-produto.component.css']
})
export class SelecionarFornecedorCompraProdutoComponent implements OnInit {

  unidMedidaKilo:UnidMedida = 1;
  unidMedidaCaixa:UnidMedida = 2;
  unidMedidaPacote:UnidMedida = 3;
  unidMedidaUnidade:UnidMedida = 4;

  fornecedorCompra:FornecedorCompra = {
    id:null,
    fornecedor_id:null,
    fornecedor_nome:null,
    fornecedor_cnpj:null,
    nota_fiscal_compra:null,
    quantidade_produto:null,
    unidade_medida_compra:2,
    peso_individual:null,
    valor_kilo_produto:null,
    embalagem:null,
    lote:null,
  }

  buscaRealizada:boolean = false;
  fornecedorSelecionado:boolean = false;
  termoBuscaFornecedor:string = "";
  progressbarFornecedores:boolean = false;

  arrFornecedor:Fornecedor[] = [];
  placeholder:string = "Buscar Fornecedor";

  label_quantidade_produto:string = "Quantidade";
  label_peso_individual:string = "Quantidade de quilos por caixa";
  label_valor_produto:string = "Custo por quilo do produto (Opcional)";

  arrEmbalagens:Embalagem[] = [];

  @ViewChild('input', {static:false}) inputEl:ElementRef;

  constructor(
    public dialog: MatDialog,
    public adminGlobalService:AdminGlobalService,
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<SelecionarFornecedorCompraProdutoComponent>,
    public httpAdminService:HttpAdminService,
    @Inject(MAT_DIALOG_DATA) public dataFornecedor:FornecedorCompra,
  ) {

  }
  ngOnInit() {
    setTimeout(() => this.inputEl.nativeElement.focus());
    if(this.dataFornecedor.fornecedor_nome != null){
      this.fornecedorCompra = this.dataFornecedor;
      this.buscaRealizada = true;
      this.fornecedorSelecionado = true;
      this.fornecedorCompra.fornecedor_cnpj = this.dataFornecedor.fornecedor_cnpj;
      this.fornecedorCompra.fornecedor_id = this.dataFornecedor.fornecedor_id;
      this.fornecedorCompra.fornecedor_nome = this.dataFornecedor.fornecedor_nome;
      this.placeholder = this.dataFornecedor.fornecedor_nome;
    }
    this.getListaEmbalagens();
  }
  ngAfterViewInit() {
   
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  salvarFornecedor(){

    this.dialogRef.close(this.fornecedorCompra);

/*     if(this.fornecedorSelecionado){
      if(this.fornecedorCompra.quantidade_produto > 0){
        if(this.fornecedorCompra.peso_individual > 0){
          this.dialogRef.close(this.fornecedorCompra);
        }else{
          this.adminGlobalService.openSnackBar("Você precisa informar a quantidade e peso corretamente!", "fechar");
        }
      }else{
        this.adminGlobalService.openSnackBar("Você precisa informar a quantidade do produto corretamente!", "fechar");
      }

    }else{
      this.adminGlobalService.openSnackBar("Você precisa selecionar o fornecedor para poder salvar!", "fechar");
    } */
  }
  getFornecedor(){
    this.progressbarFornecedores = true;
    this.fornecedorSelecionado = false;
    this.fornecedorCompra.fornecedor_id = null;
    this.fornecedorCompra.fornecedor_nome = null;
    this.fornecedorCompra.fornecedor_cnpj = null;
    this.httpRastreadorService.getListaFornecedor(1, 50, this.termoBuscaFornecedor, 'id', 'ASC').subscribe((data:any)=>{
      this.arrFornecedor = data.data;
      this.buscaRealizada = true;
      this.progressbarFornecedores = false;
      this.termoBuscaFornecedor = "";
    }, error=>{
      this.progressbarFornecedores = false;
    });
  }
  selecionarFornecedor(fornecedor:Fornecedor){
    this.fornecedorSelecionado = true;
    this.fornecedorCompra.fornecedor_cnpj = fornecedor.fornecedor_cnpj;
    this.fornecedorCompra.fornecedor_id = fornecedor.fornecedor_id;
    this.fornecedorCompra.fornecedor_nome = fornecedor.fornecedor_nome;
    this.placeholder = this.fornecedorCompra.fornecedor_nome;
  }
  onUnidadeMedidaChange(){
    if(this.fornecedorCompra.unidade_medida_compra == 1){
      //KILO
      this.label_quantidade_produto = "Quantidade de quilos";
      this.label_peso_individual = "Quantidade de quilos por caixa";
      this.label_valor_produto = "Custo por quilo?";
      this.fornecedorCompra.peso_individual = 1;
    }else if(this.fornecedorCompra.unidade_medida_compra == 2){
      //CAIXA
      this.label_quantidade_produto = "Quantidade de caixas";
      this.label_peso_individual = "Quantidade de quilos por caixa";
      this.label_valor_produto = "Custo por quilo?";
    }else if(this.fornecedorCompra.unidade_medida_compra == 3){
      //PACOTE
      this.label_quantidade_produto = "Quantidade de pacotes";
      this.label_peso_individual = "Quantidade de quilos por pacote";
      this.label_valor_produto = "Custo por quilo?";
    }else if(this.fornecedorCompra.unidade_medida_compra == 4){
      //UNIDADE
      this.label_quantidade_produto = "Quantidade";
      this.label_peso_individual = "Quantidade de quilos por unidade";
      this.label_valor_produto = "Custo por quilo?";
    }

  }
  checkForm(){
    if(this.fornecedorSelecionado){
      if(this.fornecedorCompra.quantidade_produto > 0){
        if(this.fornecedorCompra.embalagem !== null){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  getListaEmbalagens(){
    this.httpAdminService.getListaEmbalagem().subscribe((data:any)=>{
/*       data.map(d=>{
        let emb:Embalagem = {
          embalagem_id:d.embalagem_id,
          embalagem_nome:d.embalagem_nome,
          embalagem_capacidade:d.embalagem_capacidade,
          embalagem_unidade_medida:d.embalagem_unidade_medida,
          label:d.embalagem_nome
        }

        let embEstoque:EmbalagemEstoque = {
          label:d.embalagem_nome,
          value:emb
        }


        this.arrEmbalagensEstoque.push(embEstoque);
        
      }); */



      this.arrEmbalagens = data;
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível obter a lista de embalagens para os produtos, por favor verifique sua conexão com a internet", "fechar");
    });
  }
  setEmbalagem(index:number, embalagemId:any){
    
    this.arrEmbalagens.map(d=>{
      if(d.embalagem_id === embalagemId){

        this.fornecedorCompra.embalagem = d;

        this.fornecedorCompra.peso_individual = d.embalagem_capacidade;
 /*        this.fornecedorCompra.quantidade_produto = d.
        d.embalagem_capacidade */

      }
    });

    console.log("embalagemId : " + JSON.stringify(this.fornecedorCompra));
  }
  cadastrarEmbalagem(){
    const dialogRef = this.dialog.open(CadastrarEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.arrEmbalagens.push(result);
        //this.vendaSimples.venda_produtos[index].produto_embalagem = result;
      }
    });
  }
  removerEmbalagem(){
    const dialogRef = this.dialog.open(RemoverEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getListaEmbalagens();
    });
  }
}
