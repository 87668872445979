import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { AdminGlobalService } from '../services/admin-global.service';

export interface HistoricoRastreamento{
  'produto':ProdutoHistorico[];
  'origens':OrigensHistorico[];
  'destinos':DestinosHistorico[];
}
export interface ProdutoHistorico{
  produto_imagem:string;
  produto_nome:string;
}
export interface OrigensHistorico{
  fornecedor_cnpj:string;
  fornecedor_nome:string;
  nota_fiscal_compra:string;
}
export interface DestinosHistorico{
  cliente_cnpj:string;
  cliente_nome:string;
  venda_nota_fiscal:string;
}

@Component({
  selector: 'app-consultar-historico-rastreio',
  templateUrl: './consultar-historico-rastreio.component.html',
  styleUrls: ['./consultar-historico-rastreio.component.css']
})
export class ConsultarHistoricoRastreioComponent implements OnInit {

  rastreio_id:string = null;
  rastreio_id_busca:string = "";
  historicoRastreamento:HistoricoRastreamento = {
    'produto':[],
    'origens':[],
    'destinos':[],
  }
  progressbarBuscarRastreio:boolean = false;
  erroBuscarDados:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService
  ) { }

  ngOnInit() {
    this.adminGlobalService.setPageTitle("Rastrear Produto");
    this.rastreio_id = this.route.snapshot.paramMap.get("rastreio_id");
    if(this.rastreio_id != null){
      this.getHistoricoRastreio(this.rastreio_id);
      this.rastreio_id_busca = this.rastreio_id;
    }else{
      
      
    }

  }

  getHistoricoRastreio(rastreio_id:string){
    this.progressbarBuscarRastreio = true;
    this.httpRastreadorService.getHistoricoRastreio(rastreio_id).subscribe((d:any)=>{
      try {
        if(d[0].status){
          this.historicoRastreamento = d[0];
          this.progressbarBuscarRastreio = false;
          this.erroBuscarDados = false;
        }else{
          this.erroBuscarDados = true;
          this.historicoRastreamento.destinos = [];
          this.historicoRastreamento.origens = [];
          this.historicoRastreamento.produto = [];
          this.adminGlobalService.openSnackBar("Não foi possível encontrar o rastreio informado!", "fechar");
        } 
      } catch (error) {
        this.erroBuscarDados = true;
        this.historicoRastreamento.destinos = [];
        this.historicoRastreamento.origens = [];
        this.historicoRastreamento.produto = [];
        this.progressbarBuscarRastreio = false;
        this.adminGlobalService.openSnackBar("Não foi possível encontrar o rastreio informado!", "fechar");
      }
    }, error=>{
      this.erroBuscarDados = true;
      this.historicoRastreamento.destinos = [];
      this.historicoRastreamento.origens = [];
      this.historicoRastreamento.produto = [];
      this.adminGlobalService.openSnackBar("Não foi possível carregar os dados do rastreio, por favor tente novamente!", "fechar");
      this.progressbarBuscarRastreio = false;
    });
  }


  buscarHistorico(rastreio_id:string){
    this.router.navigate(['/admin/consultarHistoricoRastreio/'+rastreio_id])
    this.getHistoricoRastreio(rastreio_id);
  }


}
