import { CarrinhoService } from '../carrinho.service';
import { Produto, ListaCompra } from '../teste/lista.model';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CriarListaComponent } from '../criar-lista/criar-lista.component';

export interface Lista{
  id:number;
  idUser:number;
  nome:string;
  data:string;
  dataUltimaAtualizacao:string;
  produtos:Produto[];

}

@Component({
  selector: 'app-listas',
  templateUrl: './listas.component.html',
  styleUrls: ['./listas.component.css']
})
export class ListasComponent implements OnInit {

  arrLista:any[] = [1,2,3,4,5,6,7,8,9,10];

  constructor(
    public carrinhoService:CarrinhoService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  criarLista(){
    const dialogRef = this.dialog.open(CriarListaComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  setLista(lista:ListaCompra){
    this.carrinhoService.listaCompra = lista;
  }

}
