import { Component, OnInit, HostListener,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AdminGlobalService } from './services/admin-global.service';
import { JwtModule } from '@auth0/angular-jwt';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { HttpAdminService } from './services/http-admin.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { UserPerfil } from 'src/app/rastreador/model/util.model';
import { AutenticacaoService } from './services/autenticacao.service';

//###################################################################################
//Referências
//https://medium.com/rocketseat/implementando-lazy-loading-no-angular-d8a6541c0580
//###################################################################################

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  tituloPagina = "Painel de controle";
  fullScreen:boolean = false;

  //###############################################
  //Store data
  //###############################################
  storeName:string = "Thoth Company Store";
  storeLogo:string = "https://rast.thothcompany.com.br/assets/images/rastreabilidade-logo.png";
  //###############################################
  drawerMode:string = 'side';
  screenSize:Number;
  elem;

  userPerfil:UserPerfil = {
    user_cidade: "",
    user_cnpj: "",
    user_inscricao_estadual: "",
    user_endereco: "",
    user_endereco_latitude: "",
    user_endereco_longitude: "",
    user_estado: "",
    user_bairro:"",
    user_id: 0,
    user_id_cliente: null,
    user_nome:  "",
    user_razao_social: "",
    user_numero:  "",
    user_pais:  "",
    user_status_conta: null,
    user_telefone:  "",
    user_telefone_celular:"",
    user_level: 0,
  }

  dadosCarregados:boolean = false;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    public adminGlobalService:AdminGlobalService,
    public httpAdminService:HttpAdminService,
    public httpRastreioService:HttpRastreadorService,
    public autenticacaoService:AutenticacaoService
    ) {
    this.screenSize = window.innerWidth;
    if(this.autenticacaoService.autenticado == false){
      this.router.navigate(['/login']);
    }
  }
  ngOnInit() {
    this.elem = document.documentElement;
    if(this.screenSize >= 600){
      this.drawerMode = 'side';
    }else{
      this.drawerMode = 'over';
    }
    setTimeout(()=>{
      this.getLoggedDataUser();
      }, 1000
    );

    this.userPerfil = JSON.parse(localStorage.getItem('logged_data_user'));
  }
  getLoggedDataUser(){
    this.httpRastreioService.getLoggedDataUser().subscribe((data:any)=>{
      this.userPerfil = data[0];
      localStorage.setItem('logged_data_user', JSON.stringify(data[0]));
      this.dadosCarregados = true;
      if(this.userPerfil.user_status_conta == 0){
        this.sairSistema();
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenSize = window.innerWidth;
    if(this.screenSize > 700) this.drawerMode = 'side';
    if(this.screenSize <= 700) this.drawerMode = 'over';

    if(this.screenSize <= 600){
      this.adminGlobalService.mobile = true;
    }else{
      this.adminGlobalService.mobile = false;
    }
  }
  openFullscreen() {
    if(!this.fullScreen){
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
      this.fullScreen = true;
    }else{
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (this.elem.webkitExitFullscreen) {
        this.elem.webkitExitFullscreen();
      } else if (this.elem.mozCancelFullScreen) {
        this.elem.mozCancelFullScreen();
      } else if (this.elem.msExitFullscreen) {
        this.elem.msExitFullscreen();
      }
      this.fullScreen = false;
    }
  }
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event?) {
    console.debug("Scroll Event", window.pageYOffset );
  }
  onActivate(event) {
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  sairSistema(){
    try {
      let data:any = JSON.parse(atob(localStorage.getItem('rast_access_data')));

      this.httpAdminService.fazerLogout(data.p, data.p).subscribe((data)=>{
        
        localStorage.removeItem('rast_access_token');
        localStorage.removeItem('access_data');
        this.autenticacaoService.autenticado = false;
        this.autenticacaoService.tokenAutenticacao.expires_in = 0;
        this.autenticacaoService.tokenAutenticacao.access_token = null;
        this.router.navigate(['/login']);

      },error =>{
        localStorage.removeItem('rast_access_token');
        localStorage.removeItem('access_data');
        this.autenticacaoService.autenticado = false;
        this.autenticacaoService.tokenAutenticacao.expires_in = 0;
        this.autenticacaoService.tokenAutenticacao.access_token = null;
        this.router.navigate(['/login']);
      });
    } catch (error) {
      localStorage.removeItem('rast_access_token');
      localStorage.removeItem('access_data');
      this.autenticacaoService.autenticado = false;
      this.autenticacaoService.tokenAutenticacao.expires_in = 0;
      this.autenticacaoService.tokenAutenticacao.access_token = null;
      this.router.navigate(['/login']);
    }
  }
}
