import { ConfirmDialogComponent } from 'src/app/admin/admin-home/confirm-dialog/confirm-dialog.component';
import { Produto } from 'src/app/model/produto.model';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Compra, CompraProduto, FornecedorCompra } from 'src/app/model/compra.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompraVendaAddProdutoDialogComponent } from '../compra-venda-add-produto-dialog/compra-venda-add-produto-dialog.component';
import { CompraService } from 'src/app/admin/admin-home/services/compra.service';
import {formatDate } from '@angular/common';
import { CompraVendaAtualizarProdutoDialogComponent } from '../compra-venda-atualizar-produto-dialog/compra-venda-atualizar-produto-dialog.component';
import { Router } from "@angular/router";
import { AtualizarProdutoCompraComponent } from '../atualizar-produto-compra/atualizar-produto-compra.component';
import { VerFornecedoresCompraProdutoComponent } from '../ver-fornecedores-compra-produto/ver-fornecedores-compra-produto.component';
import { Embalagem } from 'src/app/model/embalagem.model';

@Component({
  selector: 'app-nova-compra',
  templateUrl: './nova-compra.component.html',
  styleUrls: ['./nova-compra.component.css']
})
export class NovaCompraComponent implements OnInit {

  today:number = Date.now();
  jstoday = '';

  progressbarFornecedores:boolean = false;
  termoBuscaFornecedor:string = '';
  arrFornecedor:Fornecedor[] = [];
  fornecedorSelecionado:boolean = false;
  showResultadoFornecedor:boolean = false;


  arrEmbalagens:Embalagem[] = [];


  fornecedor:Fornecedor = {
    fornecedor_id:null,
    fornecedor_razao_social:"",
    fornecedor_nome:"",
    fornecedor_cnpj: "",
    fornecedor_inscricao_estadual: "",
    fornecedor_endereco_longitude: "",
    fornecedor_endereco_latitude: "",
    fornecedor_pais: "",
    fornecedor_estado: "",
    fornecedor_cidade: "",
    fornecedor_bairro: "",
    fornecedor_endereco: "",
    fornecedor_numero: "",
    fornecedor_complemento: "",
    fornecedor_observacao: "",
    fornecedor_cep: "",
    fornecedor_tipoPessoa:""
  };
  novaCompra:Compra = {
    compra_id:0,
    compra_numero_pedido:null,
    compra_id_cliente:0,
    compra_data:"",
    compra_nota_fiscal:"",
    compra_id_fornecedor:this.fornecedor.fornecedor_id,
    compra_fornecedor_nome:this.fornecedor.fornecedor_nome,
    compra_fornecedor_cnpj:this.fornecedor.fornecedor_cnpj,
    compra_produtos:[]
  }

  arrProduto = [];

  constructor(
    private router: Router,
    public compraService:CompraService,
    public dialog: MatDialog,
    public adminGlobalService:AdminGlobalService,
    private _formBuilder: FormBuilder,
    public httpRastreadorService:HttpRastreadorService,
    ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Nova compra');
    //#####################################################
  }
  ngOnInit() {
      try {
        if (localStorage.getItem("novaCompra") !== null) {
          this.compraService.compra = JSON.parse(localStorage.getItem('novaCompra'));
        }
      } catch (error) {
      }

  }
  getFornecedor(){
    this.showResultadoFornecedor = true;
    this.progressbarFornecedores = true;
    this.httpRastreadorService.getListaFornecedor(1, 1000, this.termoBuscaFornecedor, 'id', 'ASC').subscribe((data)=>{
      this.compraService.compra.compra_id_fornecedor = null;
      this.compraService.compra.compra_fornecedor_cnpj = null;
      this.compraService.compra.compra_fornecedor_nome = null;
      let d:any = data;
      this.arrFornecedor = d.data;
      this.progressbarFornecedores = false;
      this.termoBuscaFornecedor = "";
    });
  }
  showFornecedorResult(){
    //this.getFornecedor();
    
  }
  hideFornecedorResult(){
    setTimeout(()=>{
      this.showResultadoFornecedor = false;
    }, 300);

  }
  selecionarFornecedor(fornecedor:Fornecedor){
    this.fornecedor = fornecedor;
    this.fornecedorSelecionado = true;
    this.showResultadoFornecedor = false;
    this.compraService.compra.compra_id_fornecedor = fornecedor.fornecedor_id;
    this.compraService.compra.compra_fornecedor_nome = fornecedor.fornecedor_nome;
    this.compraService.compra.compra_fornecedor_cnpj = fornecedor.fornecedor_cnpj;
    this.compraService.openSnackBar("Fornecedor Selecionado!", "fechar");
  }
  removerFornecedor(){
    //this.showResultadoFornecedor = true;
    this.fornecedorSelecionado = false;
    this.fornecedor = {
      fornecedor_id:null,
      fornecedor_razao_social:"",
      fornecedor_nome:"",
      fornecedor_cnpj: "",
      fornecedor_inscricao_estadual: "",
      fornecedor_endereco_longitude: "",
      fornecedor_endereco_latitude: "",
      fornecedor_pais: "",
      fornecedor_estado: "",
      fornecedor_cidade: "",
      fornecedor_bairro: "",
      fornecedor_endereco: "",
      fornecedor_numero: "",
      fornecedor_complemento: "",
      fornecedor_observacao: "",
      fornecedor_cep: "",
      fornecedor_tipoPessoa: ""
    };
    this.compraService.compra.compra_id_fornecedor = null;
    this.compraService.compra.compra_fornecedor_cnpj = null;
    this.compraService.compra.compra_fornecedor_nome = null;
    this.compraService.salvarStorage();
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  openDialogAdicionarProduto(){
    const dialogRef = this.dialog.open(CompraVendaAddProdutoDialogComponent, {
      width: '600px',
      data: {tipo:"compra"}
    });
    dialogRef.afterClosed().subscribe((result:Produto) => {
    });
  }
  getTotalIndividual(index){
    return this.compraService.compra.compra_produtos[index].compra_produto_quantidade * this.compraService.compra.compra_produtos[index].produto_comprado_valor;
  }
  getTotal(){
    let total = 0;
    this.compraService.compra.compra_produtos.map((produto,index)=>{
      total += this.getTotalIndividual(index);
    });
    return total;
  }
  removerProduto(index){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover produto', 'pergunta':'Você realmente deseja remover este produto?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.compraService.compra.compra_produtos.splice(index, 1);
        this.compraService.openSnackBar("Produto removido!", "fechar");
        this.compraService.salvarStorage();
      }
    });
  }
  removetTodosProdutos(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover todos os produtos', 'pergunta':'Você realmente deseja remover todos os produtos?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
      this.compraService.compra.compra_produtos = [];
      this.compraService.openSnackBar("Todos os produtos foram removidos!", "fechar");
      this.compraService.salvarStorage();
      }
    });
  }
  atualizaProduto(compraProduto:CompraProduto, index:any){
    const dialogRef = this.dialog.open(AtualizarProdutoCompraComponent, {
      width: '600px',
      data: compraProduto
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result !== false && result !== undefined){
        this.compraService.compra.compra_produtos[index] = result;
        this.adminGlobalService.openSnackBar("Produto Atualizado com sucesso!", "fechar");
        this.compraService.salvarStorage();
      }
    });

  }
  finalizarCompra(){
      if(this.compraService.compra.compra_produtos.length > 0){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '600px',
          data: {'titulo':'Finalizar Compra', 'pergunta':'Você realmente deseja finalizar esta compra?'}
        });
        dialogRef.afterClosed().subscribe((result:boolean) => {

          if(result){
            
            this.compraService.compra.compra_produtos.map(dataProd=>{
              let quantidadeProdutos = 0;
              dataProd.fornecedor_compra.map(dataForn=>{
                quantidadeProdutos = Number(quantidadeProdutos) + Number(dataForn.quantidade_produto);
              });
              dataProd.compra_produto_quantidade = quantidadeProdutos;
            });

            this.httpRastreadorService.salvarCompra(this.compraService.compra).subscribe((data:any)=>{

              if(data.status==true){
                this.compraService.openSnackBar("Compra salva com sucesso!", "fechar");
                this.compraService.compra.compra_produtos = [];
                this.compraService.compra.compra_nota_fiscal = "";
                this.compraService.compra.compra_data = "";
                this.compraService.compra.compra_id_fornecedor = null;
                this.compraService.compra.compra_fornecedor_cnpj = null;
                this.compraService.compra.compra_fornecedor_nome = null;
                this.compraService.compra.compra_id = 0;
                this.compraService.salvarStorage();
                this.removerFornecedor();
                this.router.navigate(['/admin/novavenda/' + data.id_compra]);
              }

            });
          }
        
        });
      }else{
        this.compraService.openSnackBar("Você precisa selecionar ao menos um produto antes de enviar!", "fechar");
      }
  }
  getTotalPesoProduto(index:any){
    let peso:number = 0;
   if(this.compraService.compra.compra_produtos[index].fornecedor_compra.length > 0){
    this.compraService.compra.compra_produtos[index].fornecedor_compra.map(d=>{
      peso = peso + ( Number( d.peso_individual ) * Number( d.quantidade_produto ) );
    });
      return peso;
    }else{
      return 0;
    }
  }
  visualizarFornecedoresProduto(fornecedor_compra:FornecedorCompra[]){
    
    const dialogRef = this.dialog.open(VerFornecedoresCompraProdutoComponent, {
      width: '600px',
      data: fornecedor_compra
    });

    dialogRef.afterClosed().subscribe((result:any) => {

    });
  }

}
