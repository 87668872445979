import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestOptions, Headers } from "@angular/http";
import { ConfigStoreService } from './config-store.service';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService extends ConfigStoreService{
  // Variavel onde vai ser colocada a URL da API
  public API_URL: string;
  public requestOptions: any;
  public headers;
  access_token:string = null;

  public reqHeader:any;

  constructor() {
    super();
    this.getToken();
    this.API_URL = this.API_SERVER_URL;
    this.reqHeader = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.access_token,
      'Accept': '*/*',
   });
    this.requestOptions = new RequestOptions({ headers: this.reqHeader });
  }
  public getToken(){
    if(this.access_token == null){
      this.setToken(localStorage.getItem('access_token'));
    }
    return this.access_token;
  }
  public setToken(access_token){
    this.access_token = access_token;
  }
  public setHeader(){
    this.access_token = null;
    this.getToken();

    this.reqHeader = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + this.access_token,
      'Accept': '*/*',
   });
    this.requestOptions = new RequestOptions({ headers: this.reqHeader });
  }
}
