import { UploadFileService } from '../../services/upload-file.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from  '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-foto-produto',
  templateUrl: './upload-foto-produto.component.html',
  styleUrls: ['./upload-foto-produto.component.css']
})
export class UploadFotoProdutoComponent implements OnInit {

  form: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  uploadStatus:boolean = false;
  uploadProgresso:number = 0;
  erroEnvio:boolean = false;

  constructor(
    public _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public uploadService: UploadFileService,
    public dialogRef: MatDialogRef<UploadFotoProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    ) {


    }

  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: ['']
    });
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    }
  }
  onSubmit() {
    if(this.form.get('avatar').value != ''){
      this.erroEnvio = true;
      this.uploadStatus = true;
      const formData = new FormData();
      formData.append('image', this.form.get('avatar').value);
      this.uploadService.uploadImagemProduto(formData, this.userId).subscribe((data)=>{
        this.erroEnvio = false;
        if(data.message != undefined){
          this.uploadProgresso = data.message
        }
        if(data.url != undefined){
          this.uploadStatus = false;
          this.dialogRef.close(data.url);
          this.openSnackBar("Imagem enviada com sucesso!", "fechar");
        }
      },error =>{
        this.erroEnvio = true;
        this.openSnackBar("Houve um erro ao tentar enviar sua imagem, verifique o tipo de arquivo enviado, ele pode não ser suportado ou você esta sem conexão com a Internet!", "fechar");
      });
    }else{
      this.openSnackBar("Você precisa selecionar uma imagem antes de enviar!", "fechar");
    }
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }
  closeWindow(){
    this.dialogRef.close();
  }
}
