import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { NovaVenda } from 'src/app/teste/teste/lista.model';
@Component({
  selector: 'app-upload-arquivo-plu-vincular-produto',
  templateUrl: './upload-arquivo-plu-vincular-produto.component.html',
  styleUrls: ['./upload-arquivo-plu-vincular-produto.component.css']
})
export class UploadArquivoPluVincularProdutoComponent implements OnInit {

  compraProdutos:CompraProduto[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadArquivoPluVincularProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public novaVenda:NovaVenda
  ) {
   }

  ngOnInit() {

  }
  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }
  selecionarProduto(produto:any){
    this.dialogRef.close(produto);
  }
}
