import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AdminGlobalService } from '../services/admin-global.service';
import { Cliente } from 'src/app/model/cliente.model';
import { CriarCodigosDeBarrasComponent } from '../criar-codigos-de-barras/criar-codigos-de-barras.component';
import { CodigoBarras } from 'src/app/model/codigo-barras.model';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-listar-codigos-de-barras',
  templateUrl: './listar-codigos-de-barras.component.html',
  styleUrls: ['./listar-codigos-de-barras.component.css']
})
export class ListarCodigosDeBarrasComponent implements OnInit {

  arrCodigoBarras:CodigoBarras[] = [];
  progressbarListaItens:boolean = false;
  buscaRealizada:boolean = false;

  constructor(
    public dialog: MatDialog,
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<ListarCodigosDeBarrasComponent>,
    public adminGlobalService:AdminGlobalService,
    @Inject(MAT_DIALOG_DATA) public cliente:Cliente
  ) { }

  ngOnInit() {
    this.getListaCodigoBarras();
  }
  getListaCodigoBarras(){
    this.progressbarListaItens = true;
    this.httpRastreadorService.getListaCodBarrasCliente(this.cliente.cliente_id).subscribe((data:any)=>{
      this.arrCodigoBarras = data;
      this.progressbarListaItens = false;
      this.buscaRealizada = true;
    }, error=>{
      this.progressbarListaItens = false;
      this.adminGlobalService.openSnackBar("Não foi possível listar os códigos de barras deste cliente, tente novamente em instantes!", "fechar");
    });
  }
  cadastrarNovoCodigoBarras():void{
    const dialogRef = this.dialog.open(CriarCodigosDeBarrasComponent, {
      width: '600px',
      data: this.cliente
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
/*         console.log("result: " + JSON.stringify(result));
        let codbar:CodigoBarras = {
          id:result.id,
          id_produto:result.id_produto,
          id_cliente:result.id_cliente,
          codigo_barras:result.codigo_barras,
          tipo_codigo_barras:result.tipo_codigo_barras,
          produto_nome:result.produto_nome,
          produto_imagem:result.produto_imagem
        }
        this.arrCodigoBarras.push(codbar); */
        this.getListaCodigoBarras();
      }
    });
  }
  editarCodigoBarras(codigoBarras:CodigoBarras, index:any):void{
  
    const dialogRef = this.dialog.open(CriarCodigosDeBarrasComponent, {
      width: '600px',
      data: codigoBarras
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.arrCodigoBarras[index].codigo_barras = result.codigo_barras;
        this.arrCodigoBarras[index].tipo_codigo_barras = result.tipo_codigo_barras;
      }
    });
  }
  removerCodigoBarras(codigoBarras:CodigoBarras):void{
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '580px',
      data: {'titulo':'Remover item', 'pergunta':'Você realmente deseja remover este item?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.httpRastreadorService.removerCodigoBarras(codigoBarras.id).subscribe((d:any)=>{
            this.adminGlobalService.openSnackBar("Código de barras removido com sucesso!", "fechar");
            this.getListaCodigoBarras();
        });
      }
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível remover este código de barras, por favor tente novamente!", "fechar");
    });
  }

  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }

}
