import { Cliente } from 'src/app/model/cliente.model';
import { Embalagem } from 'src/app/model/embalagem.model';

export interface PLUProduto{
  plu_nome_produto:string;
  plu_codigo_barras:string;
  plu_codigo_barras_tipo:string;
  plu_capacidade_embalagem:string;
}
export interface PLUData{
  plu_id_pedido:string;
  plu_data_entrega:string;
  plu_numero_fornecedor:string;
}
export interface ListaCompra{
  nome:string;
  data:string;
  arrProdutos:Produto[];

}
export enum UnidMedida{
  KILO = 1,
  CAIXA = 2,
  PACOTE = 3,
  UNIDADE = 4
}
export interface Produto{
  id:number;
  nome:string;
  descricao:string;
  foto:string;
  unidMedida:UnidMedida;
  valor:number;
  quantidade:number;
}

export interface ProdutoEstoque{
  estoque:number;
  quantidade_venda:number;
  id_lote:number;
  nota_fiscal:string;
  id_produto:number;
  imagem_produto: string;
  peso_individual: string;
  produto_nome: string;
  rastreio_id: string;
  unidade_medida_compra: string;
  fornecedor_nome:string;
  embalagem:Embalagem;
  id_compra_produto:number;
  plu_importado:boolean;
  plu_produto:PLUProduto;
}

export interface EmbalagemEstoque{
  label:string;
  value:Embalagem
}

export interface NovaVenda{
  notaFiscal:number;
  nPedido:string;
  cliente:Cliente;
  arrProduto:ProdutoEstoque[];
  plu_data:PLUData
}