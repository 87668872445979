import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpAdminService } from '../services/http-admin.service';
import { Embalagem, EmbalagemUnidadeMedida } from 'src/app/model/embalagem.model';
import { AdminGlobalService } from '../services/admin-global.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export interface EmbalagemRemover{
  embalagem_id:number;
  embalagem_nome:string;
  embalagem_capacidade:number;
  embalagem_unidade_medida:EmbalagemUnidadeMedida;
  remover:boolean
}


@Component({
  selector: 'app-remover-embalagem',
  templateUrl: './remover-embalagem.component.html',
  styleUrls: ['./remover-embalagem.component.css']
})
export class RemoverEmbalagemComponent implements OnInit {

  arrEmbalagens:EmbalagemRemover[] = [];
  progressBar:boolean = false;

  constructor(
    public adminGlobalService:AdminGlobalService,
    public httpAdminService:HttpAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RemoverEmbalagemComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.getListaEmbalagens();
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }

  getListaEmbalagens(){

    this.progressBar = true;
    this.httpAdminService.getListaEmbalagem().subscribe((data:any)=>{
      this.progressBar = false;
      this.arrEmbalagens = data;
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível obter a lista de embalagens para os produtos, por favor verifique sua conexão com a internet", "fechar");
    });
  }
  openDialogConfirmarFinalizacao(){
    let arr:EmbalagemRemover[] = [];
    this.arrEmbalagens.map(d=>{
      if(d.remover){
        arr.push(d);
      }
    });
    if(arr.length > 0){
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '600px',
        data: {'titulo':'Remover embalagens', 'pergunta':'Você realmente deseja remover as embalagens selecionadas?'}
      });
      dialogRef.afterClosed().subscribe((result:any) => {
        if(result == true){
          let dados = {
            embalagens:arr
          }
          this.progressBar = true;
          this.httpAdminService.removerEmbalagens(dados).subscribe(d=>{
            this.getListaEmbalagens();
            this.adminGlobalService.openSnackBar("Embalagens removidas com sucesso!", "fechar");
            this.progressBar = false;
          }, error=>{
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar remover as embalagens, verifique sua conexão com a internet!", "fechar");
            this.progressBar = false;
          });
        }
      });
    }else{
      this.adminGlobalService.openSnackBar("Você precisa selecionar ao menos um item para remover!", "fechar");
    }
  }

  checkBoxItem(checked, embalagem:Embalagem){
    console.log("checked : " + checked);

    if(checked == true){

    }else{

    }
  }



  
}
