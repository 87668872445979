import { Transportadora } from './../../../../model/transportadora.model';
import { Component, OnInit, PipeTransform, Pipe, Inject } from '@angular/core';
import { AbstractControl, Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpAdminService } from '../../services/http-admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ValidatorsBr} from '../../../../class/validatorsbr.class'
// import custom validator to validate that password and confirm password fields match

@Pipe({name: 'cnpjPipe'})
export class CNPJPipe implements PipeTransform {
    transform(value) {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
    }
}
@Component({
  selector: 'app-cadastrar-transportadora-dialog',
  templateUrl: './cadastrar-transportadora-dialog.component.html',
  styleUrls: ['./cadastrar-transportadora-dialog.component.css']
})
export class CadastrarTransportadoraDialogComponent implements OnInit {

  transportadora:Transportadora;
  form:FormGroup;
  public MASKS = MASKS;
  cnpj:any = "";
  transportadoraNome:string = "";
  transportadoraTelefone:string = "";
  transportadoraTransportadora:string = "";
  showProgressbar:boolean = false;
  validatorsBr:ValidatorsBr = new ValidatorsBr();

  submitted = false;

  cnpjValido:boolean = true;

  constructor(
    public _snackBar: MatSnackBar,
    public httpAdminService:HttpAdminService,
    public dialogRef: MatDialogRef<CadastrarTransportadoraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder
    ) {
   }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      transportadoraNome: ['', Validators.required],
      telefone: ['', Validators.required],
      cnpj: ['', Validators.required]
    }, {
    });
  }
  onSubmit() {
    if(this.validatorsBr.validarCNPJ(this.form.value.cnpj) == true){
      this.showProgressbar = true;
      this.httpAdminService.createNewTransportadora(
        this.form.value.transportadoraNome,
        this.form.value.telefone,
        this.form.value.cnpj
        ).subscribe((data)=>{
          this.openSnackBar("Transportadora cadastrada com sucesso!", "fechar");
          this.dialogRef.close(true);
          this.showProgressbar = false;
      });
      this.cnpjValido = true;
    }else{
      this.cnpjValido = false;
      console.log("CNPJ INVÁLIDO");
    }
    /*
    // stop here if form is invalid
    if (this.form.invalid) {
      console.log("Ok this.form.invalid : " + this.form.invalid);
        return;
    }else{
      console.log("Erro this.form.invalid : " + this.form.invalid);
    }
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.form.value))
    */
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }





}


