import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { A11yModule} from '@angular/cdk/a11y';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { PortalModule} from '@angular/cdk/portal';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { CdkStepperModule} from '@angular/cdk/stepper';
import { CdkTableModule} from '@angular/cdk/table';
import { CdkTreeModule} from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule} from '@angular/material/dialog';
import { MatDividerModule} from '@angular/material/divider';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatIconModule} from '@angular/material/icon';
import { MatInputModule} from '@angular/material/input';
import { MatListModule} from '@angular/material/list';
import { MatMenuModule} from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatRadioModule} from '@angular/material/radio';
import { MatSelectModule} from '@angular/material/select';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatSliderModule} from '@angular/material/slider';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSortModule} from '@angular/material/sort';
import { MatTableModule} from '@angular/material/table';
import { MatTabsModule} from '@angular/material/tabs';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatTreeModule} from '@angular/material/tree';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { DashboardComponent } from './admin/admin-home/dashboard/dashboard.component';
import { EstoqueComponent } from './admin/admin-home/estoque/estoque.component';
import { CompraComponent } from './admin/admin-home/compra/compra.component';
import { VendasComponent } from './admin/admin-home/vendas/vendas.component';
import { ClientesComponent } from './admin/admin-home/clientes/clientes.component';
import { TransportadorasComponent } from './admin/admin-home/transportadoras/transportadoras.component';
import { ConfiguracoesComponent } from './admin/admin-home/configuracoes/configuracoes.component';
import { CadastrarProdutoDialogComponent } from './admin/admin-home/estoque/cadastrar-produto-dialog/cadastrar-produto-dialog.component';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CadastrarProdutoComponent } from './admin/admin-home/estoque/cadastrar-produto/cadastrar-produto.component';
import { FormsModule } from '@angular/forms';
import { CadastrarFornecedorComponent } from './admin/admin-home/cadastrar-fornecedor/cadastrar-fornecedor.component';
import { Page404Component } from './page404/page404.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CadastrarCategoriaDialogComponent } from './admin/admin-home/estoque/cadastrar-categoria-dialog/cadastrar-categoria-dialog.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { CadastrarTransportadoraDialogComponent, CNPJPipe } from './admin/admin-home/transportadoras/cadastrar-transportadora-dialog/cadastrar-transportadora-dialog.component';
import { CpfCnpjModule } from 'ng2-cpf-cnpj';
import { NgBrazil } from 'ng-brazil';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig} from 'ngx-mask';
export let options: Partial<IConfig> | (() => Partial<IConfig>) = {};
import { CriarAtributoDialogComponent } from './admin/admin-home/estoque/criar-atributo-dialog/criar-atributo-dialog.component'
import { ColorPickerModule } from 'ngx-color-picker';
import { CriarAtributoGrupoDialogComponent } from './admin/admin-home/estoque/criar-atributo-grupo-dialog/criar-atributo-grupo-dialog.component';
import { VincularAtributoGrupoDialogComponent } from './admin/admin-home/estoque/vincular-atributo-grupo-dialog/vincular-atributo-grupo-dialog.component';
import { UploadFotoProdutoComponent } from './admin/admin-home/estoque/upload-foto-produto/upload-foto-produto.component';
import { ConfirmarFinalizarCadastroProdutoDialogComponent } from './admin/admin-home/estoque/confirmar-finalizar-cadastro-produto-dialog/confirmar-finalizar-cadastro-produto-dialog.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageZoomModule } from 'angular2-image-zoom';
import { RastrearProdutoComponent } from './rastreador/rastrear-produto/rastrear-produto.component';
import { AgmCoreModule } from '@agm/core';
import { ProductInfoDialogComponent } from './rastreador/product-info-dialog/product-info-dialog.component';
import { RastreiosComponent } from './admin/admin-home/rastreios/rastreios.component';
import { ProdutosComponent } from './admin/admin-home/produtos/produtos.component';
import { OrigensComponent } from './admin/admin-home/origens/origens.component';
import { CadastrarProdutosComponent } from './admin/admin-home/cadastrar-produtos/cadastrar-produtos.component';
import { CriarRastreioComponent } from './rastreador/criar-rastreio/criar-rastreio.component';
import { RastreioGerarEtiquetaComponent } from './admin/admin-home/rastreio-gerar-etiqueta/rastreio-gerar-etiqueta.component';
import { RastreioInserirMovimentacaoProdutoComponent } from './admin/admin-home/rastreio-inserir-movimentacao-produto/rastreio-inserir-movimentacao-produto.component';
import { RastreioLoginComponent } from './admin/admin-home/rastreio-login/rastreio-login.component';
import { GerarEtiquetaComponent } from './admin/admin-home/gerar-etiqueta/gerar-etiqueta.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QRCodeModule } from 'angular2-qrcode';
// Import ngx-barcode module
import { NgxBarcode6Module } from 'ngx-barcode6';
import { AdicionarProdutoComponent } from './teste/adicionar-produto/adicionar-produto.component';
import { AdicionarProdutoConfirmarDadosDialogComponent } from './teste/adicionar-produto-confirmar-dados-dialog/adicionar-produto-confirmar-dados-dialog.component';
import { ListasComponent } from './teste/listas/listas.component';
import { LoginListaComponent } from './teste/login-lista/login-lista.component';
import { CriarListaComponent } from './teste/criar-lista/criar-lista.component';
import { FornecedorComponent } from './admin/admin-home/fornecedor/fornecedor.component';
import { NovaCompraComponent } from './admin/admin-home/nova-compra/nova-compra.component';
import { CompraVendaAddProdutoDialogComponent } from './admin/admin-home/compra-venda-add-produto-dialog/compra-venda-add-produto-dialog.component';
import { CompraVendaAddProdutoConfirmDialogComponent } from './admin/admin-home/compra-venda-add-produto-confirm-dialog/compra-venda-add-produto-confirm-dialog.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { ConfirmDialogComponent } from './admin/admin-home/confirm-dialog/confirm-dialog.component';
import { CompraVendaAtualizarProdutoDialogComponent } from './admin/admin-home/compra-venda-atualizar-produto-dialog/compra-venda-atualizar-produto-dialog.component';
registerLocaleData(localePtBr);
import { AngularFireModule } from "angularfire2";
// for AngularFireDatabase
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireDatabase } from "angularfire2/database";
// for AngularFireAuth
import { AngularFireAuthModule } from "angularfire2/auth";
import { AngularFireAuth } from "angularfire2/auth";
import { VerCompraComponent } from './admin/admin-home/ver-compra/ver-compra.component';
import { NovaVendaComponent } from './admin/admin-home/nova-venda/nova-venda.component';
import { CriarContaComponent } from './rastreador/criar-conta/criar-conta.component';
import { RastreioEditarPerfilUsuarioComponent } from './admin/admin-home/rastreio-editar-perfil-usuario/rastreio-editar-perfil-usuario.component';
import { RastreioAlterarSenhaComponent } from './admin/admin-home/rastreio-alterar-senha/rastreio-alterar-senha.component';
import { GerarEtiquetaPersonalizadaComponent } from './admin/admin-home/gerar-etiqueta-personalizada/gerar-etiqueta-personalizada.component';
import { CadastrarClienteComponent } from './admin/admin-home/cadastrar-cliente/cadastrar-cliente.component';
import { RastreioEditarCompraComponent } from './admin/admin-home/rastreio-editar-compra/rastreio-editar-compra.component';
import { RastreioEditarVendaComponent } from './admin/admin-home/rastreio-editar-venda/rastreio-editar-venda.component';
import { NovaVendaSimplesComponent } from './admin/admin-home/nova-venda-simples/nova-venda-simples.component';
import { VerVendaComponent } from './admin/admin-home/ver-venda/ver-venda.component';
import { CadastrarEmbalagemComponent } from './admin/admin-home/cadastrar-embalagem/cadastrar-embalagem.component';
import { UploadArquivoPluComponent } from './admin/admin-home/upload-arquivo-plu/upload-arquivo-plu.component';
import { UploadArquivoPluVincularProdutoComponent } from './admin/admin-home/upload-arquivo-plu-vincular-produto/upload-arquivo-plu-vincular-produto.component';
import { EditarEmbalagemComponent } from './admin/admin-home/editar-embalagem/editar-embalagem.component';
import { RemoverEmbalagemComponent } from './admin/admin-home/remover-embalagem/remover-embalagem.component';
import { NgxPrintModule } from 'ngx-print';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SelecionarFornecedorCompraProdutoComponent } from './admin/admin-home/selecionar-fornecedor-compra-produto/selecionar-fornecedor-compra-produto.component';
import { AtualizarProdutoCompraComponent } from './admin/admin-home/atualizar-produto-compra/atualizar-produto-compra.component';
import { VerFornecedoresCompraProdutoComponent } from './admin/admin-home/ver-fornecedores-compra-produto/ver-fornecedores-compra-produto.component';
import { EditarFornecedorCompraProdutoComponent } from './admin/admin-home/editar-fornecedor-compra-produto/editar-fornecedor-compra-produto.component';
import { ListarCodigosDeBarrasComponent } from './admin/admin-home/listar-codigos-de-barras/listar-codigos-de-barras.component';
import { CriarCodigosDeBarrasComponent } from './admin/admin-home/criar-codigos-de-barras/criar-codigos-de-barras.component';
import { BuscarClientesDialogComponent } from './admin/admin-home/buscar-clientes-dialog/buscar-clientes-dialog.component';
import { BuscarProdutoEstoqueDialogComponent } from './admin/admin-home/buscar-produto-estoque-dialog/buscar-produto-estoque-dialog.component';
import { ConsultarHistoricoRastreioComponent } from './admin/admin-home/consultar-historico-rastreio/consultar-historico-rastreio.component';

import { DateAdapter, MAT_DATE_FORMATS, SatDatepickerModule } from 'saturn-datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { TesteComponent } from './teste/teste/teste.component';

import { CriarEtiquetaPersonalizadaComponent } from './admin/admin-home/criar-etiqueta-personalizada/criar-etiqueta-personalizada.component';              //api
import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
  declarations: [
    AppComponent,
    AdminHomeComponent,
    DashboardComponent,
    EstoqueComponent,
    CompraComponent,
    VendasComponent,
    ClientesComponent,
    TransportadorasComponent,
    ConfiguracoesComponent,
    CadastrarProdutoDialogComponent,
    CadastrarProdutoComponent,
    CadastrarFornecedorComponent,
    Page404Component,
    CadastrarCategoriaDialogComponent,
    CadastrarTransportadoraDialogComponent,
    CNPJPipe,
    CriarAtributoDialogComponent,
    CriarAtributoGrupoDialogComponent,
    VincularAtributoGrupoDialogComponent,
    UploadFotoProdutoComponent,
    ConfirmarFinalizarCadastroProdutoDialogComponent,
    RastrearProdutoComponent,
    ProductInfoDialogComponent,
    RastreiosComponent,
    ProdutosComponent,
    OrigensComponent,
    CadastrarProdutosComponent,
    CriarRastreioComponent,
    RastreioGerarEtiquetaComponent,
    RastreioInserirMovimentacaoProdutoComponent,
    RastreioLoginComponent,
    GerarEtiquetaComponent,
    AdicionarProdutoComponent,
    AdicionarProdutoConfirmarDadosDialogComponent,
    ListasComponent,
    LoginListaComponent,
    CriarListaComponent,
    FornecedorComponent,
    NovaCompraComponent,
    CompraVendaAddProdutoDialogComponent,
    CompraVendaAddProdutoConfirmDialogComponent,
    ConfirmDialogComponent,
    CompraVendaAtualizarProdutoDialogComponent,
    VerCompraComponent,
    NovaVendaComponent,
    CriarContaComponent,
    RastreioEditarPerfilUsuarioComponent,
    RastreioAlterarSenhaComponent,
    GerarEtiquetaPersonalizadaComponent,
    CadastrarClienteComponent,
    RastreioEditarCompraComponent,
    RastreioEditarVendaComponent,
    NovaVendaSimplesComponent,
    VerVendaComponent,
    CadastrarEmbalagemComponent,
    UploadArquivoPluComponent,
    UploadArquivoPluVincularProdutoComponent,
    EditarEmbalagemComponent,
    RemoverEmbalagemComponent,
    SelecionarFornecedorCompraProdutoComponent,
    AtualizarProdutoCompraComponent,
    VerFornecedoresCompraProdutoComponent,
    EditarFornecedorCompraProdutoComponent,
    ListarCodigosDeBarrasComponent,
    CriarCodigosDeBarrasComponent,
    BuscarClientesDialogComponent,
    BuscarProdutoEstoqueDialogComponent,
    ConsultarHistoricoRastreioComponent,
    TesteComponent,
    ListarCodigosDeBarrasComponent,
    CriarEtiquetaPersonalizadaComponent
  ],
  imports: [
    MatSelectFilterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule, A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    HttpClientModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CpfCnpjModule,
    NgBrazil,
    TextMaskModule,
    ColorPickerModule,
    AngularFontAwesomeModule,
    NgxMaskModule.forRoot(options),
    FontAwesomeModule,
    ImageZoomModule,
    NgxBarcode6Module,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBdueqqWcN9C8odMoBxe-N9wvKGcy5YGLk'
    }),
    NgxBarcodeModule,
    QRCodeModule,
    CurrencyMaskModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyCB5BRVJhUk3d4Pdtzg4USe1AH3tJXrwkQ",
      authDomain: "thoth-rastreador.firebaseapp.com",
      databaseURL: "https://thoth-rastreador.firebaseio.com",
      projectId: "thoth-rastreador",
      storageBucket: "push-notifications-9cf18.appspot.com",
      messagingSenderId: "405109065745"
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    NgxPrintModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SatDatepickerModule
  ],
  providers: [
    {provide: LOCALE_ID,useValue: 'pt-BR'},
    MatDatepickerModule,
    [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CadastrarProdutoDialogComponent,
    CadastrarFornecedorComponent,
    CadastrarCategoriaDialogComponent,
    CadastrarTransportadoraDialogComponent,
    CriarAtributoDialogComponent,
    CriarAtributoGrupoDialogComponent,
    VincularAtributoGrupoDialogComponent,
    UploadFotoProdutoComponent,
    ConfirmarFinalizarCadastroProdutoDialogComponent,
    ProductInfoDialogComponent,
    AdicionarProdutoComponent,
    AdicionarProdutoConfirmarDadosDialogComponent,
    CriarListaComponent,
    CompraVendaAddProdutoDialogComponent,
    CompraVendaAddProdutoConfirmDialogComponent,
    ConfirmDialogComponent,
    CompraVendaAtualizarProdutoDialogComponent,
    RastreioEditarVendaComponent,
    RastreioEditarCompraComponent,
    NovaVendaSimplesComponent,
    CadastrarEmbalagemComponent,
    UploadArquivoPluComponent,
    UploadArquivoPluVincularProdutoComponent,
    EditarEmbalagemComponent,
    RemoverEmbalagemComponent,
    SelecionarFornecedorCompraProdutoComponent,
    AtualizarProdutoCompraComponent,
    VerFornecedoresCompraProdutoComponent,
    EditarFornecedorCompraProdutoComponent,
    ListarCodigosDeBarrasComponent,
    CriarCodigosDeBarrasComponent,
    BuscarClientesDialogComponent,
    BuscarProdutoEstoqueDialogComponent,
    CriarEtiquetaPersonalizadaComponent
  ]
})
export class AppModule { }
