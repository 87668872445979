import { UploadFotoProdutoComponent } from '../upload-foto-produto/upload-foto-produto.component';
import { Foto } from '../../../../model/produto.model';
import { Atributo } from './../../../../model/atributos.model';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { HttpAdminService } from '../../services/http-admin.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CriarAtributoDialogComponent } from './../criar-atributo-dialog/criar-atributo-dialog.component';

@Component({
  selector: 'app-vincular-atributo-grupo-dialog',
  templateUrl: './vincular-atributo-grupo-dialog.component.html',
  styleUrls: ['./vincular-atributo-grupo-dialog.component.css']
})
export class VincularAtributoGrupoDialogComponent implements OnInit {

  idGrupoAtributo:any;
  showProgressbar:boolean = false;

  listaAtributos:Atributo[] = [];
  listaAtributosNumerico:Atributo[] = [];
  listaAtributosTexto:Atributo[] = [];
  listaAtributosCor:Atributo[] = [];
  //##########################################
  //Galeria de fotos do produto
  fotosProduto:Foto[] = [];
  //##########################################

  constructor(
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public httpAdminService:HttpAdminService,
    public dialogRef: MatDialogRef<VincularAtributoGrupoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder
  ) {
    this.idGrupoAtributo = data.id_grupo_atributo;
  }
  ngOnInit() {
    this.geAtributos();
    this.getListaFotosProduto();
  }
  geAtributos(){
    this.showProgressbar = true;
    this.httpAdminService.getListaDeAtributos(this.idGrupoAtributo, 1, 100000, '', 'id', 'ASC').subscribe((result)=>{
      let mapData:any = result;
      this.listaAtributos = mapData;

      this.listaAtributosNumerico =  this.listaAtributos.filter((item)=>item.atributo_tipo === 1);
      this.listaAtributosTexto =  this.listaAtributos.filter((item)=>item.atributo_tipo === 2);
      this.listaAtributosCor =  this.listaAtributos.filter((item)=>item.atributo_tipo === 3);

      this.showProgressbar = false;
    });
  }
  close():void{
    this.dialogRef.close();
  }
  openSnackBar(message: string, action: string):void {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  saveData():void{

    this.showProgressbar = true;
    let arrData = [].concat(this.listaAtributosNumerico, this.listaAtributosTexto, this.listaAtributosCor);

    this.httpAdminService.vincularGrupoAtributo(arrData, this.idGrupoAtributo).subscribe((data)=>{
      this.showProgressbar = false;
      this.dialogRef.close(true);
      this.openSnackBar("Atributos salvos com sucesso!", "fechar");
    }, error =>{
      console.log("Erro :(");
    });

  }
  openDialogCriarAtributo():void{
    const dialogRef = this.dialog.open(CriarAtributoDialogComponent, {
      width: '450px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.geAtributos();
      }
    });
  }
  checkNumerico($event, index){
    this.listaAtributosNumerico[index].atributo_status = $event.checked;
  }
  checkTexto($event, index){
    this.listaAtributosTexto[index].atributo_status = $event.checked;
  }
  checkCor($event, index){
    this.listaAtributosCor[index].atributo_status = $event.checked;
  }
  removerImagem(index:number){
    this.fotosProduto.splice(index,1);
    this.updateFotosGrupoAtributos();
  }
  getListaFotosProduto(){
    this.httpAdminService.getListaFotosGrupoAtributosProduto(this.idGrupoAtributo).subscribe(data=>{
      let d:any = data;
      this.fotosProduto = d;
    });
  }  

  openDialogUploadFotoProduto():void{
    const dialogRef = this.dialog.open(UploadFotoProdutoComponent, {
      width: '550px',
      data: {id_produto:this.idGrupoAtributo}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        let fotos:Foto = {
          foto_id:1,
          foto_id_produto:this.idGrupoAtributo,
          foto_ordem:1,
          foto_url:"http://127.0.0.1:8000/uploads/images/produto/" + result
        }
        this.fotosProduto.push(fotos);
        this.updateFotosGrupoAtributos();


      }
    });

  }

  updateFotosGrupoAtributos(){
    this.showProgressbar = true;
    this.httpAdminService.updateFotosGrupoAtributos(this.fotosProduto, this.idGrupoAtributo).subscribe((data)=>{
      this.showProgressbar = false;
    });
  }



}
