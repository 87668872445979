import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';

@Component({
  selector: 'app-rastreio-alterar-senha',
  templateUrl: './rastreio-alterar-senha.component.html',
  styleUrls: ['./rastreio-alterar-senha.component.css']
})
export class RastreioAlterarSenhaComponent implements OnInit {

  registerForm: FormGroup;

  senhaAtual:string = "";
  novaSenha1:string = "";
  novaSenha2:string = "";

  mostrarProgressbar:boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public adminGlobalService:AdminGlobalService,
    public httpRastreadorService:HttpRastreadorService
  ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Alterar Senha');
    //#####################################################
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      senha: ['', Validators.required],
      senhaNova: ['', Validators.required],
      senhaNova2: ['', Validators.required]
    });
  }

  salvarNovaSenha(){
    this.mostrarProgressbar = true;
    this.httpRastreadorService.atualizarSenha(this.senhaAtual, this.novaSenha1).subscribe((data:any)=>{
      this.mostrarProgressbar = false;
      if(data.status){
        this.adminGlobalService.openSnackBar("Senha atualizada com sucesso!", "");
        this.senhaAtual = "";
        this.novaSenha1 = "";
        this.novaSenha2 = "";
      }else{
        this.adminGlobalService.openSnackBar("Não foi possível atualizar sua senha, por favor verifique se a senha antiga esta correta e tente novamente!", "");
      }

    }, error=>{
      this.mostrarProgressbar = false;
      this.adminGlobalService.openSnackBar("Houve um problema ao tentar atualizar sua senha, verifique sua conexão com a internet!", "");
    });



  }

}
