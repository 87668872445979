import { Component, OnInit, Inject } from '@angular/core';
import { CarrinhoService } from '../carrinho.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UnidMedida, Produto } from '../teste/lista.model'

@Component({
  selector: 'app-adicionar-produto-confirmar-dados-dialog',
  templateUrl: './adicionar-produto-confirmar-dados-dialog.component.html',
  styleUrls: ['./adicionar-produto-confirmar-dados-dialog.component.css']
})
export class AdicionarProdutoConfirmarDadosDialogComponent implements OnInit {

  arrUnidMedida:UnidMedida[] = [];
  arrProduto:Produto[] = [];
  produto:Produto;

  constructor(
    public carrinhoService:CarrinhoService,
    public dialogRef: MatDialogRef<AdicionarProdutoConfirmarDadosDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:Produto
  ) {
    this.produto = data;
  }

  ngOnInit() {




  }

  adicionarProduto(){
    this.carrinhoService.listaCompra.arrProdutos.push(this.produto);
    this.carrinhoService.openSnackBar("Produto adicionado ao carrinho!", "fechar");
    this.onNoClick(true);
  }

  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }

}
