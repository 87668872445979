import { HttpAdminService } from '../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UploadFileService } from '../services/upload-file.service';
import { UploadArquivoPluVincularProdutoComponent } from '../upload-arquivo-plu-vincular-produto/upload-arquivo-plu-vincular-produto.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NovaVenda, ProdutoEstoque, PLUProduto } from 'src/app/teste/teste/lista.model';

export interface PluDados{
  plu_id_pedido:string;
  plu_data_entrega:string;
  plu_numero_fornecedor:string;
  plu_produtos:PluProduto[]
}
export interface PluProduto{
  plu_nome_produto:string;
  plu_codigo_barras:string;
  plu_codigo_barras_tipo:string;
  plu_capacidade_embalagem:string;
  produto_vinculado:ProdutoEstoque
}

@Component({
  selector: 'app-upload-arquivo-plu',
  templateUrl: './upload-arquivo-plu.component.html',
  styleUrls: ['./upload-arquivo-plu.component.css']
})
export class UploadArquivoPluComponent implements OnInit {

  panelOpenState = false;

  form: FormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' };
  uploadStatus:boolean = false;
  uploadProgresso:number = 0;
  erroEnvio:boolean = false;
  arquivoPluEnviado:boolean = false;
  restamVinculosRestantes:boolean = false;
  showProgressbar:boolean = false;

  arrPluProduto:PluProduto[] = [];

  pluDados:PluDados = {
    plu_id_pedido:null,
    plu_data_entrega:null,
    plu_numero_fornecedor:null,
    plu_produtos:[]
  }
  constructor(
    public uploadService: UploadFileService,
    public adminGlobalService:AdminGlobalService,
    private formBuilder: FormBuilder,
    public httpAdminService:HttpAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UploadArquivoPluComponent>,
    @Inject(MAT_DIALOG_DATA) public novaVenda:NovaVenda
  ) { }
  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: ['']
    });

  }
  onNoClick(status:boolean): void {
    if(this.arquivoPluEnviado){
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '600px',
        data: {'titulo':'Fechar janela', 'pergunta':'Você realmente deseja fechar esta janela e cancelar o vinculo dos produtos?'}
      });
      dialogRef.afterClosed().subscribe((result:boolean) => {
        if(result){
          this.dialogRef.close();
          
        }
      });
    }else{
      this.dialogRef.close();
    }
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
      this.onSubmit();
    }
  }
  onSubmit() {
    if(this.form.get('avatar').value != ''){
      this.uploadStatus = true;
      const formData = new FormData();
      formData.append('image', this.form.get('avatar').value);
      this.uploadService.uploadArquivoPlu(formData).subscribe((data:any)=>{
        if(data.message != undefined){
          this.uploadProgresso = data.message
        }
        if(data.status != undefined){
          this.uploadStatus = false;
          if(data.plu_produtos != undefined){
            this.pluDados = data;
            this.arquivoPluEnviado = true;
            this.novaVenda.plu_data.plu_data_entrega = this.pluDados.plu_data_entrega;
            this.novaVenda.nPedido = this.pluDados.plu_id_pedido;
            this.novaVenda.plu_data.plu_id_pedido = this.pluDados.plu_id_pedido;
            this.novaVenda.plu_data.plu_numero_fornecedor = this.pluDados.plu_numero_fornecedor;
          }
          this.adminGlobalService.openSnackBar("Arquivo enviado com sucesso!", "fechar");
        }
      },error =>{
        //this.erroEnvio = true;
        this.adminGlobalService.openSnackBar("Houve um erro ao tentar enviar o arquivo, verifique o tipo de arquivo enviado, ele pode não ser suportado ou você esta sem conexão com a Internet!", "fechar");
      });
    }else{
      this.adminGlobalService.openSnackBar("Você precisa selecionar um arquivo antes de enviar!", "fechar");
    }

  }
  openDialogImportarPLU(index){
    /* console.log("this.compra : " + JSON.stringify(this.novaVenda)); */
    const dialogRef = this.dialog.open(UploadArquivoPluVincularProdutoComponent, {
      width: '600px',
      disableClose:true,
      data:this.novaVenda
    });
    dialogRef.afterClosed().subscribe((result:ProdutoEstoque) => {
      if(result != null){

        this.pluDados.plu_produtos[index].produto_vinculado = result;

        this.novaVenda.arrProduto.map(d=>{
          if(result.id_lote === d.id_lote){
            let PLU:PLUProduto = {
              plu_nome_produto:this.pluDados.plu_produtos[index].plu_nome_produto,
              plu_codigo_barras:this.pluDados.plu_produtos[index].plu_codigo_barras,
              plu_codigo_barras_tipo:this.pluDados.plu_produtos[index].plu_codigo_barras_tipo,
              plu_capacidade_embalagem:this.pluDados.plu_produtos[index].plu_capacidade_embalagem
            }
            d.plu_produto = PLU;
            d.plu_importado = true;
          }
        });
      }
    });
  }
  salvarDados(){
    let erros:number = 0;
    this.pluDados.plu_produtos.map(d=>{
      if(d.produto_vinculado == null){
        erros++;
      }
    });

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '600px',
        data: {'titulo':'Salvar dados', 'pergunta':'Você realmente deseja salvar os dados?'}
      });
      dialogRef.afterClosed().subscribe((result:boolean) => {
        if(result){
          this.dialogRef.close(this.novaVenda);
/*           this.showProgressbar = true;
          this.httpAdminService.salvarVinculoPluDadosComVenda(this.pluDados).subscribe((data:any)=>{
            this.showProgressbar = false;
            if(data.status == true){
              this.adminGlobalService.openSnackBar("Produtos vinculados com sucesso!!", "fechar");
              this.dialogRef.close(true);
            }else{
              this.adminGlobalService.openSnackBar("Houve um erro ao tentar vincular os produtos, verifique sua conexão com a internet e tente novamente!", "fechar");
            }
          }, error=>{
            this.showProgressbar = false;
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar vincular os produtos, verifique sua conexão com a internet e tente novamente!", "fechar");
          }); */
        }
      });
    
  }
}
