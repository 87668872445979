import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl } from '@angular/forms';
import { HttpAdminService } from '../../services/http-admin.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-criar-atributo-dialog',
  templateUrl: './criar-atributo-dialog.component.html',
  styleUrls: ['./criar-atributo-dialog.component.css']
})
export class CriarAtributoDialogComponent implements OnInit {

  tipoAtributoSelected:number;
  valorAtributo:any = '';
  nomeAtributo:string = "";
  color:any = '';
  form:FormGroup;
  valorAtributoNumerico:number;
  cpPosition:any = "bottom";
  cpToggle:boolean = false;
  showProgressbar:boolean = false;

  constructor(
    public _snackBar: MatSnackBar,
    public httpAdminService:HttpAdminService,
    public dialogRef: MatDialogRef<CriarAtributoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder
  ) {


  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      atributoValor: ['', Validators.required],
      atributoValorNumerico: ['', Validators.required],
      nomeAtributo: ['', Validators.required],
      valorAtributoNumerico: ['', Validators.required]
    }, {
    });
  }
  close() {
    this.dialogRef.close();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  alterarTipoAtributo(){
    if(this.tipoAtributoSelected == 3){
      this.cpToggle = true;
    }else{
      this.cpToggle = false;
    }
  }
  saveData(){
    if(this.form.value.nomeAtributo.length > 0){
      if(this.tipoAtributoSelected != undefined){
        if(this.tipoAtributoSelected == 3){
          if(this.color.length > 0){
            //###########################################
            this.sendDataToServer(this.form.value.nomeAtributo, this.tipoAtributoSelected, this.color);
            //###########################################
          }else{
            this.openSnackBar("Você precisa selecionar uma cor para o novo atributo", "fechar");
          }
        }else if(this.tipoAtributoSelected == 2){
          if(this.form.value.atributoValor){
            //###########################################
            this.sendDataToServer(this.form.value.nomeAtributo, this.tipoAtributoSelected, this.form.value.atributoValor);
            //###########################################
          }else{
            this.openSnackBar("Você precisa informar o valor para o novo atributo", "fechar");
          }
        }else{
          if(this.form.value.atributoValorNumerico){
            //###########################################
            this.sendDataToServer(this.form.value.nomeAtributo, this.tipoAtributoSelected, this.form.value.atributoValorNumerico);
            //###########################################
          }else{
            this.openSnackBar("Você precisa informar o valor para o novo atributo", "fechar");
          }
        }
      }else{
        this.openSnackBar("Você precisa selecionar um tipo para o novo atributo", "fechar");
      }
    }else{
      this.openSnackBar("Você precisa informar o nome do novo atributo", "fechar");
    }
  }
  sendDataToServer(nomeAtributo:string, tipoAtributo:number, valorAtributo:string){
    this.showProgressbar = true;
    this.httpAdminService.createNewAtributo(nomeAtributo, tipoAtributo, valorAtributo).subscribe((data)=>{
      this.openSnackBar("Atributo criado com sucesso!", "fechar");
      this.showProgressbar = false;
      this.dialogRef.close(true);
    });
  }
}
