import { Cliente } from '../../../model/cliente.model';
import { HttpAdminService } from './../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  clientes:Cliente[] = [];
  progressbarAtivo:boolean = false;
  termoBusca:string = "";

  //###############################################################
  //###############################################################
  //#################### Paginação ################################
  orderAsc = 'ASC';
  orderDesc = 'DESC';
  order:any = 'ASC';
  orderBy:any = 'nome';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;

  buscaRealizada:boolean = false;
  firstLoad:boolean = false;


  //###############################################################
  //######################################################### ######
  //###############################################################

  constructor(
    public adminGlobalService: AdminGlobalService,
    public httpAdminService:HttpAdminService,
    private route: ActivatedRoute,
    private router: Router
    ) {
      //Seta o titulo da página
    }

  ngOnInit() {
    this.adminGlobalService.setPageTitle('Clientes');
    //################################################
    let paginaRota = this.route.snapshot.paramMap.get("pagina");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(paginaRota != null){
      this.paginaAtual = paginaRota;
      if(!this.firstLoad){
        this.firstLoad = true;
        this.getClientList(paginaRota);
      }

    }else{
      this.firstLoad = true;
      this.getClientList(this.paginaAtual);
    }

  }
  getClientList(paginaAtual){

    this.progressbarAtivo = true;

    this.httpAdminService.getClientList(paginaAtual,this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe(data=>{

      let mapData:any = data;

      this.paginasProximas = [];
      this.paginasAnteriores = [];

      this.clientes = mapData.data;
      this.progressbarAtivo = false;
      this.ultimaPagina = mapData.last_page;

      if(mapData.last_page > 1){
        this.exibirPaginacao = true;
      }
      //###################################################
      //Criação das próximas páginas
      let prox = Number(paginaAtual) + 1;

      for( let i = prox; i <= mapData.last_page; i++){
        if(this.paginasProximas.length < this.linksPorPagina){
          this.paginasProximas.push(i);
        }
      }
      //###################################################
      //###################################################
      //###################################################
      //Criação das páginas anteriores
      let ant = paginaAtual - this.linksPorPagina;
      for( let j = ant; j < paginaAtual; j++){
        if(j>0){
          this.paginasAnteriores.push(j);
        }
      }
      //###################################################
      //###################################################
      this.paginaAtual = paginaAtual;
      this.buscaRealizada = true;
      this.scrollTop();

    }, error=>{
      this.progressbarAtivo = false;
      console.log('erro! : ' + error);
    });
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getClientList(paginaAtual);
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag)
      this.getClientList(pag);
    }
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getClientList(pag);
    }
  }
  changeRoute(paginaAtual:any) {
    this.router.navigate(['admin/cliente/'+paginaAtual]);
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.getClientList(1);
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;

    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  orderList(){
    if(this.order == this.orderAsc){
      this.order= this.orderDesc;
    }else{
      this.order= this.orderAsc;
    }
    this.getClientList(this.paginaAtual);
  }
}
