import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Compra } from 'src/app/model/compra.model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UnidMedida } from 'src/app/teste/teste/lista.model';
import { HttpAdminService } from '../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { CadastrarEmbalagemComponent } from '../cadastrar-embalagem/cadastrar-embalagem.component';
import { Embalagem } from 'src/app/model/embalagem.model';
import { Router } from '@angular/router';
import { EditarEmbalagemComponent } from '../editar-embalagem/editar-embalagem.component';
import { RemoverEmbalagemComponent } from '../remover-embalagem/remover-embalagem.component';

export interface VendaSimplesCliente{
  cliente_id: number;
  cliente_nome: string;
  cliente_cnpj: string;
  cliente_endereco_longitude: string;
  cliente_endereco_latitude: string;
  cliente_pais: string;
  cliente_estado: string;
  cliente_cidade: string;
  cliente_bairro: string;
  cliente_endereco: string;
  cliente_numero: string;
  cliente_complemento: string;
  cliente_observacao: string;
}
export interface VendaSimples{
  venda_id_cliente:number;
  venda_nota_fiscal:string;
  venda_produtos:VendaSimplesProduto[];
}
export interface VendaSimplesProduto{
  venda_produto_id:number;
  venda_produto_id_compra:number;
  venda_produto_quantidade:number;
  venda_produto_unidade_medida:UnidMedida;
  compra_venda_produto_id:number;
  produto_vendido_id:number;
  produto_vendido_nome:string;
  produto_vendido_imagem:string;
  produto_vendido_valor:number;
  produto_vendido_cod_rastreio:string;
  produto_selecionado:boolean;
  produto_embalagem:Embalagem;
}
@Component({
  selector: 'app-nova-venda-simples',
  templateUrl: './nova-venda-simples.component.html',
  styleUrls: ['./nova-venda-simples.component.css']
})
export class NovaVendaSimplesComponent implements OnInit {

  progressbarFinalizarVenda:boolean = false;

  clienteSelecionado:boolean = false;
  cliente:VendaSimplesCliente;

  showResultadoBuscaCliente:boolean = false;
  progressbarBuscaCliente:boolean = false;
  arrBuscaCliente:VendaSimplesCliente[] = [];
  termoBuscaClientes:string = "";

  vendaSimples:VendaSimples = {
    venda_id_cliente:null,
    venda_nota_fiscal:null,
    venda_produtos:[]
  }

  arrEmbalagens:Embalagem[] = [];

  constructor(
    private router: Router,
    public adminGlobalService:AdminGlobalService,
    public httpAdminService:HttpAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<NovaVendaSimplesComponent>,
    @Inject(MAT_DIALOG_DATA) public compra:Compra
  ) {

  }
  ngOnInit() {
    this.compra.compra_produtos.map((data)=>{
      let prod:VendaSimplesProduto = {
        venda_produto_id:data.compra_produto_id,
        venda_produto_id_compra:data.compra_produto_id_compra,
        venda_produto_quantidade:data.compra_produto_quantidade,
        venda_produto_unidade_medida:data.compra_produto_unidade_medida,
        compra_venda_produto_id:data.compra_venda_produto_id,
        produto_vendido_id:data.produto_comprado_id,
        produto_vendido_nome:data.produto_comprado_nome,
        produto_vendido_imagem:data.produto_comprado_imagem,
        produto_vendido_valor:data.produto_comprado_valor,
        produto_vendido_cod_rastreio:data.produto_comprado_cod_rastreio,
        produto_selecionado:false,
        produto_embalagem:null,
      }
      this.vendaSimples.venda_produtos.push(prod);
    });
    this.getListaEmbalagens();
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }
  openDialogConfirmarFinalizacao(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Finalizar venda', 'pergunta':'Você realmente deseja finalizar esta venda?'}
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      if(result){
        let arrV:VendaSimples = {
          venda_id_cliente:this.vendaSimples.venda_id_cliente,
          venda_nota_fiscal:this.vendaSimples.venda_nota_fiscal,
          venda_produtos:[]
        }

        this.vendaSimples.venda_produtos.map((data)=>{
          if(data.produto_selecionado){
            arrV.venda_produtos.push(data);
          }
        });

        this.progressbarFinalizarVenda = true;
        this.httpAdminService.salvarVendaSimples(arrV).subscribe((data:any)=>{
          if(data.status){

            this.onNoClick(true);
            this.adminGlobalService.openSnackBar("Venda Realizada com sucesso!", "fechar");
            this.progressbarFinalizarVenda = false;
            this.router.navigateByUrl('/admin/vervenda/' + data.id_venda);
          }
        }, error=>{
          this.adminGlobalService.openSnackBar("Não foi possível salvar sua venda, houve um erro no processo!", "fechar");
          this.progressbarFinalizarVenda = false;
        });

      }else{
        console.log("VENDA NÃO FINALIZADA");
      }
    });
  }
  getTotalItensSelecionados(){
    let total = 0;
    this.vendaSimples.venda_produtos.map((data)=>{
      if(data.produto_selecionado){
        total++;
      }
    });
    return total;
  }
  selecionarCliente(cliente:VendaSimplesCliente){
    this.cliente = cliente;
    this.clienteSelecionado = true;
    this.showResultadoBuscaCliente = false;
    this.vendaSimples.venda_id_cliente = cliente.cliente_id;
    //this.compraService.openSnackBar("Fornecedor Selecionado!", "fechar");
  }
  showBuscaClienteResult(){
    //this.buscarClientes();
    this.showResultadoBuscaCliente = true;
  }
  hideBuscaClienteResult(){
    setTimeout(()=>{
      //this.showResultadoBuscaCliente = false;
    }, 300);

  }
  buscarClientes(){
    this.showBuscaClienteResult();
    this.progressbarBuscaCliente = true;
    this.httpAdminService.getClientList(1, 50, this.termoBuscaClientes, 'nome', "ASC").subscribe((resultado:any)=>{
      this.arrBuscaCliente = resultado.data;
      this.progressbarBuscaCliente = false;
    }, error=>{
      this.progressbarBuscaCliente = false;
    });
  }
  removerCliente(){
    this.showResultadoBuscaCliente = true;
    this.clienteSelecionado = false;
    this.cliente = {
      cliente_id: null,
      cliente_nome: "",
      cliente_cnpj: "",
      cliente_endereco_longitude: "",
      cliente_endereco_latitude: "",
      cliente_pais: "",
      cliente_estado: "",
      cliente_cidade: "",
      cliente_bairro: "",
      cliente_endereco: "",
      cliente_numero: "",
      cliente_complemento: "",
      cliente_observacao: ""
    };
  }
  getTotalItensNaoVendidos(){
    let total = 0;
    this.vendaSimples.venda_produtos.map((data)=>{
      if(data.compra_venda_produto_id == null){
        total++;
      }
    });
    return total;
  }
  selecionarTodosProdutos(checked:any){
    if(checked == true){
      this.vendaSimples.venda_produtos.map((data, index)=>{
        if(data.compra_venda_produto_id == null){
          this.vendaSimples.venda_produtos[index].produto_selecionado = true;
        }
      });
    }else{
      this.vendaSimples.venda_produtos.map((data, index)=>{
        if(data.compra_venda_produto_id == null){
          this.vendaSimples.venda_produtos[index].produto_selecionado = false;
        }
      });
    }


  }
  cadastrarEmbalagem(index){
    const dialogRef = this.dialog.open(CadastrarEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.arrEmbalagens.push(result);
        this.vendaSimples.venda_produtos[index].produto_embalagem = result;
      }
    });
  }
  atualizarEmbalagem(){
    const dialogRef = this.dialog.open(EditarEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        
        
      }
    });
  }
  removerEmbalagem(){
    const dialogRef = this.dialog.open(RemoverEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getListaEmbalagens();
    });
  }
  getListaEmbalagens(){
    this.httpAdminService.getListaEmbalagem().subscribe((data:any)=>{
      this.arrEmbalagens = data;
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível obter a lista de embalagens para os produtos, por favor verifique sua conexão com a internet", "fechar");
    });
  }

}
