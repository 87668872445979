import { Produtos } from 'src/app/rastreador/model/produto.model';
import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent implements OnInit {

  arrProdutos:Produtos[] = [];

  orderAsc = 'ASC';
  orderDesc = 'DESC';
  order:any = 'ASC';
  orderBy:any = 'nome';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  termoBusca:string = '';

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;

  buscaRealizada:boolean = false;
  progressbarAtivo:boolean = false;

  totalProdutos:number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public adminGlobalService: AdminGlobalService,
    public httpRastreadorService:HttpRastreadorService
  ) {
    this.adminGlobalService.setPageTitle('Produtos');
  }
  ngOnInit() {
    let paginaRota = this.route.snapshot.paramMap.get("pagina");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(paginaRota != null){
      this.paginaAtual = paginaRota;
      this.getListaProdutos(paginaRota);
    }else{
      this.getListaProdutos(1);
    }
  }
  getListaProdutos(paginaAtual){
    this.progressbarAtivo = true;
    this.httpRastreadorService.getListaProdutos(paginaAtual, this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe((data)=>{
      let d:any = data;
      this.arrProdutos = d.data;

      this.arrProdutos.map((data)=>{
        data.rastreio_produtos_status = false;
      });
      this.buscaRealizada = true;
      this.progressbarAtivo = false;
      this.totalProdutos = d.total;

      this.paginasProximas = [];
      this.paginasAnteriores = [];
      //###################################################
      //Criação das próximas páginas
      let prox = Number(paginaAtual) + 1;

      for( let i = prox; i <= d.last_page; i++){
        if(this.paginasProximas.length < this.linksPorPagina){
          this.paginasProximas.push(i);
        }
      }
      //###################################################
      //###################################################
      //###################################################
      //Criação das páginas anteriores
      let ant = paginaAtual - this.linksPorPagina;

      for( let j = ant; j < paginaAtual; j++){
        if(j>0){
          this.paginasAnteriores.push(j);
        }

      }
      //###################################################
      //###################################################

      this.ultimaPagina = d.last_page;
      this.paginaAtual = paginaAtual;
      this.buscaRealizada = true;
      this.scrollTop();
    }, error=>{


    });
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.getListaProdutos(1);
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag);
      this.getListaProdutos(pag);
    }
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getListaProdutos(pag);
    }
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getListaProdutos(paginaAtual);
    }
  }
  changeRoute(paginaAtual:any) {
    this.router.navigate(['admin/produtos/'+paginaAtual]);
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  orderList(){
    if(this.order == this.orderAsc){
      this.order= this.orderDesc;
    }else{
      this.order= this.orderAsc;
    }
    this.getListaProdutos(this.paginaAtual);
  }
  onImageLoad(index){
    this.arrProdutos[index].rastreio_produtos_status = true;
  }
}
