import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { UnidMedida } from 'src/app/rastreador/model/produto.model';
import { VendaService } from '../services/venda.service';
import { filter } from 'rxjs/operators';

export interface Venda{
  venda_id:number;
  numero_pedido:number;
  venda_id_cliente:number;
  venda_data:string;
  venda_nota_fiscal:string;
  venda_id_fornecedor:number;
  venda_fornecedor_nome:string;
  venda_fornecedor_cnpj:string;
  venda_produtos:VendaProduto[];
}

export interface VendaProduto{
  venda_produto_id:number;
  produto_venda_id_compra_produto_fornecedor:number;
  produto_venda_quantidade:number;
  produto_venda_unidade_medida:UnidMedida;
  produto_venda_produto_nome:number;
  produto_venda_produto_valor:string;
  produto_venda_embalagem_id:string;
  produto_venda_embalagem_nome:number;
  produto_venda_embalagem_capacidade:string;
  produto_venda_embalagem_unidade_medida:string;
  rastreio_id:string;
  selecionado:boolean;
}

@Component({
  selector: 'app-vendas',
  templateUrl: './vendas.component.html',
  styleUrls: ['./vendas.component.css'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('0.3s ease-in', style({opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class VendasComponent implements OnInit {

  arrVenda:Venda[] = [];
  orderAsc = 'ASC';
  orderDesc = 'DESC';
  order:any = 'DESC';
  orderBy:any = 'id';
  itensPorPagina:string = '10';
  paginaAtual:any = 1;
  ultimaPagina:any = 1;
  termoBusca:string = '';
  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];
  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;
  buscaRealizada:boolean = false;
  progressbarAtivo:boolean = false;
  totalCompra:any;
  showFabButton:boolean = false;
  picker:any;
  date:any;
  dateRangeDisp:any
  tipoFiltro:string = "padrao";
  dataBuscaInicial:string = "";
  dataBuscaFinal:string = "";

  requestAtiva:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService,
    private location: Location,
    public vendaService:VendaService
    ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Vendas');
    //#####################################################
  }
  ngOnInit() {
    let paginaRota = this.route.snapshot.paramMap.get("pagina");
    let qtdPorPagina = this.route.snapshot.paramMap.get("qtdPorPagina");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(qtdPorPagina != null){
      this.itensPorPagina = qtdPorPagina;
    }
    if(paginaRota != null){
      this.paginaAtual = paginaRota;
      this.getListaVenda(paginaRota);
    }else{
      this.getListaVenda(1);
    }

/*     this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let d = event.url.split('/');
      let paginaN = d[3];
      let qtd = d[4];
      this.itensPorPagina = qtd;
      this.paginaAtual = paginaN;
      if(d[2]==="vendas"){
        this.getListaVenda(this.paginaAtual);
      }
      
    }); */
  }
  displayHideFabButton(){
    this.showFabButton = !this.showFabButton;
  }
  public getListaVenda(paginaAtual){

    if(!this.requestAtiva){
      this.requestAtiva = true;
      this.progressbarAtivo = true;
      this.httpRastreadorService.getListaVenda(this.tipoFiltro, this.dataBuscaInicial, this.dataBuscaFinal, paginaAtual, this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe((data)=>{
        let d:any = data;
        this.arrVenda = d.data;
        this.buscaRealizada = true;
        this.progressbarAtivo = false;
        this.totalCompra = d.total;
        this.paginasProximas = [];
        this.paginasAnteriores = [];
        //###################################################
        //Criação das próximas páginas
        let prox = Number(paginaAtual) + 1;
        for( let i = prox; i <= d.last_page; i++){
          if(this.paginasProximas.length < this.linksPorPagina){
            this.paginasProximas.push(i);
          }
        }
        //###################################################
        //###################################################
        //###################################################
        //Criação das páginas anteriores
        let ant = paginaAtual - this.linksPorPagina;
        for( let j = ant; j < paginaAtual; j++){
          if(j>0){
            this.paginasAnteriores.push(j);
          }
        }
        //###################################################
        //###################################################
        this.ultimaPagina = d.last_page;
        this.paginaAtual = paginaAtual;
        this.buscaRealizada = true;
        this.scrollTop();

        this.arrVenda.map((d, index)=>{
          this.getProdutosVenda(d.venda_id, index);
        });
        this.requestAtiva = false;
      }, error=>{
        this.requestAtiva = false;
        this.progressbarAtivo = false;
      });
    }


  }
  orderList(){
    if(this.order == this.orderAsc){
      this.order= this.orderDesc;
    }else{
      this.order= this.orderAsc;
    }
    this.getListaVenda(this.paginaAtual);
    console.log("date: " + this.date);
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.changeRoute(this.paginaAtual);
    this.getListaVenda(1);
  }
  changeRoute(paginaAtual:any) {
    //this.router.navigate(['admin/vendas/'+paginaAtual]);
    this.location.go( 'admin/vendas/'+paginaAtual+'/' + this.itensPorPagina );
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getListaVenda(paginaAtual);
    }
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getListaVenda(pag);
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag);
      this.getListaVenda(pag);
    }
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;

    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  @ViewChild("myInputSearch", {static:false}) inputEl: ElementRef;
  focusInputSearch() {
    this.inputEl.nativeElement.focus()
  }
  getProdutosVenda(idVenda:any, index:any){
    this.progressbarAtivo = true;
    this.httpRastreadorService.getProdutosVenda(idVenda).subscribe((data:any)=>{
      let arrProd:VendaProduto[] = [];
      data.map(d=>{
        let prod:VendaProduto = {
          venda_produto_id:d.venda_produto_id,
          produto_venda_id_compra_produto_fornecedor:d.produto_venda_id_compra_produto_fornecedor,
          produto_venda_quantidade:d.produto_venda_quantidade,
          produto_venda_unidade_medida:d.produto_venda_unidade_medida,
          produto_venda_produto_nome:d.produto_venda_produto_nome,
          produto_venda_produto_valor:d.produto_venda_produto_valor,
          produto_venda_embalagem_id:d.produto_venda_embalagem_id,
          produto_venda_embalagem_nome:d.produto_venda_embalagem_nome,
          produto_venda_embalagem_capacidade:d.produto_venda_embalagem_capacidade,
          produto_venda_embalagem_unidade_medida:d.produto_venda_embalagem_unidade_medida,
          rastreio_id:d.rastreio_id,
          selecionado:false
        }
        arrProd.push(prod);
      });
      this.arrVenda[index].venda_produtos = arrProd;

      this.progressbarAtivo = false;
    }, error=>{
      this.progressbarAtivo = false;
    });
  }
  getProdutosImpressao(){
    let arrProd:any[] = [];
    this.arrVenda.map(d=>{
      d.venda_produtos.map(p=>{
        if(p.selecionado){

          let d:any = {
            venda_produto_id:p.venda_produto_id
          }

          arrProd.push(d );
        }
      });
    });
    this.vendaService.arrProdutosImpressao = arrProd;
    this.router.navigate(['admin/geraretiqueta']);
  }
  getTotalProdutosImpressao():number{
    let total:number = 0;

    try {
      this.arrVenda.map(d=>{
        d.venda_produtos.map(p=>{
          if(p.selecionado){
            total++;
          }
        });
      });
    } catch (error) {
      
    }


    return total;
  }
// Code behind
saveDate(event: any) {
  // look at how the date is emitted from save 
  try {
    this.dataBuscaInicial = event.target.value.begin._i.year + "-" + (event.target.value.begin._i.month + 1) + "-" + event.target.value.begin._i.date;
    this.dataBuscaFinal = event.target.value.end._i.year + "-" + (event.target.value.end._i.month + 1) + "-" + (event.target.value.end._i.date +1);
    this.getListaVenda(this.paginaAtual);
  } catch (error) {
    
  }

}


}
