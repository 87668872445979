import { Injectable } from '@angular/core';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UnidMedida } from '../../../rastreador/model/produto.model';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  public compra:Compra = {
    compra_id:0,
    compra_numero_pedido:null,
    compra_id_cliente:0,
    compra_data:"",
    compra_nota_fiscal:"",
    compra_id_fornecedor:0,
    compra_fornecedor_nome:"",
    compra_fornecedor_cnpj:"",
    compra_produtos:[]
  }

  constructor(private _snackBar: MatSnackBar) {
   }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  salvarStorage(){
    localStorage.setItem('novaCompra', JSON.stringify(this.compra));
  }
}
