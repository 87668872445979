import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { PerfilEmpresa, UserPerfil, Estado } from 'src/app/rastreador/model/util.model';
import { ProdutoPLU } from 'src/app/model/venda.model';
import { number } from 'ng-brazil/number/validator';


export interface Etiqueta{
  rastreio_id_int:number;
  produto_nome:string;
  produto_quantidade:number;
  produto_unidade_medida:number;
  produto_venda_produto_id:number;
  rastreio_embalagem_capacidade:string;
  rastreio_embalagem_nome:string;
  rastreio_embalagem_unidade_medida:number;
  produto_plu:ProdutoPLU[]
}

export enum LinhaAlinhamento{
  esquerda = 'left',
  centro = 'center',
  direita = 'right'
}
export interface LinhaEtiqueta{
  texto:string;
  fontSize:number;
  negrito:boolean;
  alinhamento:LinhaAlinhamento
}

@Component({
  selector: 'app-gerar-etiqueta',
  templateUrl: './gerar-etiqueta.component.html',
  styleUrls: ['./gerar-etiqueta.component.css']
})
export class GerarEtiquetaComponent implements OnInit {

  etiqueta:Etiqueta = {
    rastreio_id_int:null,
    produto_nome:"",
    produto_quantidade:0,
    produto_unidade_medida:0,
    produto_venda_produto_id:0,
    rastreio_embalagem_capacidade:"",
    rastreio_embalagem_nome:"",
    rastreio_embalagem_unidade_medida:0,
    produto_plu:[]
  };

  idRastreio:any;
  etiquetas:any[] = [];
  quantidadeEtiquetas:number = 2;
  display:boolean = false;
  arrEtiquetaLinha:LinhaEtiqueta[] = [];
  brand:string = "BY THOTHCOMPANY";
  linhaSelecionada:number = 15;
  linha1FontSize:any[] = [];
  bottomBarCodeValue:string = null;
  bottomBarCodeWidth:number = 1.3;
  exibirBarCode:boolean = true;
  progressbarCarregandoEtiqueta:boolean = false;
  perfilEmpresa:PerfilEmpresa = {
    empresaRazaoSocial:"",
    empresaNome:"",
    empresaCNPJ:"",
    empresaInscricaoEstadual:"",
    empresaEstado:1,
    empresaMunicipio:"",
    empresaBairro:"",
    empresaEndereco:"",
    empresaNumero:"",
    empresaLongitude:"",
    empresaLatitude:"",
    empresaTelefoneFixo:"",
    empresaTelefoneCelular:"",
    empresaEmail:"",
    empresaEmail2:"",
    empresaSenha:"",
    empresaSenha2:""
  }
  
  userPerfil:UserPerfil;

  constructor(
    public adminGlobalService: AdminGlobalService,
    private route: ActivatedRoute,
    public httpRastreadorService:HttpRastreadorService
  ) {
    this.idRastreio = this.route.snapshot.paramMap.get("idRastreio");
  }
  ngOnInit() {

    for(let i = 1; i <= 30; i++){
      this.linha1FontSize.push(i);
    }
    for(let i=0; i<this.quantidadeEtiquetas;i++){
      this.etiquetas.push(i);
    }
    
    this.progressbarCarregandoEtiqueta = true;
    this.httpRastreadorService.getProdutoGerarEtiqueta(this.idRastreio).subscribe((data:any)=>{
      this.httpRastreadorService.getLoggedDataUser().subscribe((userData:any)=>{
        this.userPerfil = userData[0];
        let abjEstado:Estado[] = this.adminGlobalService.arrEstados.filter(x => x.estado_id == this.userPerfil.user_estado);
        this.httpRastreadorService.getMunicipioById(this.userPerfil.user_cidade).subscribe((dataMunicipio:any)=>{
          this.etiqueta = data; 

          let etiquetaLinha1:LinhaEtiqueta = {
            texto:this.userPerfil.user_nome,
            fontSize:15,
            negrito:true,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha2:LinhaEtiqueta = {
            texto:this.userPerfil.user_endereco + " " +this.userPerfil.user_numero,
            fontSize:12,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha3:LinhaEtiqueta = {
            texto: this.userPerfil.user_bairro + " - " + dataMunicipio[0].municipio_nome + " - " + abjEstado[0].estado_uf,
            fontSize:12,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha4:LinhaEtiqueta = {
            texto:this.userPerfil.user_nome,
            fontSize:12,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha5:LinhaEtiqueta = {
            texto:"CNPJ: " + this.formatCnpjCpf(this.userPerfil.user_cnpj),
            fontSize:12,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha6:LinhaEtiqueta = {
            texto:"",
            fontSize:15,
            negrito:true,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha7:LinhaEtiqueta = {
            texto:this.etiqueta.produto_nome,
            fontSize:18,
            negrito:true,
            alinhamento:LinhaAlinhamento.centro
          };
          let etiquetaLinha8:LinhaEtiqueta = {
            texto:data.rastreio_embalagem_nome,
            fontSize:15,
            negrito:true,
            alinhamento:LinhaAlinhamento.centro
          };
          let etiquetaLinha9:LinhaEtiqueta = {
            texto:"DATA DE ENTREGA :",
            fontSize:12,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha10:LinhaEtiqueta = {
            texto:"Rastreie em: rast.thothcompany.com.br/rastrear",
            fontSize:10,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };
          let etiquetaLinha11:LinhaEtiqueta = {
            texto:"Cod Rastreio : " + this.idRastreio + " (" +this.etiqueta.rastreio_id_int+ ")",
            fontSize:10,
            negrito:false,
            alinhamento:LinhaAlinhamento.esquerda
          };

          //console.log("this.etiqueta.produto_plu : " + JSON.stringify(this.etiqueta.produto_plu));

          this.arrEtiquetaLinha.push(etiquetaLinha1);
          this.arrEtiquetaLinha.push(etiquetaLinha2);
          this.arrEtiquetaLinha.push(etiquetaLinha3);
          //this.arrEtiquetaLinha.push(etiquetaLinha4);
          this.arrEtiquetaLinha.push(etiquetaLinha5);
          this.arrEtiquetaLinha.push(etiquetaLinha6);
          this.arrEtiquetaLinha.push(etiquetaLinha7);
          this.arrEtiquetaLinha.push(etiquetaLinha8);
          this.arrEtiquetaLinha.push(etiquetaLinha9);
          this.arrEtiquetaLinha.push(etiquetaLinha10);
          this.arrEtiquetaLinha.push(etiquetaLinha11);

          etiquetaLinha7.texto = this.etiqueta.produto_nome;
          this.progressbarCarregandoEtiqueta = false;


          this.etiqueta.produto_plu.map(d=>{
            etiquetaLinha6.texto = "CÓDIGO DO FORNECEDOR: " + d.venda_produto_plu_numero_fornecedor;
            etiquetaLinha7.texto = d.venda_produto_plu_nome_produto_plu;
            etiquetaLinha9.texto = "DATA DE ENTREGA : " + d.venda_produto_plu_data_entrega_plu;
            this.bottomBarCodeValue = d.venda_produto_plu_codigo_barras_plu;
          });



        }, error=>{
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
          this.progressbarCarregandoEtiqueta = false;
        });
      }, error=>{
        this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
        this.progressbarCarregandoEtiqueta = false;
      });
    }, error =>{
      this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
      this.progressbarCarregandoEtiqueta = false;
    });
  }

  setLinhaSelecionada(linha){
    this.linhaSelecionada = linha;
  }
  gerarEtiquetas(event?){
    if(this.quantidadeEtiquetas > 1 && this.quantidadeEtiquetas <= 1000){
      this.etiquetas = [];
      for(let i=0; i<this.quantidadeEtiquetas;i++){
        this.etiquetas.push(i);
      }
    }
  }
  gerarPDF() {
    let documento = new jspdf();
    documento.text("Relatório em PDF no Angular", 10, 10);
    documento.output("dataurlnewwindow");
  }
  gerarPDF2(){
    const elementToPrint = document.getElementById('print-section'); //The html element to become a pdf

    const pdf = new jspdf('p', 'pt', 'a4');

    pdf.addHTML(elementToPrint, () => {
        //doc.output('web.pdf');
    });
  }
  public captureScreen()  {
    var data = document.getElementById('print-section');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('MYPdf.pdf'); // Generated PDF
    });
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .font-padrao{
            font-family: Arial, Helvetica, sans-serif;
          }
          .etiqueta-main-container{
            font-family: Arial, Helvetica, sans-serif;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 6px;
            margin: 20px;
          }
          .etiqueta-container-main{
            width: 100%;
            position: absolute;
          }
          .etiqueta-container{
            max-width: 260px;
            height: 540px;
            background: #FFFFFF;
            position: relative;
          }
          .etiqueta-total-label{
            margin-top: 20px;
            margin-left: 30px;
            max-width: 520px;
            margin-bottom: 20px;
          }
          .rotate{
            transform: rotate(-90deg);
            transform-origin: right, top;
            -ms-transform: rotate(-90deg);
            -ms-transform-origin:right, top;
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin:right, top;
          }
          .etiqueta-titulo-pequena{
            font-size: 10px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .etiqueta-text-align-center{
            text-align: center;
          }
          .etiqueta-box-qrcode{
            height: 120px;
            width: 110px;
            margin-left: 20px;
            margin-top: 130px;
          }
          .etiqueta-text-align-center{
            text-align: center;
          }
          .etiqueta-titulo{
            font-size: 15px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            overflow: hidden;
            height: 20px;
          }
          .etiqueta-descricao{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 10px;
            height: 12px;
          }
          .etiqueta-titulo-grande{
            font-family: Arial, Helvetica, sans-serif;
            font-size: 22px;
            font-weight: 600;
            height: 30px;
            overflow: hidden;
          }
          .etiqueta-grid{
            display: grid;
            grid-template-columns: auto auto;
            width: 382px;
          }
          .etiqueta-box-container{
            position: absolute;
            bottom: 56px;
            right: -44px;
            width: 280px;
          }
          .display-flex{
            display: flex;
          }
          #print-section{
            width: 401px;
          }
          .font-bold{
            font-weight: 600;
          }

          .text-align-left{
            text-align: left;
          }
          .text-align-center{
            text-align: center;
          }
          .text-align-right{
            text-align: right;
          }
          .barcodeBottom {
            position: absolute;
            margin-top: -2px;
            margin-left: -71px;
        }
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  setFontSize(data){
    console.log("DATA : " + data);
    this.linhaSelecionada = data;
  }
  setNegrito(i, negrito){
    this.arrEtiquetaLinha[i].negrito = !negrito;
  }
  addLinha(){
    let etiquetaLinha:LinhaEtiqueta = {
      texto:"",
      fontSize:12,
      negrito:false,
      alinhamento:LinhaAlinhamento.esquerda
    };
    this.arrEtiquetaLinha.push(etiquetaLinha);
  }
  getLoggedDataUser(){
    this.httpRastreadorService.getLoggedDataUser().subscribe((data:any)=>{
      data.map((data:UserPerfil)=>{
        this.perfilEmpresa.empresaEstado = Number(data.user_estado);
        this.perfilEmpresa.empresaLatitude = data.user_endereco_latitude;
        this.perfilEmpresa.empresaLongitude = data.user_endereco_longitude;
        this.perfilEmpresa.empresaMunicipio = data.user_cidade;
        this.perfilEmpresa.empresaEndereco = data.user_endereco;
        this.perfilEmpresa.empresaNome = data.user_nome;
        this.perfilEmpresa.empresaNumero = data.user_numero;
        this.perfilEmpresa.empresaTelefoneCelular = data.user_telefone_celular;
        this.perfilEmpresa.empresaTelefoneFixo = data.user_telefone;
      });
    }, error=>{
    });
  }
  public formatCnpjCpf(value)
  {
  const cnpjCpf = value.replace(/\D/g, '');
    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } 
    
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }
}
