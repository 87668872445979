import { HttpRastreadorService } from '../services/http-rastreador.service';
import { ConfigStoreService } from '../services/config-store.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { AutenticacaoService } from '../services/autenticacao.service';
export interface dataLogin{
  access_token:string;
  expires_in:number;
  token_type:string;
}

@Component({
  selector: 'app-rastreio-login',
  templateUrl: './rastreio-login.component.html',
  styleUrls: ['./rastreio-login.component.css']
})
export class RastreioLoginComponent implements OnInit {
  
  userEmail:string;
  userSenha:string;
  progressbarLogin:boolean = false;
  erroLogin:boolean = false;

  constructor(
    public _snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private httpRastreadorService:HttpRastreadorService,
    public configStoreService:ConfigStoreService,
    public autenticacaoService:AutenticacaoService
  ) {
    if(this.autenticacaoService.autenticado == true){
      this.router.navigate(['/admin']);
    }
   }
  ngOnInit() {

  }
  fazerLogin(){
    this.progressbarLogin = true;
    this.httpRastreadorService.login(this.userSenha, this.userEmail).subscribe((data:any)=>{

      this.autenticacaoService.tokenAutenticacao = data;
      this.autenticacaoService.salvarTokenStorage(this.autenticacaoService.tokenAutenticacao);

      this.openSnackBar("Login autorizado, redirecionando você ao painel administrativo!", "fechar");
      let dataLogin = {
        e:this.userEmail,
        p:this.userSenha
      }
      localStorage.setItem('access_data', btoa(JSON.stringify(dataLogin)));
      this.router.navigate(['/admin']);

    }, error=>{
      this.progressbarLogin = false;
      this.erroLogin = true;
      this.openSnackBar("Login não autorizado, verifique seu e-mail e senha de acesso!", "fechar");
    });
  }
  changeRoute(){
    this.router.navigate(['/admin']);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
