import { UserPerfil } from '../../../rastreador/model/util.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminGlobalService } from '../services/admin-global.service';
import { PerfilEmpresa, Municipio, Estado, Marker } from 'src/app/rastreador/model/util.model';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';

@Component({
  selector: 'app-rastreio-editar-perfil-usuario',
  templateUrl: './rastreio-editar-perfil-usuario.component.html',
  styleUrls: ['./rastreio-editar-perfil-usuario.component.css']
})
export class RastreioEditarPerfilUsuarioComponent implements OnInit {

  registerForm: FormGroup;
  perfilEmpresa:PerfilEmpresa = {
    empresaRazaoSocial:"",
    empresaNome:"",
    empresaCNPJ:"",
    empresaInscricaoEstadual:"",
    empresaEstado:1,
    empresaMunicipio:"",
    empresaBairro:"",
    empresaEndereco:"",
    empresaNumero:"",
    empresaLongitude:"",
    empresaLatitude:"",
    empresaTelefoneFixo:"",
    empresaTelefoneCelular:"",
    empresaEmail:"",
    empresaEmail2:"",
    empresaSenha:"",
    empresaSenha2:""
  }
  arrMunicipios:Municipio[] = [];
  arrEstados:Estado[] = [
    {"estado_id":1,"estado_nome":"Acre","estado_uf":"AC"},
    {"estado_id":2,"estado_nome":"Alagoas","estado_uf":"AL"},
    {"estado_id":3,"estado_nome":"Amazonas","estado_uf":"AM"},
    {"estado_id":4,"estado_nome":"Amap\u00e1","estado_uf":"AP"},
    {"estado_id":5,"estado_nome":"Bahia","estado_uf":"BA"},
    {"estado_id":6,"estado_nome":"Cear\u00e1","estado_uf":"CE"},
    {"estado_id":7,"estado_nome":"Distrito Federal","estado_uf":"DF"},
    {"estado_id":8,"estado_nome":"Esp\u00edrito Santo","estado_uf":"ES"},
    {"estado_id":9,"estado_nome":"Goi\u00e1s","estado_uf":"GO"},
    {"estado_id":10,"estado_nome":"Maranh\u00e3o","estado_uf":"MA"},
    {"estado_id":11,"estado_nome":"Minas Gerais","estado_uf":"MG"},
    {"estado_id":12,"estado_nome":"Mato Grosso do Sul","estado_uf":"MS"},
    {"estado_id":13,"estado_nome":"Mato Grosso","estado_uf":"MT"},
    {"estado_id":14,"estado_nome":"Par\u00e1","estado_uf":"PA"},
    {"estado_id":15,"estado_nome":"Para\u00edba","estado_uf":"PB"},
    {"estado_id":16,"estado_nome":"Pernambuco","estado_uf":"PE"},
    {"estado_id":17,"estado_nome":"Piau\u00ed","estado_uf":"PI"},
    {"estado_id":18,"estado_nome":"Paran\u00e1","estado_uf":"PR"},
    {"estado_id":19,"estado_nome":"Rio de Janeiro","estado_uf":"RJ"},
    {"estado_id":20,"estado_nome":"Rio Grande do Norte","estado_uf":"RN"},
    {"estado_id":21,"estado_nome":"Rond\u00f4nia","estado_uf":"RO"},
    {"estado_id":22,"estado_nome":"Roraima","estado_uf":"RR"},
    {"estado_id":23,"estado_nome":"Rio Grande do Sul","estado_uf":"RS"},
    {"estado_id":24,"estado_nome":"Santa Catarina","estado_uf":"SC"},
    {"estado_id":25,"estado_nome":"Sergipe","estado_uf":"SE"},
    {"estado_id":26,"estado_nome":"S\u00e3o Paulo","estado_uf":"SP"},
    {"estado_id":27,"estado_nome":"Tocantins","estado_uf":"TO"}
  ];
  buscandoMunicipios:boolean = false;
  atualizandoDados:boolean = false;
  progressbarLoadData:boolean = true;
  progressbarUpdateData:boolean = false;
  arrMarker:Marker[] = [];
  zoom: number = 16;
  disabled:boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public adminGlobalService:AdminGlobalService,
    public httpRastreadorService:HttpRastreadorService
  ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Editar dados');
    //#####################################################
    this.getLoggedDataUser();
  }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      empresaBairro: ['', Validators.required],
      empresaRazaoSosial: ['', Validators.required],
      empresaNome: ['', Validators.required],
      empresaCNPJ: ['', Validators.required],
      empresaInscricaoEstadual: ['', Validators.required],
      empresaEstado: ['', Validators.required],
      empresaMunicipio: ['',Validators.required],
      empresaEndereco: ['', Validators.required],
      empresaNumero: ['', Validators.required],
      empresaTelefoneFixo: ['', Validators.required],
      empresaTelefoneCelular: ['', Validators.required],
      empresaLongitude:['', Validators.required],
      empresaLatitude:['', Validators.required]
    });

    this.registerForm.controls['empresaRazaoSosial'].disable();
    this.registerForm.controls['empresaNome'].disable();
    this.registerForm.controls['empresaCNPJ'].disable();
    this.registerForm.controls['empresaInscricaoEstadual'].disable();

  }
  obterLocalizacaoUsuario(){
    navigator.geolocation.getCurrentPosition((location) =>{
      this.arrMarker = [];
      let p:Marker = {
        lat: Number(location.coords.latitude),
        lng: Number(location.coords.longitude),
        label: "",
        text:"Localização",
        draggable: false,
      }
      this.arrMarker.push(p);
      this.perfilEmpresa.empresaLatitude = String(location.coords.latitude);
      this.perfilEmpresa.empresaLongitude = String(location.coords.longitude);
      //console.log(location.coords.accuracy);
    });
  }
  getListaMunicipios(estado:number, municipio?:number):void{
    this.buscandoMunicipios = true;
    this.httpRastreadorService.getListaMunicipios(estado).subscribe((data:any)=>{
      this.arrMunicipios = data;
      this.buscandoMunicipios = false;
      this.perfilEmpresa.empresaMunicipio = Number(municipio);
    }, error =>{
      this.buscandoMunicipios = false;
    });
  }
  placeMarker($event){
    this.arrMarker = [];
    let p:Marker = {
      lat: Number($event.coords.lat),
      lng: Number($event.coords.lng),
      label: "",
      text:"Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
    this.perfilEmpresa.empresaLatitude = $event.coords.lat;
    this.perfilEmpresa.empresaLongitude = $event.coords.lng;
  }
  mudarCoordenadas(){
    this.arrMarker = [];
    let p:Marker = {
      lat: Number(this.perfilEmpresa.empresaLatitude),
      lng: Number(this.perfilEmpresa.empresaLongitude),
      label: "",
      text:"Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
  }
  getLoggedDataUser(){
    this.progressbarLoadData = true;
    this.httpRastreadorService.getLoggedDataUser().subscribe((data:any)=>{
      data.map((data:UserPerfil)=>{
        this.perfilEmpresa.empresaCNPJ = data.user_cnpj;
        this.perfilEmpresa.empresaRazaoSocial = data.user_razao_social;
        this.perfilEmpresa.empresaInscricaoEstadual = data.user_inscricao_estadual;
        this.perfilEmpresa.empresaEstado = Number(data.user_estado);
        this.perfilEmpresa.empresaLatitude = data.user_endereco_latitude;
        this.perfilEmpresa.empresaLongitude = data.user_endereco_longitude;
        this.perfilEmpresa.empresaMunicipio = data.user_cidade;
        this.perfilEmpresa.empresaBairro = data.user_bairro;
        this.perfilEmpresa.empresaEndereco = data.user_endereco;
        this.perfilEmpresa.empresaNome = data.user_nome;
        this.perfilEmpresa.empresaNumero = data.user_numero;
        this.perfilEmpresa.empresaTelefoneCelular = data.user_telefone_celular;
        this.perfilEmpresa.empresaTelefoneFixo = data.user_telefone;
        this.getListaMunicipios(Number(data.user_estado), this.perfilEmpresa.empresaMunicipio);
        this.mudarCoordenadas();
      });
      this.progressbarLoadData = false;
    }, error=>{
      this.progressbarLoadData = false;
    });
  }
  atualizarDados(){
    if(this.adminGlobalService.validarCNPJ(this.perfilEmpresa.empresaCNPJ)){
    this.progressbarUpdateData = true;
    this.httpRastreadorService.atualizarDadosCliente(this.perfilEmpresa).subscribe((data:any)=>{
      this.progressbarUpdateData = false;
      if(data.status == true){
        this.adminGlobalService.openSnackBar("Dados atualizados com sucesso!", "fechar");
      }else{
        this.adminGlobalService.openSnackBar("Não foi possível atualizar seus dados!", "fechar");
      }
    }, error=>{
      this.progressbarUpdateData = false;
      this.adminGlobalService.openSnackBar("Não foi possível atualizar seus dados, verifique sua conexão com a internet!", "fechar");
    });
    }else{
      this.adminGlobalService.openSnackBar("O CNPJ informado é inválido!", "fechar");
    }
  }
  obterCoordenadasPorEndereco(){

    let estado = "SP";
    let cidade = "São Paulo";

    let endereco = this.perfilEmpresa.empresaEndereco + " " + this.perfilEmpresa.empresaNumero + " " + estado + " " + cidade;
    this.httpRastreadorService.obterCoordenadasPorEndereco("São Paulo").subscribe((data)=>{
      console.log("data : " + JSON.stringify(data));
    });

  }
}
