import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-cadastrar-produto-dialog',
  templateUrl: './cadastrar-produto-dialog.component.html',
  styleUrls: ['./cadastrar-produto-dialog.component.css']
})
export class CadastrarProdutoDialogComponent implements OnInit {

  htmlContent:any;

  constructor(
    public dialogRef: MatDialogRef<CadastrarProdutoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
    ) { }

  ngOnInit() {
  }





}
