
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from "@angular/router";

export enum undMedida{
  KILO    = 1,
  CAIXA   = 2,
  BANDEJA = 3,
  SACO    = 4
}
export interface NFEProduto{
  id:number;
  nome:string;
  quantidade:number;
  unid:undMedida;
  valorUnitario:number;
}
export interface NFE{
  nf:string;
  data:string;
  produtos:NFEProduto[];
}

@Component({
  selector: 'app-criar-rastreio',
  templateUrl: './criar-rastreio.component.html',
  styleUrls: ['./criar-rastreio.component.css']
})
export class CriarRastreioComponent implements OnInit {

  arrNFE:NFE[] = [];
  termoBusca:string = '';

  orderAsc = 'ASC';
  orderDesc = 'DESC';
  order:any = 'ASC';
  paginaAtual:number = 1;
  ultimaPagina:number = 1;
  itensPorPagina:number = 1;

  buscaRealizada:boolean = true;
  progressbarAtivo:boolean = false;

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  totalNFE:number = 0;

  constructor(
    private router: Router
  ) {

    let prodNFE1:NFEProduto = {
      id:17,
      nome:'Cenoura',
      quantidade:10,
      unid:undMedida.CAIXA,
      valorUnitario: 100.00
    }
    let prodNFE2:NFEProduto = {
      id:1,
      nome:'Abacaxi',
      quantidade:20,
      unid:undMedida.KILO,
      valorUnitario: 5.00
    }
    let prodNFE3:NFEProduto = {
      id:2,
      nome:'Alface',
      quantidade:80,
      unid:undMedida.BANDEJA,
      valorUnitario: 2.00
    }
    let NF1:NFE = {
      nf:'123456789123456789123456789',
      data:'19/08/2019',
      produtos:[]
    }

    NF1.produtos.push(prodNFE1);
    NF1.produtos.push(prodNFE2);
    NF1.produtos.push(prodNFE3);

    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);
    this.arrNFE.push(NF1);



    console.log("NF1 : " + JSON.stringify(this.arrNFE));

  }

  ngOnInit() {



  }


  buscarNotaFiscal(pagina:number){

    console.log("BUSCA NOTA FISCAL : " + this.termoBusca);
  }


  orderList(){
    if(this.order == this.orderAsc){
      this.order= this.orderDesc;
    }else{
      this.order= this.orderAsc;
    }

    this.buscarNotaFiscal(this.paginaAtual);
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.buscarNotaFiscal(1);
  }
  changeRoute(paginaAtual:any) {
    this.router.navigate(['admin/produtos/'+paginaAtual]);
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }



  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag);
      this.buscarNotaFiscal(pag);
    }
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.buscarNotaFiscal(pag);
    }
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.buscarNotaFiscal(paginaAtual);
    }
  }

}
