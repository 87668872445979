import { Component, OnInit } from '@angular/core';
import { Produto, UnidMedida } from '../teste/lista.model';
import { CarrinhoService } from '../carrinho.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AdicionarProdutoComponent } from '../adicionar-produto/adicionar-produto.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.css']
})
export class TesteComponent implements OnInit {


  // all_inclusive, icone novo

  //Libs e Pesquisas
  //https://medium.com/@kheronn.machado/youtube-angular-en-2ed98f07e0f9
  //Libs
  //https://github.com/tjoskar/ng-lazyload-image
  //https://github.com/alexcastillo/ng2-notifications
  //https://rxjs-dev.firebaseapp.com/guide/overview
  //https://github.com/akveo/ngx-admin
  //https://ant.design/components/list/
  //https://stackblitz.com/edit/ngx-barcode-demo
  //https://www.npmjs.com/package/angular2-qrcode

  //Camera
  //https://www.npmjs.com/package/ngx-webcam

  //Grid drag and Drop
  //https://dsmorse.github.io/gridster.js/#demos
  //https://medium.com/javascript-in-plain-english/drag-and-drop-dashboard-builder-with-angular-and-gridster-a07592e54ce2

  //Componentes
  //https://alyle.io/getting-started/installation
  //http://ng-lightning.github.io/ng-lightning/#/components/colorpicker

  //http://ng.mobile.ant.design/#/docs/introduce/en

  constructor(
    private router: Router,
    public carrinhoService:CarrinhoService,
    public dialog: MatDialog
    ) {
      let access_token = localStorage.getItem('access_token');
      if(access_token == null){
        //this.router.navigate(['/Login']);
      }
     }

  ngOnInit() {

  }
  adicionarProduto(): void {

    //Modal em Tela cheia
    /*
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    */

    const dialogRef = this.dialog.open(AdicionarProdutoComponent, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  incrementarProduto(index){
    this.carrinhoService.listaCompra.arrProdutos[index].quantidade = (this.carrinhoService.listaCompra.arrProdutos[index].quantidade + 1);
  }
  decrementarProduto(index){
    if(this.carrinhoService.listaCompra.arrProdutos[index].quantidade > 0){
      this.carrinhoService.listaCompra.arrProdutos[index].quantidade = (this.carrinhoService.listaCompra.arrProdutos[index].quantidade - 1);
    }
  }



}
