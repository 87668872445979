import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UnidMedida, Produtos } from 'src/app/rastreador/model/produto.model';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { CompraVendaAddProdutoConfirmDialogComponent } from '../compra-venda-add-produto-confirm-dialog/compra-venda-add-produto-confirm-dialog.component';

@Component({
  selector: 'app-compra-venda-add-produto-dialog',
  templateUrl: './compra-venda-add-produto-dialog.component.html',
  styleUrls: ['./compra-venda-add-produto-dialog.component.css']
})
export class CompraVendaAddProdutoDialogComponent implements OnInit {

  arrUnidMedida:UnidMedida[] = [];
  arrProduto:Produtos[] = [];
  produto:Produtos;
  palavraChaveBusca:string = "";

  order:any = 'ASC';
  orderBy:any = 'nome';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  termoBusca:string = '';

  progressBarBusca:boolean = false;

  constructor(
    public dialog: MatDialog,
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<CompraVendaAddProdutoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.buscarProdutos();
  }
  buscarProdutos(){
    this.progressBarBusca = true;
    this.httpRastreadorService.getListaProdutosTeste(this.termoBusca, this.orderBy, this.order).subscribe((data:any)=>{
      this.progressBarBusca = false;
      this.arrProduto = data;
    }, error=>{
      this.progressBarBusca = false;
    });
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }
  confirmarDados(produtoData:Produtos){
    const dialogRef = this.dialog.open(CompraVendaAddProdutoConfirmDialogComponent, {
      width: '560px',
      data: {produto:produtoData, tipo:this.data.tipo}
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.termoBusca = "";
    });
  }

}
