import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpAdminService } from '../../services/http-admin.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-criar-atributo-grupo-dialog',
  templateUrl: './criar-atributo-grupo-dialog.component.html',
  styleUrls: ['./criar-atributo-grupo-dialog.component.css']
})
export class CriarAtributoGrupoDialogComponent implements OnInit {

  form:FormGroup;
  showProgressbar:boolean = false;
  nomeGrupoAtributo:any = '';

  constructor(
    public _snackBar: MatSnackBar,
    public httpAdminService:HttpAdminService,
    public dialogRef: MatDialogRef<CriarAtributoGrupoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private formBuilder: FormBuilder
  ) {
  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      nomeGrupoAtributo: ['', Validators.required]
    });
  }
  close():void{
    this.dialogRef.close();
  }
  openSnackBar(message: string, action: string):void {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  saveData():void{
    this.showProgressbar = true;
    if(this.form.value.nomeGrupoAtributo.length > 0){
      this.httpAdminService.createNewGrupoAtributo(this.form.value.nomeGrupoAtributo, this.data.id_produto).subscribe((data)=>{
        this.showProgressbar = false;
        this.openSnackBar("Grupo de atributo " + this.form.value.nomeGrupoAtributo + " criado!", "fechar");
        this.dialogRef.close(true);
      });
    }else{
      this.openSnackBar("Você precisa informar o nome do novo grupo de atributos antes de enviar!", "fechar");
    }
  }




}
