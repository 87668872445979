import { Injectable } from '@angular/core';

export interface TokenAutenticacao{
  access_token:string;
  token_type:string;
  expires_in:number;

}

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {
  
  tokenAutenticacao:TokenAutenticacao = {
    access_token:null,
    token_type:null,
    expires_in:null,
  }

  autenticado:boolean = false;

  constructor() {
    this.getTokenStorage();
  }
  public userAutenticado():boolean{
    if(this.tokenAutenticacao === null){
      return false;
    }else{
      return true;
    }
  }
  public getTimestamp():number{
    return Math.floor(Date.now() / 1000);
  }
  public salvarTokenStorage(tokenAutenticacao:TokenAutenticacao){
    this.tokenAutenticacao.access_token = tokenAutenticacao.access_token;
    this.tokenAutenticacao.token_type = tokenAutenticacao.token_type;
    let tempoDuracao = Math.floor(Date.now() / 1000) + Number(tokenAutenticacao.expires_in);
    this.tokenAutenticacao.expires_in = tempoDuracao;
    this.autenticado = true;
    localStorage.setItem("rast_access_token", JSON.stringify(tokenAutenticacao));
  }
  public getTokenStorage(){
    if(localStorage.getItem("rast_access_token")){
      this.tokenAutenticacao = JSON.parse(localStorage.getItem("rast_access_token"));
        if(this.tokenAutenticacao.expires_in < Math.floor(Date.now() / 1000)){
          console.log("Você não esta logado!");
          this.autenticado = false;
          localStorage.clear();
        }else{
          this.autenticado = true;
          console.log("Você esta logado!");
        }
    }else{
      this.autenticado = false;
      console.log("Você não esta logado!");
    }
  }
  public getToken(){
    this.getTokenStorage();
    return this.tokenAutenticacao.access_token;
  }
}
