/// <reference types="@types/googlemaps" />
import { Fornecedor } from './../../../model/fornecedor.model';
import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Estado, Municipio, Marker } from 'src/app/rastreador/model/util.model';
import { Observable } from 'rxjs';
import { google } from '@google/maps';
import { MapsAPILoader } from '@agm/core';
import { ConsultaCepService } from '../../../model/ConsultaCepService';
import { isEmpty } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
declare var google: any;

@Component({
  selector: 'app-cadastrar-fornecedor',
  templateUrl: './cadastrar-fornecedor.component.html',
  styleUrls: ['./cadastrar-fornecedor.component.css']
})
export class CadastrarFornecedorComponent implements OnInit {

  cadastrarCPF: boolean = false;
  bounds = null;
  private latlng;
  private mapa: any;
  private toSelect;
  private latitude;
  private longitude;

  arrEstados: Estado[] = [
    { "estado_id": 1, "estado_nome": "Acre", "estado_uf": "AC" },
    { "estado_id": 2, "estado_nome": "Alagoas", "estado_uf": "AL" },
    { "estado_id": 3, "estado_nome": "Amazonas", "estado_uf": "AM" },
    { "estado_id": 4, "estado_nome": "Amap\u00e1", "estado_uf": "AP" },
    { "estado_id": 5, "estado_nome": "Bahia", "estado_uf": "BA" },
    { "estado_id": 6, "estado_nome": "Cear\u00e1", "estado_uf": "CE" },
    { "estado_id": 7, "estado_nome": "Distrito Federal", "estado_uf": "DF" },
    { "estado_id": 8, "estado_nome": "Esp\u00edrito Santo", "estado_uf": "ES" },
    { "estado_id": 9, "estado_nome": "Goi\u00e1s", "estado_uf": "GO" },
    { "estado_id": 10, "estado_nome": "Maranh\u00e3o", "estado_uf": "MA" },
    { "estado_id": 11, "estado_nome": "Minas Gerais", "estado_uf": "MG" },
    { "estado_id": 12, "estado_nome": "Mato Grosso do Sul", "estado_uf": "MS" },
    { "estado_id": 13, "estado_nome": "Mato Grosso", "estado_uf": "MT" },
    { "estado_id": 14, "estado_nome": "Par\u00e1", "estado_uf": "PA" },
    { "estado_id": 15, "estado_nome": "Para\u00edba", "estado_uf": "PB" },
    { "estado_id": 16, "estado_nome": "Pernambuco", "estado_uf": "PE" },
    { "estado_id": 17, "estado_nome": "Piau\u00ed", "estado_uf": "PI" },
    { "estado_id": 18, "estado_nome": "Paran\u00e1", "estado_uf": "PR" },
    { "estado_id": 19, "estado_nome": "Rio de Janeiro", "estado_uf": "RJ" },
    { "estado_id": 20, "estado_nome": "Rio Grande do Norte", "estado_uf": "RN" },
    { "estado_id": 21, "estado_nome": "Rond\u00f4nia", "estado_uf": "RO" },
    { "estado_id": 22, "estado_nome": "Roraima", "estado_uf": "RR" },
    { "estado_id": 23, "estado_nome": "Rio Grande do Sul", "estado_uf": "RS" },
    { "estado_id": 24, "estado_nome": "Santa Catarina", "estado_uf": "SC" },
    { "estado_id": 25, "estado_nome": "Sergipe", "estado_uf": "SE" },
    { "estado_id": 26, "estado_nome": "S\u00e3o Paulo", "estado_uf": "SP" },
    { "estado_id": 27, "estado_nome": "Tocantins", "estado_uf": "TO" }
  ];
  arrMunicipios: Municipio[] = [];
  registerForm: FormGroup;
  public fornecedor: Fornecedor = {
    fornecedor_id: 0,
    fornecedor_razao_social: "",
    fornecedor_nome: "",
    fornecedor_cnpj: "",
    fornecedor_inscricao_estadual: "",
    fornecedor_endereco_longitude: "",
    fornecedor_endereco_latitude: "",
    fornecedor_pais: "",
    fornecedor_estado: "",
    fornecedor_cidade: "",
    fornecedor_bairro: "",
    fornecedor_endereco: "",
    fornecedor_numero: "",
    fornecedor_complemento: "",
    fornecedor_cep: "",
    fornecedor_observacao: "",
    fornecedor_tipoPessoa: ""
  };
  progressBarCadastro: boolean = false;
  progressBarBuscarFornecedor: boolean = false;
  modoEdicao: boolean = false;
  buscandoMunicipios: boolean = false;
  arrMarker: Marker[] = [];
  zoom: number = 16;


  constructor(
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public httpRastreadorService: HttpRastreadorService,
    public adminGlobalService: AdminGlobalService,
    private mapsAPILoader: MapsAPILoader,
    private cepService: ConsultaCepService

  ) {

    this.mapsAPILoader.load().then(() => {
      this.initialize();
    });

    let idFornecedor = this.route.snapshot.paramMap.get("idfornecedor");


    if (idFornecedor != null) {
      adminGlobalService.setPageTitle("Editar Fornecedor");
      this.modoEdicao = true;
      this.buscarDadosAtualizar(idFornecedor);
    } else {
      adminGlobalService.setPageTitle("Cadastrar Fornecedor");
      this.fornecedor.fornecedor_tipoPessoa = "CNPJ"
      this.modoEdicao = false;
      this.obterLocalizacaoUsuario();
    }

  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fornecedor_nome: ['', Validators.required],
      fornecedor_razao_social: ['', Validators.required],
      fornecedor_cnpj: ['', Validators.required],
      fornecedor_inscricao_estadual: ['', Validators.required],
      fornecedor_endereco_longitude: ['', Validators.required],
      fornecedor_endereco_latitude: ['', Validators.required],
      fornecedor_pais: ['', Validators.required],
      fornecedor_estado: ['', Validators.required],
      fornecedor_cidade: ['', Validators.required],
      fornecedor_bairro: ['', Validators.required],
      fornecedor_endereco: ['', Validators.required],
      fornecedor_numero: ['', Validators.required],
      fornecedor_cep: [''],
      fornecedor_complemento: [''],
      fornecedor_observacao: [''],
      fornecedor_tipoPessoa: ['']
    });
  }

  buscarDadosAtualizar(idFornecedor) {
    this.progressBarBuscarFornecedor = true;
    this.httpRastreadorService.buscarFornecedorAtualizar(idFornecedor).subscribe((data: any) => {
      this.fornecedor = data[0];
      this.somePlaceholder = this.fornecedor.fornecedor_tipoPessoa;
      this.progressBarBuscarFornecedor = false;
      this.getListaMunicipios(Number(this.fornecedor.fornecedor_estado), this.fornecedor.fornecedor_cidade);
      this.mudarCoordenadas();
    }, error => {
      this.progressBarBuscarFornecedor = false;
      this.adminGlobalService.openSnackBar("Não foi possível buscar o fornecedor selecionado para edição, por favor tente novamente!", "fechar");
    });

  }
  atualizarFornecedor() {
    if (!this.registerForm.invalid) {
      if (this.adminGlobalService.validarCNPJ(this.fornecedor.fornecedor_cnpj) || this.adminGlobalService.validarCPF(this.fornecedor.fornecedor_cnpj) == true) {
        this.progressBarCadastro = true;
        this.httpRastreadorService.atualizarFornecedor(this.fornecedor).subscribe((data: any) => {
          this.progressBarCadastro = false;
          if (data.status) {
            this.adminGlobalService.openSnackBar("Fornecedor atualizado com sucesso!", "fechar");
          } else {
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar atualizar o fornecedor, tente novamente!", "fechar");
          }
        }, error => {
          this.progressBarCadastro = false;
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar atualizar o fornecedor, verifique sua conexão de internet e tente novamente!", "fechar");
        });
      } else {
        debugger;
        this.adminGlobalService.openSnackBar("O" + this.fornecedor.fornecedor_tipoPessoa + " informado é inválido!", "fechar");
      }
    } else {
      this.adminGlobalService.openSnackBar("Você precisa preencher todos os campos para salvar!", "fechar");

    }
  }
  cadastrarFornecedor() {
    debugger
    if (!this.registerForm.invalid) {
      if (this.adminGlobalService.validarCNPJ(this.fornecedor.fornecedor_cnpj) || this.adminGlobalService.validarCPF(this.fornecedor.fornecedor_cnpj) == true) {
        debugger;
        this.progressBarCadastro = true;
        this.httpRastreadorService.cadastrarFornecedor(this.fornecedor).subscribe((data: any) => {
          this.progressBarCadastro = false;
          if (data.status) {
            this.adminGlobalService.openSnackBar("Fornecedor cadastrado com sucesso!", "fechar");
            this.fornecedor = {
              fornecedor_id: 0,
              fornecedor_razao_social: "",
              fornecedor_nome: "",
              fornecedor_cnpj: "",
              fornecedor_inscricao_estadual: "",
              fornecedor_endereco_longitude: "",
              fornecedor_endereco_latitude: "",
              fornecedor_pais: "",
              fornecedor_estado: "",
              fornecedor_cidade: "",
              fornecedor_bairro: "",
              fornecedor_endereco: "",
              fornecedor_numero: "",
              fornecedor_cep: "",
              fornecedor_complemento: "",
              fornecedor_observacao: "",
              fornecedor_tipoPessoa: ""
            };
          } else {
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar cadastrar o fornecedor, tente novamente!", "fechar");
          }
        }, error => {
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar cadastrar o fornecedor, verifique sua conexão de internet e tente novamente!", "fechar");
        });
      } else {
        this.adminGlobalService.openSnackBar("O"+this.fornecedor.fornecedor_tipoPessoa + " informado é inválido!", "fechar");
      }
    } else {
      this.adminGlobalService.openSnackBar("Você precisa preencher todos os campos para salvar!", "fechar");

    }
  }
  getListaMunicipios(estado: number, municipio?: any): void {
    this.buscandoMunicipios = true;
    this.httpRastreadorService.getListaMunicipios(estado).subscribe((data: any) => {
      this.arrMunicipios = data;
      this.buscandoMunicipios = false;
      this.fornecedor.fornecedor_cidade = municipio;
    }, error => {
      this.buscandoMunicipios = false;
    });
  }
  placeMarker($event) {
    debugger
    this.arrMarker = [];

    let p: Marker = {
      lat:   Number($event.coords.lat)  <= 0 ? Number($event.coords.lat)  :-34.397 ,
      lng:   Number($event.coords.lng)   <= 0 ? Number($event.coords.lng) : 150.644,
      label: "",
      text: "Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
    debugger
    this.mapa = {
      center: {
        lat: Number($event.coords.lat),
        lng: Number($event.coords.lng)
      }
    }

    this.fornecedor.fornecedor_endereco_latitude = $event.coords.lat;
    this.fornecedor.fornecedor_endereco_longitude = $event.coords.lng;
  }
  mudarCoordenadas() {
    this.arrMarker = [];
    let p: Marker = {
      lat: Number(this.fornecedor.fornecedor_endereco_latitude),
      lng: Number(this.fornecedor.fornecedor_endereco_longitude),
      label: "",
      text: "Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
  }

  obterLocalizacaoUsuario() {
debugger
    navigator.geolocation.getCurrentPosition((location) => {
      this.latitude = location.coords.latitude == undefined ? -23.5336886 :location.coords.latitude  ;
      this.longitude = location.coords.longitude == undefined ? -46.7378578 :location.coords.latitude  ;

      this.arrMarker = [];

      let p: Marker = {
        lat: Number(location.coords.latitude),
        lng: Number(location.coords.longitude),
        label: "",
        text: "Localização",
        draggable: false,
      }
      console.log("funcionaaaa "+p);
      this.arrMarker.push(p);
      this.fornecedor.fornecedor_endereco_latitude = String(location.coords.latitude);
      this.fornecedor.fornecedor_endereco_longitude = String(location.coords.longitude);
      // console.log(location.coords.accuracy);
    });
  }

  list = [{ "name": "Pessoa Jurídica", id: "CNPJ" }, { "name": "Pessoa Física", id: "CPF" }];

  onChange(value) {
    if (value.value === "CPF") {
      this.fornecedor.fornecedor_tipoPessoa = value.value;
      this.cadastrarCPF = true;
      this.somePlaceholder = 'CPF'
      this.getCpfCnpjMask();
    } else {
      this.fornecedor.fornecedor_tipoPessoa = value.value;
      this.cadastrarCPF = false;
      this.somePlaceholder = 'CNPJ'
      this.getCpfCnpjMask();
    }
  }
  somePlaceholder: string = this.fornecedor.fornecedor_tipoPessoa === 'CPF' ? 'CPF' : 'CNPJ';
  mascara: string = this.fornecedor.fornecedor_tipoPessoa === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00';

  mask: string;

  getCpfCnpjMask() {
    if (this.fornecedor.fornecedor_tipoPessoa == 'CPF')
      this.mask = '000.000.000-00';
    else if (this.fornecedor.fornecedor_tipoPessoa == 'CNPJ') {
      this.mask = '00.000.000/0000-00';
    }
    return this.mask;
  }

  private marker;

  async initialize()  {
    // alert(this.map)
    debugger
    if(document.getElementById("map") == null){
      var latlng = new google.maps.LatLng(-18.8800397, -47.05878999999999);
      var options = {
          zoom: 16,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.mapa = new google.maps.Map(document.getElementById("map"), options);

      debugger
      this.marker = new google.maps.Marker({
        map: this.mapa,
        draggable: true,
      });
      debugger
      const geocoder = new google.maps.Geocoder();
      this.geocodeAddress(geocoder, this.mapa);

      alert("Sua localização não pode ser carregado, por favor permitir sua localização")
    }else
    this.mapa =  await new google.maps.Map(document.getElementById("map"), {
      zoom: 16,
      center: {
        lat:  -34.397 ,
        lng: 50.644,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
    });
      debugger
    this.marker = new google.maps.Marker({
      map: this.mapa,
      draggable: true,
    });
    debugger
    const geocoder = new google.maps.Geocoder();
    this.geocodeAddress(geocoder, this.mapa);
  }
  geocodeAddress(geocoder, resultsMap) {
    const address = this.fornecedor.fornecedor_cep != '' ? this.fornecedor.fornecedor_cep : this.fornecedor.fornecedor_endereco_latitude + " " + this.fornecedor.fornecedor_endereco_longitude;

    geocoder.geocode( { 'address': address},
      (results, status) => {
        if (status === "OK") {
          this.fornecedor.fornecedor_endereco_latitude = results[0].geometry.location.lat();
          this.fornecedor.fornecedor_endereco_longitude = results[0].geometry.location.lng();
          console.log("resulado"+results)
          resultsMap.setCenter(results[0].geometry.location);
          new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location 
          });

        } else {
          console.log( 
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  }

  consultaCEP(cep) {
    // Nova variável "cep" somente com dígitos.
    cep = cep.replace(/\D/g, '');

    if (cep != null && cep !== '') {
      this.cepService.consultaCEP(cep)
        .subscribe(dados => this.populaDadosForm(dados, this.registerForm));
    }
  }

  populaDadosForm(dados, formulario) {

    formulario.get('fornecedor_bairro').setValue(dados.bairro);
    formulario.get('fornecedor_complemento').setValue(dados.complemento);
    formulario.get('fornecedor_endereco').setValue(dados.logradouro);
    // formulario.get('fornecedor_endereco_latitude').setValue("-23.5349525");
    // formulario.get('fornecedor_endereco_longitude').setValue("-46.738287,18");

    // formulario.get('fornecedor_estado').setValue(this.arrEstados[25].estado_nome);
  }


}

