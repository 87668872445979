import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { Cliente } from 'src/app/model/cliente.model';

@Component({
  selector: 'app-buscar-clientes-dialog',
  templateUrl: './buscar-clientes-dialog.component.html',
  styleUrls: ['./buscar-clientes-dialog.component.css']
})
export class BuscarClientesDialogComponent implements OnInit {

  termoBusca:string = "";
  progressbar:boolean = false;
  arrCliente:Cliente[] = [];
  buscaRealizada:boolean = false;

  constructor(
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<BuscarClientesDialogComponent>,
    public adminGlobalService:AdminGlobalService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {

  }

  ngOnInit() {





  }

  closeWindow(status:boolean): void {
    this.dialogRef.close(status);
  }

  getCliente(){

    this.progressbar = true;
    this.httpRastreadorService.getListaCliente(1, 50, this.termoBusca, 'id', 'ASC').subscribe((data:any)=>{
      this.arrCliente = data.data;
      this.progressbar = false;
      this.buscaRealizada = true;
    });

  }
  selecionarCliente(cliente:Cliente){
    this.dialogRef.close(cliente);
  }

}
