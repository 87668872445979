import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { ActivatedRoute } from '@angular/router';
import { Compra, CompraProduto, FornecedorCompra } from 'src/app/model/compra.model';
import { MatDialog } from '@angular/material';
import { RastreioEditarCompraComponent } from '../rastreio-editar-compra/rastreio-editar-compra.component';
import { NovaVendaSimplesComponent } from '../nova-venda-simples/nova-venda-simples.component';
import { CompraVendaAtualizarProdutoDialogComponent } from '../compra-venda-atualizar-produto-dialog/compra-venda-atualizar-produto-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UploadArquivoPluComponent } from '../upload-arquivo-plu/upload-arquivo-plu.component';
import { VerFornecedoresCompraProdutoComponent } from '../ver-fornecedores-compra-produto/ver-fornecedores-compra-produto.component';


@Component({
  selector: 'app-ver-compra',
  templateUrl: './ver-compra.component.html',
  styleUrls: ['./ver-compra.component.css']
})
export class VerCompraComponent implements OnInit {

  idCompra:any;
  public compra:Compra = {
    compra_id:0,
    compra_numero_pedido:null,
    compra_id_cliente:0,
    compra_data:"",
    compra_nota_fiscal:"",
    compra_id_fornecedor:0,
    compra_fornecedor_nome:"",
    compra_fornecedor_cnpj:"",
    compra_produtos:[]
  }
  
  progressbarBuscaProdutos:boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public adminGlobalService:AdminGlobalService,
    public httpRastreadorService:HttpRastreadorService
  ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Ver compra');
    //#####################################################
    this.idCompra = this.route.snapshot.paramMap.get("idcompra");
  }
  ngOnInit() {
    this.getCompra();
  }
  getCompra():void{
    this.progressbarBuscaProdutos = true;
    this.httpRastreadorService.getCompra(this.idCompra).subscribe((data:Compra)=>{
      this.progressbarBuscaProdutos = false;
      this.compra = data;
    });
  }
  getTotalIndividual(index){
    return this.compra.compra_produtos[index].compra_produto_quantidade * this.compra.compra_produtos[index].produto_comprado_valor;
  }
  getTotal(){
    let total = 0;
    this.compra.compra_produtos.map((produto,index)=>{
      total += this.getTotalIndividual(index);
    });
    return total;
  }
  getValorUnitario(index){
    return 1 * this.compra.compra_produtos[index].produto_comprado_valor;
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  openEditCompra(){
    const dialogRef = this.dialog.open(RastreioEditarCompraComponent, {
      width: '600px',
      data: {compra:this.compra}
    });
    dialogRef.afterClosed().subscribe((result:Compra) => {
      if(result != null){
        this.compra = result;
      }
    });
  }
  openDialogVendaSimples(){
    const dialogRef = this.dialog.open(NovaVendaSimplesComponent, {
      maxWidth: '98vw',
      width: '740px',
      data: this.compra,
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.getCompra();
        this.compra.compra_produtos = [];
      }
    });
  }
  atualizaProduto(compraProduto:CompraProduto, index:any){
    const dialogRef = this.dialog.open(CompraVendaAtualizarProdutoDialogComponent, {
      width: '600px',
      data: {'compraProduto':compraProduto, 'index':index, 'tipo':'atualizarCompraFinalizada'}
    });
    dialogRef.afterClosed().subscribe((result:CompraProduto) => {
      if(result !== undefined){
        this.compra.compra_produtos[index].compra_produto_quantidade = result.compra_produto_quantidade;
        this.compra.compra_produtos[index].produto_comprado_valor = result.produto_comprado_valor;
        this.compra.compra_produtos[index].compra_produto_unidade_medida = result.compra_produto_unidade_medida;

        this.httpRastreadorService.atualizarProdutoComprado(result).subscribe((data:any)=>{
          this.adminGlobalService.openSnackBar("Produto Atualizado com sucesso!", "fechar");
        });
        
      }else{
        console.log("Produto não atualizado");
      }
    });
  }
  removerProduto(index){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover produto', 'pergunta':'Você realmente deseja remover este produto?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.httpRastreadorService.removerProdutoComprado(this.compra.compra_produtos[index]).subscribe((data:any)=>{
          if(data.status == true){}
          this.adminGlobalService.openSnackBar("Produto removido!", "fechar");
          this.compra.compra_produtos.splice(index, 1);
        });

      }
    });
  }
  openDialogImportarPLU(){
    const dialogRef = this.dialog.open(UploadArquivoPluComponent, {
      width: '800px',
      disableClose:true,
      data: {
        compra:this.compra
      }
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.getCompra();
      }
    });
  }
  getTotalPesoProduto(fornecedor_compra:FornecedorCompra[]){
   let peso:number = 0;
   if(fornecedor_compra.length > 0){
    fornecedor_compra.map(d=>{
      peso = peso + ( Number( d.peso_individual ) * Number( d.quantidade_produto ) );
    });
      return peso;
    }else{
      return 0;
    }
  }
  visualizarFornecedoresProduto(fornecedor_compra:FornecedorCompra[]){
    const dialogRef = this.dialog.open(VerFornecedoresCompraProdutoComponent, {
      width: '600px',
      data: fornecedor_compra
    });
    dialogRef.afterClosed().subscribe((result:any) => {
    });
  }
  checkImportacaoPLU(){
    let totalProdutosImportacaoPLU = this.getTotalProdutosVinculadoPLU();
    if(totalProdutosImportacaoPLU > 0){
      if(this.compra.compra_produtos.length == totalProdutosImportacaoPLU){
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }
  getTotalProdutosVinculadoPLU(){
    let total = 0;
    this.compra.compra_produtos.map(d=>{
      total = total + d.produto_plu.length;
    });
    return total;
  }
  habilitarImportacaoPLU(){
    let total = 0;
    this.compra.compra_produtos.map(d=>{
      if(d.produto_plu.length == 0){
        total++;
      }
    });
    if(total == 0){
      return true;
    }else{
      return false;
    }
  }
  removerPLU(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover PLU', 'pergunta':'Você realmente deseja remover o vinculo PLU desta compra?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.httpRastreadorService.removerPLUCompra(this.idCompra).subscribe((d:any)=>{
            this.adminGlobalService.openSnackBar("PLU removido com sucesso!", "fechar");
            this.getCompra();
        }, error=>{
          this.adminGlobalService.openSnackBar("Não foi possível remover o vinculo PLU desta compra!", "fechar");
        });
      }
    });
  }


  
}
