import { ConfigStoreService } from '../../admin/admin-home/services/config-store.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-login-lista',
  templateUrl: './login-lista.component.html',
  styleUrls: ['./login-lista.component.css']
})
export class LoginListaComponent implements OnInit {

  userEmail:string;
  userSenha:string;
  progressbarLogin:boolean = false;

  constructor(
    public _snackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    public configStoreService:ConfigStoreService
  ) { }

  ngOnInit() {
  }
  fazerLogin(){
    this.progressbarLogin = true;
    this.login(this.userSenha, this.userEmail).subscribe((data)=>{

      let d:any = data;
      this.openSnackBar("Login autorizado, redirecionando você ao painel administrativo!", "fechar");

      let dataLogin = {
        e:this.userEmail,
        p:this.userSenha
      }

      localStorage.setItem('access_token', d.access_token);
      localStorage.setItem('access_data', btoa(JSON.stringify(dataLogin)));

      setTimeout(()=>{
        this.router.navigate(['/lista']);
      }, 200);

    }, error=>{
      this.openSnackBar("Login não autorizado, verifique seu e-mail e senha de acesso!", "fechar");
    });
  }
  changeRoute(){
    this.router.navigate(['/lista']);
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  login(password, email){
    let dataLogin = {
      password:password,
      email:email
    }
    return this.http.post(this.configStoreService.API_SERVER_URL + "auth/login", dataLogin);
  }
}
