export interface Produtos{
  rastreio_produtos_id: number;
  rastreio_produtos_nome:string;
  rastreio_produtos_descricao:string;
  rastreio_produtos_imagem:string;
  rastreio_produtos_imagem_loaded:boolean;
  rastreio_produtos_status:boolean;
  rastreio_produtos_url_fonte:string;
}
export enum UnidMedida{
  KILO = 1,
  CAIXA = 2,
  PACOTE = 3,
  UNIDADE = 4
}
