import { ListaCompra, Produto, UnidMedida } from '../teste/teste/lista.model';
import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import {Location} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {

  arrListaCompra:ListaCompra[] = [];

  listaCompra:ListaCompra = {
    nome:'',
    data:'',
    arrProdutos:[]
  };

  totalGeral:number = 0;

  constructor(
    private _location: Location,
    public _snackBar: MatSnackBar
  ) {
  }
  backPage(){
    this._location.back();
  }
  clearAll(){
    this.listaCompra.arrProdutos = [];
  }
  addProduto(){
    let produto:Produto = {
      id:1,
      nome:"Abacaxi",
      descricao:"",
      foto:"http://127.0.0.1:8000/uploads/images/produto/d0e359da7cf19afe7a8749b352ce7bbc.png",
      unidMedida:UnidMedida.KILO,
      valor:2.54,
      quantidade:1000
    }
    this.listaCompra.arrProdutos.push(produto);
    this.openSnackBar("Produto adicionado ao carrinho!", "fechar");
  }
  getTotalGeral(){
    let total:number = 0;
    this.listaCompra.arrProdutos.map((produto, index)=>{

      let totalParcial:number = 0;

      if(produto.unidMedida == UnidMedida.PACOTE || produto.unidMedida == UnidMedida.CAIXA || produto.unidMedida == UnidMedida.UNIDADE){
        totalParcial = Number(produto.valor) * Number(produto.quantidade);
      }else if(produto.unidMedida == UnidMedida.KILO){
        if(produto.valor > 0){
          totalParcial = (Number(produto.valor) / 1000) * Number(produto.quantidade);
        }else{
          totalParcial = 0;
        }
      }
      total += totalParcial;
    });

    if(this.listaCompra.arrProdutos.length == 0){
      return 0;
    }else{
      return total;
    }
  }
  getTotalIndividual(index){
    let totalParcial:number = 0;
    if(this.listaCompra.arrProdutos[index].unidMedida == UnidMedida.PACOTE || this.listaCompra.arrProdutos[index].unidMedida == UnidMedida.CAIXA || this.listaCompra.arrProdutos[index].unidMedida == UnidMedida.UNIDADE){
      totalParcial = Number(this.listaCompra.arrProdutos[index].valor) * Number(this.listaCompra.arrProdutos[index].quantidade);
    }else if(this.listaCompra.arrProdutos[index].unidMedida == UnidMedida.KILO){
      if(this.listaCompra.arrProdutos[index].valor > 0){
        totalParcial = (Number(this.listaCompra.arrProdutos[index].valor) / 1000) * Number(this.listaCompra.arrProdutos[index].quantidade);
      }
    }
    return totalParcial;
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
