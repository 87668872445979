import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AdminGlobalService } from '../services/admin-global.service';
import { CodigoBarras } from 'src/app/model/codigo-barras.model';
import { HttpRastreadorService } from '../services/http-rastreador.service';

export interface produtosBusca{
  id:number;
  nome:string;
  imagem:string;
}

@Component({
  selector: 'app-criar-codigos-de-barras',
  templateUrl: './criar-codigos-de-barras.component.html',
  styleUrls: ['./criar-codigos-de-barras.component.css']
})
export class CriarCodigosDeBarrasComponent implements OnInit {

  editMode:boolean = false;
  tituloJanela:string = "Criar um códigos de barras";
  produtoSelecionado = false;

  paginaAtual:number = 1;
  itensPorPagina:number = 10;
  termoBusca:string = "";
  orderBy:string = "nome";
  order:string = "DESC";
  arrProdutosBusca:produtosBusca[] = [];
  progressbarBusca:boolean = false;
  buscaRealizada:boolean = false;

  codigoBarras:CodigoBarras = {
    id:null,
    id_produto:null,
    id_cliente:null,
    codigo_barras:null,
    tipo_codigo_barras:null,
    produto_nome:null,
    produto_imagem:null,
  }

  constructor(
    public httpRastreadorService:HttpRastreadorService,
    public dialogRef: MatDialogRef<CriarCodigosDeBarrasComponent>,
    public adminGlobalService:AdminGlobalService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    if(data.id === undefined){
      this.codigoBarras.id_cliente = data.cliente_id;
    }else{
      this.tituloJanela = "Editar código de barras";
      this.editMode = true;
      this.produtoSelecionado = true;
      this.codigoBarras.id = data.id;
      this.codigoBarras.id_produto = data.id_produto;
      this.codigoBarras.id_cliente = data.id_cliente;
      this.codigoBarras.codigo_barras = data.codigo_barras;
      this.codigoBarras.tipo_codigo_barras = data.tipo_codigo_barras;
      this.codigoBarras.produto_nome = data.produto_nome;
      this.codigoBarras.produto_imagem = data.produto_imagem;
    }
  }
  ngOnInit() {

  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }
  salvarDados(){
    if(this.editMode){
      this.atualizarCodigoBarras();
    }else{
      this.criarNovoCodigoBarras();
    }
  }
  criarNovoCodigoBarras(){
    this.httpRastreadorService.criarCodigoBarras(this.codigoBarras).subscribe((d:any)=>{

      if(d.status){
        this.codigoBarras.id = d.id;
        this.dialogRef.close(this.codigoBarras);
        this.adminGlobalService.openSnackBar("Código de barras salvo com sucesso!", "fechar");



      }else{
        this.adminGlobalService.openSnackBar("Não foi possível criar este código de barras, tente novamente!", "fechar");
      }
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível criar este código de barras, tente novamente!", "fechar");
    });
  }
  atualizarCodigoBarras(){
    this.httpRastreadorService.atualizarCodigoBarras(this.codigoBarras).subscribe((d:any)=>{
      this.adminGlobalService.openSnackBar("Código de barras salvo com sucesso!", "fechar");

      this.dialogRef.close(this.codigoBarras);
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível atualizar este código de barras, tente novamente!", "fechar");
    });
    
    console.log("SALVO");
  }
  getStringSize():number{
    if(this.codigoBarras.codigo_barras != null){
      return this.codigoBarras.codigo_barras.length;
    }else{
      return 0;
    }
    
  }
  validarCodigoBarras(){
    if(this.codigoBarras.codigo_barras != null){
      let newbar = this.codigoBarras.codigo_barras.substring(0, this.codigoBarras.codigo_barras.length - 1);
      let digito = this.calcDigitoCodigoBarras(newbar);
      var lastChar = this.codigoBarras.codigo_barras[this.codigoBarras.codigo_barras.length -1];
      if(digito == lastChar){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }
  calcDigitoCodigoBarras(numero:string){
    let digito:any;
    let factor:number = 3;
    let sum:number = 0;
    for(let index:number = numero.length; index > 0; --index){
      sum = sum + Number(numero.substring(index-1, index)) * factor;
      factor = 4 - factor;
    }
    digito = ((1000 - sum) % 10);
    if (numero.length > 17){
      digito = "";
      return false;
    }
    if (((((numero.length != 7) && (numero.length != 11)) && (numero.length != 12)) && (numero.length != 13)) && (numero.length != 17)){
      digito = "";
      //alert("O número deve possuir 7 (EAN/UCC-8), 11 (UCC-12), 12 (EAN/UCC-13), 13 (EAN/UCC-14) ou 17 (SSCC) dígitos");
      return false;
    }
    return digito;
  }
  buscarProdutos(){
    this.progressbarBusca = true;
    this.httpRastreadorService.getListaProdutos(this.paginaAtual,this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe((result:any)=>{
      
      this.arrProdutosBusca = [];
      
      result.data.map((d:any)=>{
        let prod:produtosBusca = {
          id:d.rastreio_produtos_id,
          nome:d.rastreio_produtos_nome,
          imagem:d.rastreio_produtos_imagem
        }
        this.arrProdutosBusca.push(prod);
      });
      this.buscaRealizada = true;
      this.progressbarBusca = false;
    }, error=>{
      this.buscaRealizada = true;
      this.progressbarBusca = false;
      this.adminGlobalService.openSnackBar("Não foi possível realizar sua busca, por favor tente novamente!", "fechar");
    });
  }
  selecionarProduto(produto:produtosBusca){
    this.codigoBarras.id_produto = produto.id;
    this.codigoBarras.produto_imagem = produto.imagem;
    this.codigoBarras.produto_nome = produto.nome;
    this.produtoSelecionado = true;
  }

}
