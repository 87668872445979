
import { Transportadora } from '../../../model/transportadora.model';
import { HttpAdminService } from './../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import { CadastrarTransportadoraDialogComponent } from './cadastrar-transportadora-dialog/cadastrar-transportadora-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-transportadoras',
  templateUrl: './transportadoras.component.html',
  styleUrls: ['./transportadoras.component.css']
})
export class TransportadorasComponent implements OnInit {

  transportadoras:Transportadora[] = [];
  progressbarAtivo:boolean = false;
  termoBusca:string = "";

  //###############################################################
  //###############################################################
  //#################### Paginação ################################
  order:any = 'ASC';
  orderBy:any = 'nome';
  itensPorPagina:any = 10;
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;

  buscaRealizada:boolean = false;
  //###############################################################
  //###############################################################
  //###############################################################
  constructor(
    public adminGlobalService: AdminGlobalService,
    public httpAdminService:HttpAdminService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
    ) {
  }
  ngOnInit() {
    this.adminGlobalService.setPageTitle("Transportadoras");
    //################################################
    let paginaRota = this.route.snapshot.paramMap.get("pagina");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(paginaRota != null){
      this.paginaAtual = paginaRota;
      this.getClientList(paginaRota);
    }else{
      this.getClientList(1);
    }
    //
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;

    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }
  getClientList(paginaAtual){

    this.progressbarAtivo = true;

    this.httpAdminService.getTransportadoraList(paginaAtual,this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe(data=>{

      let mapData:any = data;

      this.paginasProximas = [];
      this.paginasAnteriores = [];

      this.transportadoras = mapData.data;
      this.progressbarAtivo = false;
      this.ultimaPagina = mapData.last_page;

      if(mapData.last_page > 1){
        this.exibirPaginacao = true;
      }
      //###################################################
      //Criação das próximas páginas
      let prox = Number(paginaAtual) + 1;

      for( let i = prox; i <= mapData.last_page; i++){
        if(this.paginasProximas.length < this.linksPorPagina){
          this.paginasProximas.push(i);
        }
      }
      //###################################################
      //###################################################
      //###################################################
      //Criação das páginas anteriores
      let ant = paginaAtual - this.linksPorPagina;

      for( let j = ant; j < paginaAtual; j++){
        if(j>0){
          this.paginasAnteriores.push(j);
        }
      }
      //###################################################
      //###################################################
      this.paginaAtual = paginaAtual;
      this.buscaRealizada = true;
      this.scrollTop();

    }, error=>{
      this.progressbarAtivo = false;
      console.log('erro! : ' + error);
    });
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getClientList(paginaAtual);
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag)
      this.getClientList(pag);
    }
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getClientList(pag);
    }
  }
  changeRoute(paginaAtual:any) {
    this.router.navigate(['admin/transportadoras/'+paginaAtual]);
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.getClientList(1);
  }
  abrirCadastro():void{
    const dialogRef = this.dialog.open(CadastrarTransportadoraDialogComponent, {
      width: '400px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      try {
        if(result){
          this.buscaNovaLista(1);
        }
      } catch (error) {
      }
    });
  }

}
