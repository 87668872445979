import { Injectable } from '@angular/core';
import { Venda } from 'src/app/model/venda.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UnidMedida } from '../../../rastreador/model/produto.model';
import { VendaProduto } from '../vendas/vendas.component';

@Injectable({
  providedIn: 'root'
})
export class VendaService {

  arrProdutosImpressao:VendaProduto[] = [];

  public venda:Venda = {
    venda_id:0,
    venda_id_cliente:0,
    venda_data:"",
    venda_nota_fiscal:"",
    venda_id_fornecedor:0,
    venda_fornecedor_nome:"",
    venda_fornecedor_cnpj:"",
    venda_produtos:[]
  }

  constructor(private _snackBar: MatSnackBar) {
   }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  salvarStorage(){
    localStorage.setItem('novaVenda', JSON.stringify(this.venda));
  }


}
