import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from 'src/app/admin/admin-home/services/admin-global.service';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css']
})
export class ConfiguracoesComponent implements OnInit {

  constructor(public adminGlobalService:AdminGlobalService) {
    adminGlobalService.setPageTitle("Configurações");



   }

  ngOnInit() {
  }

}
