import { Component, OnInit } from '@angular/core';
import { HttpRastreadorService } from '../../admin/admin-home/services/http-rastreador.service';
import { AdminGlobalService } from 'src/app/admin/admin-home/services/admin-global.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import { Municipio, Estado, PerfilEmpresa } from '../model/util.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/admin/admin-home/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-criar-conta',
  templateUrl: './criar-conta.component.html',
  styleUrls: ['./criar-conta.component.css']
})
export class CriarContaComponent implements OnInit {

  registerForm: FormGroup;

  arrMunicipios:Municipio[] = [];
  arrEstados:Estado[] = [{"estado_id":1,"estado_nome":"Acre","estado_uf":"AC"},{"estado_id":2,"estado_nome":"Alagoas","estado_uf":"AL"},{"estado_id":3,"estado_nome":"Amazonas","estado_uf":"AM"},{"estado_id":4,"estado_nome":"Amap\u00e1","estado_uf":"AP"},{"estado_id":5,"estado_nome":"Bahia","estado_uf":"BA"},{"estado_id":6,"estado_nome":"Cear\u00e1","estado_uf":"CE"},{"estado_id":7,"estado_nome":"Distrito Federal","estado_uf":"DF"},{"estado_id":8,"estado_nome":"Esp\u00edrito Santo","estado_uf":"ES"},{"estado_id":9,"estado_nome":"Goi\u00e1s","estado_uf":"GO"},{"estado_id":10,"estado_nome":"Maranh\u00e3o","estado_uf":"MA"},{"estado_id":11,"estado_nome":"Minas Gerais","estado_uf":"MG"},{"estado_id":12,"estado_nome":"Mato Grosso do Sul","estado_uf":"MS"},{"estado_id":13,"estado_nome":"Mato Grosso","estado_uf":"MT"},{"estado_id":14,"estado_nome":"Par\u00e1","estado_uf":"PA"},{"estado_id":15,"estado_nome":"Para\u00edba","estado_uf":"PB"},{"estado_id":16,"estado_nome":"Pernambuco","estado_uf":"PE"},{"estado_id":17,"estado_nome":"Piau\u00ed","estado_uf":"PI"},{"estado_id":18,"estado_nome":"Paran\u00e1","estado_uf":"PR"},{"estado_id":19,"estado_nome":"Rio de Janeiro","estado_uf":"RJ"},{"estado_id":20,"estado_nome":"Rio Grande do Norte","estado_uf":"RN"},{"estado_id":21,"estado_nome":"Rond\u00f4nia","estado_uf":"RO"},{"estado_id":22,"estado_nome":"Roraima","estado_uf":"RR"},{"estado_id":23,"estado_nome":"Rio Grande do Sul","estado_uf":"RS"},{"estado_id":24,"estado_nome":"Santa Catarina","estado_uf":"SC"},{"estado_id":25,"estado_nome":"Sergipe","estado_uf":"SE"},{"estado_id":26,"estado_nome":"S\u00e3o Paulo","estado_uf":"SP"},{"estado_id":27,"estado_nome":"Tocantins","estado_uf":"TO"}];

  buscandoMunicipios:boolean = false;
  enviandoCadastro:boolean = false;
  perfilEmpresa:PerfilEmpresa = {
    empresaRazaoSocial:"",
    empresaNome:"",
    empresaCNPJ:"",
    empresaInscricaoEstadual:"",
    empresaEstado:"",
    empresaMunicipio:"",
    empresaBairro:"",
    empresaEndereco:"",
    empresaNumero:"",
    empresaLongitude:"",
    empresaLatitude:"",
    empresaTelefoneFixo:"",
    empresaTelefoneCelular:"",
    empresaEmail:"",
    empresaEmail2:"",
    empresaSenha:"",
    empresaSenha2:""
  }
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService
    ) {

  }

  ngOnInit() {
    //this.getListaMunicipios(1);
    this.registerForm = this.formBuilder.group({
      empresaRazaoSocial: ['', Validators.required],
      empresaNome: ['', Validators.required],
      empresaCNPJ: ['', Validators.required],
      empresaInscricaoEstadual: ['', Validators.required],
      empresaEstado: ['', Validators.required],
      empresaMunicipio: ['',Validators.required],
      empresaEndereco: ['', Validators.required],
      empresaBairro: ['', Validators.required],
      empresaNumero: ['', Validators.required],
      empresaTelefoneFixo: ['', Validators.required],
      empresaTelefoneCelular: ['', Validators.required],
      empresaEmail: ['', [Validators.required, Validators.email]],
      empresaEmail2: ['', [Validators.required, Validators.email]],
      empresaSenha: ['', Validators.required],
      empresaSenha2: ['', Validators.required]
    });
  }

  getListaMunicipios(estado:number):void{
    this.buscandoMunicipios = true;
    this.httpRastreadorService.getListaMunicipios(estado).subscribe((data:any)=>{
      this.arrMunicipios = data;
      this.buscandoMunicipios = false;
    }, error =>{
      this.buscandoMunicipios = false;
    });
  }

  getListaEstados():void{
    this.httpRastreadorService.getListaEstados().subscribe((data:any)=>{
      this.arrEstados = data;
    });
  }
  
  criarConta(){
    if (!this.registerForm.invalid) {
      if(this.adminGlobalService.validarCNPJ(this.perfilEmpresa.empresaCNPJ)){
        if(this.perfilEmpresa.empresaEmail === this.perfilEmpresa.empresaEmail2){
          this.enviandoCadastro = true;
          this.httpRastreadorService.criarContaUsuario(this.perfilEmpresa).subscribe((data:any)=>{
            this.enviandoCadastro = false;
            if(data.status == true){
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '600px',
                data: {'titulo':'Criar conta', 'pergunta':'Conta criada com sucesso, aguarde que entraremos em contato com você para orientá-lo sobre os próximos passos antes de usar o sistema!'}
              });
              dialogRef.afterClosed().subscribe((result:boolean) => {
                this.router.navigate(['/login']);
              });
            }
          }, error=>{
            this.enviandoCadastro = false;
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar criar sua conta de usuário, verifique sua conexão com a internet!", "fechar");
          });
        }else{
          this.adminGlobalService.openSnackBar("Os E-mails informados precisam ser iguais!", "fechar");
        }
      }else{
        this.adminGlobalService.openSnackBar("O CNPJ informado é inválido!", "fechar");
      }
    }else{
      this.adminGlobalService.openSnackBar("Você precisa preencher todos os campos antes de continuar!", "fechar");
    }
  }
}
