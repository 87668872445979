import { Injectable, ɵConsole } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { MatSnackBar } from '@angular/material';
import { Estado } from 'src/app/rastreador/model/util.model';
@Injectable({
  providedIn: 'root'
})
export class AdminGlobalService {
  debbuger;
  public versao: string = "4.1 - 14/12/2022";
  public tituloPagina: string = "Thoth Tracker";
  public showTollbarProgressbar: boolean = false;
  public mobile: boolean = false;

  public arrEstados: Estado[] = [
    { "estado_id": 1, "estado_nome": "Acre", "estado_uf": "AC" },
    { "estado_id": 2, "estado_nome": "Alagoas", "estado_uf": "AL" },
    { "estado_id": 3, "estado_nome": "Amazonas", "estado_uf": "AM" },
    { "estado_id": 4, "estado_nome": "Amap\u00e1", "estado_uf": "AP" },
    { "estado_id": 5, "estado_nome": "Bahia", "estado_uf": "BA" },
    { "estado_id": 6, "estado_nome": "Cear\u00e1", "estado_uf": "CE" },
    { "estado_id": 7, "estado_nome": "Distrito Federal", "estado_uf": "DF" },
    { "estado_id": 8, "estado_nome": "Esp\u00edrito Santo", "estado_uf": "ES" },
    { "estado_id": 9, "estado_nome": "Goi\u00e1s", "estado_uf": "GO" },
    { "estado_id": 10, "estado_nome": "Maranh\u00e3o", "estado_uf": "MA" },
    { "estado_id": 11, "estado_nome": "Minas Gerais", "estado_uf": "MG" },
    { "estado_id": 12, "estado_nome": "Mato Grosso do Sul", "estado_uf": "MS" },
    { "estado_id": 13, "estado_nome": "Mato Grosso", "estado_uf": "MT" },
    { "estado_id": 14, "estado_nome": "Par\u00e1", "estado_uf": "PA" },
    { "estado_id": 15, "estado_nome": "Para\u00edba", "estado_uf": "PB" },
    { "estado_id": 16, "estado_nome": "Pernambuco", "estado_uf": "PE" },
    { "estado_id": 17, "estado_nome": "Piau\u00ed", "estado_uf": "PI" },
    { "estado_id": 18, "estado_nome": "Paran\u00e1", "estado_uf": "PR" },
    { "estado_id": 19, "estado_nome": "Rio de Janeiro", "estado_uf": "RJ" },
    { "estado_id": 20, "estado_nome": "Rio Grande do Norte", "estado_uf": "RN" },
    { "estado_id": 21, "estado_nome": "Rond\u00f4nia", "estado_uf": "RO" },
    { "estado_id": 22, "estado_nome": "Roraima", "estado_uf": "RR" },
    { "estado_id": 23, "estado_nome": "Rio Grande do Sul", "estado_uf": "RS" },
    { "estado_id": 24, "estado_nome": "Santa Catarina", "estado_uf": "SC" },
    { "estado_id": 25, "estado_nome": "Sergipe", "estado_uf": "SE" },
    { "estado_id": 26, "estado_nome": "S\u00e3o Paulo", "estado_uf": "SP" },
    { "estado_id": 27, "estado_nome": "Tocantins", "estado_uf": "TO" }
  ];
  constructor(
    public _snackBar: MatSnackBar,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.tituloPagina);
  }
  public setPageTitle(title: string) {
    this.tituloPagina = title;
    this.titleService.setTitle(this.tituloPagina);
  }
  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
    validarCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14) {
        return false;
      }

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
        return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
        return false;

      return true;

    }

    validarCPF(controle) {
      const cpf = controle;

      let soma: number = 0;
      let resto: number;
      let valido: boolean;

      if (controle.length != 11) {
        return false;
      }

      const regex = new RegExp('[0-9]{11}');

      if (
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999' ||
        !regex.test(cpf)
      )
        valido = false;
      else {
        for (let i = 1; i <= 9; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) valido = false;

        soma = 0;
        for (let i = 1; i <= 10; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) valido = false;
        valido = true;
      
      }

      debugger
      if(valido == true)
          return true
      else return false
    
    }

}
