import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RastreioProdutoDestino } from '../model/util.model';

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.css']
})
export class ProductInfoDialogComponent implements OnInit {

  customMaska: any;

  pattern =  {
    'P': {
        pattern: new RegExp('\\d'),
    }};


  constructor(
    @Inject(MAT_DIALOG_DATA) public data:RastreioProdutoDestino,
    public dialogRef: MatDialogRef<ProductInfoDialogComponent>
  ) {
    debugger
    debugger;
    if(data.destino_inscricao_rural_estadual.length > 13 ){
      this.customMaska = ['PP.PPP.PPP/PPPP-PP', this.pattern];
    }else{
      this.customMaska = ['PPP.PPP.PPP-PP', this.pattern];
    }
   }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
