import { Fornecedor } from './../../../model/fornecedor.model';
import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Cliente } from 'src/app/model/cliente.model';
import { Municipio, Estado, Marker } from 'src/app/rastreador/model/util.model';
import { MatDialog } from '@angular/material';
import { ListarCodigosDeBarrasComponent } from '../listar-codigos-de-barras/listar-codigos-de-barras.component';
import { MapsAPILoader } from '@agm/core';
import { ConsultaCepService } from 'src/app/model/ConsultaCepService';
declare var google: any;

@Component({
  selector: 'app-cadastrar-cliente',
  templateUrl: './cadastrar-cliente.component.html',
  styleUrls: ['./cadastrar-cliente.component.css']
})
export class CadastrarClienteComponent implements OnInit {

  cadastrarCPF: boolean = false;
  bounds = null;
  private mapa: any;
  private toSelect;
  private latitude;
  private longitude;

  registerForm: FormGroup;
  arrEstados: Estado[] = [
    { "estado_id": 1, "estado_nome": "Acre", "estado_uf": "AC" },
    { "estado_id": 2, "estado_nome": "Alagoas", "estado_uf": "AL" },
    { "estado_id": 3, "estado_nome": "Amazonas", "estado_uf": "AM" },
    { "estado_id": 4, "estado_nome": "Amap\u00e1", "estado_uf": "AP" },
    { "estado_id": 5, "estado_nome": "Bahia", "estado_uf": "BA" },
    { "estado_id": 6, "estado_nome": "Cear\u00e1", "estado_uf": "CE" },
    { "estado_id": 7, "estado_nome": "Distrito Federal", "estado_uf": "DF" },
    { "estado_id": 8, "estado_nome": "Esp\u00edrito Santo", "estado_uf": "ES" },
    { "estado_id": 9, "estado_nome": "Goi\u00e1s", "estado_uf": "GO" },
    { "estado_id": 10, "estado_nome": "Maranh\u00e3o", "estado_uf": "MA" },
    { "estado_id": 11, "estado_nome": "Minas Gerais", "estado_uf": "MG" },
    { "estado_id": 12, "estado_nome": "Mato Grosso do Sul", "estado_uf": "MS" },
    { "estado_id": 13, "estado_nome": "Mato Grosso", "estado_uf": "MT" },
    { "estado_id": 14, "estado_nome": "Par\u00e1", "estado_uf": "PA" },
    { "estado_id": 15, "estado_nome": "Para\u00edba", "estado_uf": "PB" },
    { "estado_id": 16, "estado_nome": "Pernambuco", "estado_uf": "PE" },
    { "estado_id": 17, "estado_nome": "Piau\u00ed", "estado_uf": "PI" },
    { "estado_id": 18, "estado_nome": "Paran\u00e1", "estado_uf": "PR" },
    { "estado_id": 19, "estado_nome": "Rio de Janeiro", "estado_uf": "RJ" },
    { "estado_id": 20, "estado_nome": "Rio Grande do Norte", "estado_uf": "RN" },
    { "estado_id": 21, "estado_nome": "Rond\u00f4nia", "estado_uf": "RO" },
    { "estado_id": 22, "estado_nome": "Roraima", "estado_uf": "RR" },
    { "estado_id": 23, "estado_nome": "Rio Grande do Sul", "estado_uf": "RS" },
    { "estado_id": 24, "estado_nome": "Santa Catarina", "estado_uf": "SC" },
    { "estado_id": 25, "estado_nome": "Sergipe", "estado_uf": "SE" },
    { "estado_id": 26, "estado_nome": "S\u00e3o Paulo", "estado_uf": "SP" },
    { "estado_id": 27, "estado_nome": "Tocantins", "estado_uf": "TO" }
  ];
  arrMunicipios: Municipio[] = [];
  public cliente: Cliente = {
    cliente_id: 0,
    cliente_razao_social: "",
    cliente_nome: "",
    cliente_cnpj: "",
    cliente_inscricao_estadual: "",
    cliente_endereco_longitude: "",
    cliente_endereco_latitude: "",
    cliente_pais: "",
    cliente_estado: "",
    cliente_cidade: "",
    cliente_bairro: "",
    cliente_endereco: "",
    cliente_numero: "",
    cliente_complemento: "",
    cliente_observacao: "",
    cliente_cep: "",
    cliente_tipoPessoa: "",
  };
  progressBarCadastro: boolean = false;
  progressBarBuscarFornecedor: boolean = false;
  buscandoMunicipios: boolean = false;
  modoEdicao: boolean = false;
  arrMarker: Marker[] = [];
  zoom: number = 16;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public httpRastreadorService: HttpRastreadorService,
    public adminGlobalService: AdminGlobalService,
    private mapsAPILoader: MapsAPILoader,
    private cepService: ConsultaCepService
  ) {
    let idCliente = this.route.snapshot.paramMap.get("idcliente");
    if (idCliente != null) {
      adminGlobalService.setPageTitle("Editar cliente");
      this.modoEdicao = true;
      this.buscarDadosAtualizar(idCliente);
    } else {
      adminGlobalService.setPageTitle("Cadastrar cliente");
      this.modoEdicao = false;
      this.cliente.cliente_tipoPessoa = "CNPJ"
      this.obterLocalizacaoUsuario();
    }
    this.mapsAPILoader.load().then(() => {
      this.initialize();
    });
  }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      cliente_razao_social: ['', Validators.required],
      cliente_nome: ['', Validators.required],
      cliente_cnpj: ['', Validators.required],
      cliente_inscricao_estadual: ['', Validators.required],
      cliente_endereco_longitude: ['', Validators.required],
      cliente_endereco_latitude: ['', Validators.required],
      cliente_pais: ['', Validators.required],
      cliente_estado: ['', Validators.required],
      cliente_cidade: ['', Validators.required],
      cliente_bairro: ['', Validators.required],
      cliente_endereco: ['', Validators.required],
      cliente_numero: ['', Validators.required],
      cliente_cep: ['', Validators.required],
      cliente_complemento: [''],
      cliente_tipoPessoa: [''],
      cliente_observacao: ['']
    });
  }
  buscarDadosAtualizar(idCliente) {
    this.progressBarBuscarFornecedor = true;
    this.httpRastreadorService.buscarClienteAtualizar(idCliente).subscribe((data: any) => {
      this.cliente = data[0];
      this.somePlaceholder = this.cliente.cliente_tipoPessoa;
      this.progressBarBuscarFornecedor = false;
      this.getListaMunicipios(Number(this.cliente.cliente_estado), this.cliente.cliente_cidade);
      this.mudarCoordenadas();
    }, error => {
      this.progressBarBuscarFornecedor = false;
      this.adminGlobalService.openSnackBar("Não foi possível buscar o cliente selecionado para edição, por favor tente novamente!", "fechar");
    });
  }
  atualizarCliente() {
    if (!this.registerForm.invalid) {
      if (this.adminGlobalService.validarCNPJ(this.cliente.cliente_cnpj) || this.adminGlobalService.validarCPF(this.cliente.cliente_cnpj) == true) {
        this.progressBarCadastro = true;
        this.httpRastreadorService.atualizarCliente(this.cliente).subscribe((data: any) => {
          this.progressBarCadastro = false;
          if (data.status) {
            this.adminGlobalService.openSnackBar("Cliente atualizado com sucesso!", "fechar");
          } else {
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar atualizar o cliente, tente novamente!", "fechar");
          }
        }, error => {
          this.progressBarCadastro = false;
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar atualizar o cliente, verifique sua conexão de internet e tente novamente!", "fechar");
        });
      } else {
        this.adminGlobalService.openSnackBar("O " + this.cliente.cliente_tipoPessoa + " informado é inválido!", "fechar");
      }
    } else {
      this.adminGlobalService.openSnackBar("Você precisa preencher todos os campos para salvar!", "fechar");
    }
  }
  cadastrarCliente() {
    if (!this.registerForm.invalid) {
      if (this.adminGlobalService.validarCNPJ(this.cliente.cliente_cnpj) || this.adminGlobalService.validarCPF(this.cliente.cliente_cnpj) == true) {
        this.progressBarCadastro = true;
        this.httpRastreadorService.cadastrarCliente(this.cliente).subscribe((data: any) => {
          this.progressBarCadastro = false;
          if (data.status) {
            this.adminGlobalService.openSnackBar("Cliente cadastrado com sucesso!", "fechar");
            this.cliente = {
              cliente_id: 0,
              cliente_razao_social: "",
              cliente_nome: "",
              cliente_cnpj: "",
              cliente_inscricao_estadual: "",
              cliente_endereco_longitude: "",
              cliente_endereco_latitude: "",
              cliente_pais: "",
              cliente_estado: "",
              cliente_cidade: "",
              cliente_bairro: "",
              cliente_endereco: "",
              cliente_numero: "",
              cliente_complemento: "",
              cliente_observacao: "",
              cliente_cep: "",
              cliente_tipoPessoa: "",
            };
          } else {
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar cadastrar o cliente, tente novamente!", "fechar");
          }
        }, error => {
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar cadastrar o cliente, verifique sua conexão de internet e tente novamente!", "fechar");
        });
      } else {
        this.adminGlobalService.openSnackBar("O" + this.cliente.cliente_tipoPessoa + " informado é inválido!", "fechar");
      }
    } else {
      this.adminGlobalService.openSnackBar("Você precisa preencher todos os campos para salvar!", "fechar");
    }
  }
  getListaMunicipios(estado: number, municipio?: any): void {
    this.buscandoMunicipios = true;
    this.httpRastreadorService.getListaMunicipios(estado).subscribe((data: any) => {
      this.arrMunicipios = data;
      this.buscandoMunicipios = false;
      this.cliente.cliente_cidade = municipio;
    }, error => {
      this.buscandoMunicipios = false;
    });
  }
  placeMarker($event) {
    this.arrMarker = [];
    let p: Marker = {
      lat: Number($event.coords.lat),
      lng: Number($event.coords.lng),
      label: "",
      text: "Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
    this.cliente.cliente_endereco_latitude = $event.coords.lat;
    this.cliente.cliente_endereco_longitude = $event.coords.lng;
  }
  mudarCoordenadas() {
    this.arrMarker = [];
    let p: Marker = {
      lat: Number(this.cliente.cliente_endereco_latitude),
      lng: Number(this.cliente.cliente_endereco_longitude),
      label: "",
      text: "Localização",
      draggable: false,
    }
    this.arrMarker.push(p);
  }
  obterLocalizacaoUsuario() {
    navigator.geolocation.getCurrentPosition((location) => {
      this.latitude = location.coords.latitude;
      this.longitude = location.coords.longitude;

      this.arrMarker = [];
      let p: Marker = {
        lat: Number(location.coords.latitude),
        lng: Number(location.coords.longitude),
        label: "",
        text: "Localização",
        draggable: false,
      }
      this.arrMarker.push(p);
      this.cliente.cliente_endereco_latitude = String(location.coords.latitude);
      this.cliente.cliente_endereco_longitude = String(location.coords.longitude);
      //console.log(location.coords.accuracy);
    });
  }
  listarCodigosDeBarras(): void {
    const dialogRef = this.dialog.open(ListarCodigosDeBarrasComponent, {
      width: '600px',
      data: this.cliente
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
    });
  }

  onChange(value) {
    if (value.value === "CPF") {
      this.cliente.cliente_tipoPessoa = value.value;
      this.cadastrarCPF = true;
      this.somePlaceholder = 'CPF'
      this.getCpfCnpjMask();
    } else {
      this.cliente.cliente_tipoPessoa = value.value;
      this.cadastrarCPF = false;
      this.somePlaceholder = 'CNPJ'
      this.getCpfCnpjMask();
    }
  }

  list = [{ "name": "Pessoa Jurídica", id: "CNPJ" }, { "name": "Pessoa Física", id: "CPF" }];
  private marker;
  async initialize() {
    // alert(this.map)
    if (document.getElementById("map") == null) {
      var latlng = new google.maps.LatLng(-18.8800397, -47.05878999999999);
      var options = {
        zoom: 16,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.mapa = new google.maps.Map(document.getElementById("map"), options);

      debugger
      this.marker = new google.maps.Marker({
        map: this.mapa,
        draggable: true,
      });
      debugger
      const geocoder = new google.maps.Geocoder();
      this.geocodeAddress(geocoder, this.mapa);

      alert("Sua localização não pode ser carregado, por favor permitir sua localização")
    } else
      this.mapa = await new google.maps.Map(document.getElementById("map"), {
        zoom: 16,
        center: {
          lat: -34.397,
          lng: 50.644,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }
      });
    debugger
    this.marker = new google.maps.Marker({
      map: this.mapa,
      draggable: true,
    });

    // console.log(this.map);
    const geocoder = new google.maps.Geocoder();
    this.geocodeAddress(geocoder, this.mapa);
  }

  geocodeAddress(geocoder, resultsMap) {
    const address = this.cliente.cliente_cep != '' ? this.cliente.cliente_cep : this.cliente.cliente_endereco_latitude + " " + this.cliente.cliente_endereco_longitude;

    geocoder.geocode(
      {
        address: address
      },
      (results, status) => {
        if (status === "OK") {
          this.cliente.cliente_endereco_latitude = results[0].geometry.location.lat();
          this.cliente.cliente_endereco_longitude = results[0].geometry.location.lng();
          console.log(results)
          resultsMap.setCenter(results[0].geometry.location);
          new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location
          });

        } else {
          console.log(
            "Geocode was not successful for the following reason: " + status
          );
        }
      }
    );
  }

  consultaCEP(cep) {
    // Nova variável "cep" somente com dígitos.
    cep = cep.replace(/\D/g, '');

    if (cep != null && cep !== '') {
      this.cepService.consultaCEP(cep)
        .subscribe(dados => this.populaDadosForm(dados, this.registerForm));
    }
  }

  populaDadosForm(dados, formulario) {

    formulario.get('cliente_bairro').setValue(dados.bairro);
    formulario.get('cliente_complemento').setValue(dados.complemento);
    formulario.get('cliente_endereco').setValue(dados.logradouro);
    // formulario.get('cliente_endereco_latitude').setValue("-23.5349525");
    // formulario.get('cliente_endereco_longitude').setValue("-46.738287,18");
    // formulario.get('fornecedor_estado').setValue(this.arrEstados[25].estado_nome);
  }
  somePlaceholder: string = this.cliente.cliente_tipoPessoa === 'CPF' ? 'CPF' : 'CNPJ';

  mask: string;
  mascara: string = this.cliente.cliente_tipoPessoa === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00';

  getCpfCnpjMask() {
    if (this.cliente.cliente_tipoPessoa == 'CPF')
      this.mask = '000.000.000-00';
    else if (this.cliente.cliente_tipoPessoa == 'CNPJ') {
      this.mask = '00.000.000/0000-00';
    }
    return this.mask;
  }

}
