import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnidMedida } from 'src/app/rastreador/model/produto.model'
import { CompraService } from 'src/app/admin/admin-home/services/compra.service';
import { CompraProduto, FornecedorCompra } from 'src/app/model/compra.model';
import { VendaService } from 'src/app/admin/admin-home/services/venda.service';
import { SelecionarFornecedorCompraProdutoComponent } from '../selecionar-fornecedor-compra-produto/selecionar-fornecedor-compra-produto.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AdminGlobalService } from '../services/admin-global.service';
import { Fornecedor } from 'src/app/model/fornecedor.model';
import { UserPerfil } from 'src/app/rastreador/model/util.model';
import { HttpRastreadorService } from '../services/http-rastreador.service';

@Component({
  selector: 'app-compra-venda-add-produto-confirm-dialog',
  templateUrl: './compra-venda-add-produto-confirm-dialog.component.html',
  styleUrls: ['./compra-venda-add-produto-confirm-dialog.component.css']
})
export class CompraVendaAddProdutoConfirmDialogComponent implements OnInit {

  arrUnidMedida: UnidMedida[] = [];

  origemProduto: string = "1";

  arrOrigem: any = [{
    origem: "1",
    label: "Fornecedor",
    checked: true
  },
  {
    origem: "2",
    label: "Produção própria",
    checked: false
  }];

  compraProduto: CompraProduto = {
    compra_produto_id: 0,
    compra_produto_id_compra: 0,
    compra_produto_quantidade: null,
    compra_produto_unidade_medida: UnidMedida.KILO,
    compra_venda_produto_id: null,
    produto_comprado_id: 0,
    produto_comprado_nome: "",
    produto_comprado_imagem: "",
    produto_comprado_valor: null,
    produto_comprado_cod_rastreio: "",
    produto_plu: [],
    fornecedor_compra: [],
    compra_produto_lote: ""
  }
  userPerfil: UserPerfil = {
    user_cidade: "",
    user_cnpj: "",
    user_inscricao_estadual: "",
    user_endereco: "",
    user_endereco_latitude: "",
    user_endereco_longitude: "",
    user_estado: "",
    user_bairro: "",
    user_id: 0,
    user_id_cliente: null,
    user_nome: "",
    user_razao_social: "",
    user_numero: "",
    user_pais: "",
    user_status_conta: null,
    user_telefone: "",
    user_telefone_celular: "",
    user_level: 1,
  }

  constructor(
    public dialog: MatDialog,
    public compraService: CompraService,
    public vendaService: VendaService,
    public dialogRef: MatDialogRef<CompraVendaAddProdutoConfirmDialogComponent>,
    public adminGlobalService: AdminGlobalService,
    public httpRastreador: HttpRastreadorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.compraProduto.produto_comprado_id = data.produto.rastreio_produtos_id;
    this.compraProduto.produto_comprado_nome = data.produto.rastreio_produtos_nome;
    this.compraProduto.produto_comprado_imagem = data.produto.rastreio_produtos_imagem;
  }
  ngOnInit() {
    this.userPerfil = JSON.parse(localStorage.getItem('logged_data_user'));
    /* this.adicionarFornecedor(); */
  }
  onNoClick(status: boolean): void {
    this.dialogRef.close();
  }

  alteraOrigem(idOrigem: number) {
    if (idOrigem == 2) {
      this.httpRastreador.getUltimoLoteProdutoEspecifico(this.compraProduto.produto_comprado_id).subscribe((ret: any) => {
        this.compraProduto.compra_produto_lote = (ret + 1);
      })
    } else {
      this.compraProduto.compra_produto_lote = '0';
    }
  }

  adicionarProduto() {
    if (this.origemProduto == '2') {

      this.compraProduto.fornecedor_compra = [];

      let fornecedorCompra: FornecedorCompra = {
        id: 0,
        fornecedor_id: 0,
        fornecedor_nome: this.userPerfil.user_nome,
        fornecedor_cnpj: this.userPerfil.user_cnpj,
        nota_fiscal_compra: "",
        quantidade_produto: this.compraProduto.compra_produto_quantidade,
        unidade_medida_compra: UnidMedida.KILO,
        peso_individual: 1,
        valor_kilo_produto: 0,
        embalagem: null,
        lote: this.compraProduto.compra_produto_lote,
      }

      this.compraProduto.fornecedor_compra.unshift(fornecedorCompra);

      this.compraService.compra.compra_produtos.push(this.compraProduto);
      this.compraService.openSnackBar("Produto adicionado ao carrinho!", "fechar");
      this.compraService.salvarStorage();
      this.dialogRef.close();

    } else {
      this.compraService.compra.compra_produtos.push(this.compraProduto);
      this.compraService.openSnackBar("Produto adicionado ao carrinho!", "fechar");
      this.compraService.salvarStorage();
      this.dialogRef.close();
    }
  }

  adicionarFornecedor() {
    const dialogRef = this.dialog.open(SelecionarFornecedorCompraProdutoComponent, {
      width: '540px',
      data: { tipo: "compra" }
    });
    dialogRef.afterClosed().subscribe((result: any) => {

      if (result !== false && result !== undefined) {
        this.compraProduto.fornecedor_compra.unshift(result);
      }
    });
  }
  editarFornecedor(fornecedor: Fornecedor, index: any) {
    const dialogRef = this.dialog.open(SelecionarFornecedorCompraProdutoComponent, {
      width: '540px',
      data: fornecedor
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != false && result != undefined) {
        this.compraProduto.fornecedor_compra[index] = result;
        this.adminGlobalService.openSnackBar("Fornecedor Atualizado!", "fechar");
      }
    });
  }
  removerFornecedor(index: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: { 'titulo': 'Remover fornecedor', 'pergunta': 'Você realmente deseja remover este fornecedor?' }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.compraProduto.fornecedor_compra.splice(index, 1);
        this.adminGlobalService.openSnackBar("Fornecedor Removido!", "fechar");
      }
    });
  }
  checkIsPar(numero: any) {
    let resultado = numero % 2;
    if (resultado == 0) {
      return true;
    } else {
      return false;
    }
  }
  numberOnly(event): boolean {
    try {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }

  }

  checkForm() {
    if (this.origemProduto == '1' && this.compraProduto.fornecedor_compra.length == 0 || this.origemProduto == '2' && this.compraProduto.compra_produto_quantidade == 0 || this.origemProduto == '2' && this.compraProduto.compra_produto_quantidade === null) {
      return false;
    } else {
      return true;
    }
  }


}
