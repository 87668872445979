import { Component, OnInit } from '@angular/core';
// import { NgxPrintModule } from 'ngx-print';
import { ProdutoPLU } from 'src/app/model/venda.model';
import { HttpRastreadorService } from '../services/http-rastreador.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { UserPerfil, Estado } from 'src/app/rastreador/model/util.model';
import { ActivatedRoute } from '@angular/router';
import { VendaService } from '../services/venda.service';
import { MatSliderChange, MatDialog, MatDatepickerInputEvent } from '@angular/material';

// import { SelectItem } from 'primeng/api';
import { CriarEtiquetaPersonalizadaComponent } from '../criar-etiqueta-personalizada/criar-etiqueta-personalizada.component';


//https://github.com/lindell/JsBarcode/wiki/Options#format
//https://www.pika.dev/npm/ngx-barcode6

export interface EtiquetaPersonalizada {
  value: string;
  label: string;
  largura: number;
  altura: number;
  itensPorPagina: number;
}
export interface CodigoBarras {
  codigo_barras: string;
  tipo_codigo_barras: string;
}
export enum TipoCodigoBarras {
  'EAN' = 1,
  'DUN14' = 2
}
export interface Etiqueta {
  rastreio_id: string;
  rastreio_id_int: number;
  produto_nome: string;
  produto_quantidade: number;
  produto_unidade_medida: number;
  produto_venda_produto_id: number;
  rastreio_embalagem_capacidade: string;
  rastreio_embalagem_nome: string;
  rastreio_embalagem_unidade_medida: number;
  bottom_bar_code: string;
  produto_plu: ProdutoPLU[],
  arrLinhaEtiqueta: LinhaEtiqueta[],
  quantidade: number;
  tipo_codigo_barras: string;
  codigo_barras: CodigoBarras[],
  quantidade_impressa: number,
  vezes_impresso: number
}
export enum LinhaAlinhamento {
  esquerda = 'left',
  centro = 'center',
  direita = 'right'
}
export interface LinhaEtiqueta {
  texto: string;
  fontSize: number;
  negrito: boolean;
  alinhamento: LinhaAlinhamento
}
//Lib utilizada para gerar impressão
//https://www.npmjs.com/package/ngx-print

@Component({
  selector: 'app-gerar-etiqueta-personalizada',
  templateUrl: './gerar-etiqueta-personalizada.component.html',
  styleUrls: ['./gerar-etiqueta-personalizada.component.css']
})
export class GerarEtiquetaPersonalizadaComponent implements OnInit {

  userPerfil: UserPerfil;
  arrEtiqueta: Etiqueta[] = [];
  arrEtiquetasImprimir: Etiqueta[] = [];
  bottomBarCodeWidth: number = 1.5;
  brand: string = "BY THOTHCOMPANY";
  idRastreio: number = 123;
  bottomBarCodeValue: string = "1234567891012";
  exibirBarCode: boolean = true;
  quantidadeEtiquetas: number = 2;
  progressbarCarregandoEtiqueta: boolean = false;
  arrFontSize: any[] = [];
  linhaSelecionada: number = 0;
  dadosCarregados: boolean = false;
  arrEtiquetaPersonalizada: EtiquetaPersonalizada[] = [];
  idVenda: any = 0;
  etiquetaPaddingTop: number = 0;
  arrEtiquetas: EtiquetaPersonalizada[] = [];
  etiquetaSelecionada: EtiquetaPersonalizada = {
    value: '1',
    label: "Padrão",
    largura: 119,
    altura: 50,
    itensPorPagina: 2
  }
  quantidadePorPagina: number = 2;
  idEtiquetaSelecionada: string = '1';

  dataEntregaSelecionada: any;
  mostrarDataEntrega: boolean = true;
  dataEmbalagem: string = '';
  mostrarDataEmbalagem: boolean = true;
  mostrarDescricaoEmbalagem: boolean = true;
  tempDescricaoEmbalagem: string[] = [];
  dataEntregaTemp: string = '';
  dataEmbalagemTemp: string = '';

  constructor(
    private route: ActivatedRoute,
    public httpRastreadorService: HttpRastreadorService,
    public adminGlobalService: AdminGlobalService,
    public vendaService: VendaService,
    public dialog: MatDialog,
  ) {
    this.adminGlobalService.setPageTitle("Imprimir Etiquetas");
    this.route.params.subscribe((params: any) => {
      if (params.idRastreio) {
        this.idVenda = this.route.snapshot.paramMap.get("idRastreio");
      } else {
        this.idVenda = 0;
      }
    });
    this.getListaEtiquetas();
  }
  ngOnInit() {
    try {
      if (localStorage.getItem("margemSuperiorEtiqueta") !== null) {
        this.etiquetaPaddingTop = Number(localStorage.getItem('margemSuperiorEtiqueta'));
      }
    } catch (error) {
    }
    for (let i = 1; i <= 30; i++) {
      this.arrFontSize.push(i);
    }
    if (this.idVenda != 0) {
      this.httpRastreadorService.getProdutoGerarEtiquetaPorVenda(this.idVenda).subscribe((dataEtiquetas: any) => {
        console.log('dataEtiquetas: ', dataEtiquetas)
        this.httpRastreadorService.getLoggedDataUser().subscribe((userData: any) => {
          this.userPerfil = userData[0];
          console.log('userPerfil: ', this.userPerfil);
          let abjEstado: Estado[] = this.adminGlobalService.arrEstados.filter(x => x.estado_id == this.userPerfil.user_estado);
          this.httpRastreadorService.getMunicipioById(this.userPerfil.user_cidade).subscribe((dataMunicipio: any) => {

            if (dataEtiquetas != '') {
              dataEtiquetas.map((data: any) => {
                console.log('data: ', data);
                //####################################################
                //####################################################
                //let plu:ProdutoPLU = data.produto_plu;
                //let arrPLU:ProdutoPLU[] = [];
                //arrPLU.push(plu);
                //####################################################
                let arrLinhaEtiqueta1: LinhaEtiqueta[] = [];
                let etiquetaLinha1: LinhaEtiqueta = {
                  texto: this.userPerfil.user_nome,
                  fontSize: 11,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha2: LinhaEtiqueta = {
                  texto: this.userPerfil.user_endereco + " " + this.userPerfil.user_numero,
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha3: LinhaEtiqueta = {
                  texto: this.userPerfil.user_bairro + " - " + dataMunicipio[0].municipio_nome + " - " + abjEstado[0].estado_uf,
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                var textoCnpjCpf = 'CNPJ: ';
                if (this.userPerfil.user_cnpj.length <= 11)
                  textoCnpjCpf = 'CPF: '
                let etiquetaLinha4: LinhaEtiqueta = {
                  texto: textoCnpjCpf + this.formatCnpjCpf(this.userPerfil.user_cnpj),
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                var pais = 'Brasil';
                if (data.pais) {
                  pais = data.pais
                }
                let etiquetaLinha5: LinhaEtiqueta = {
                  texto: "País/Origem: " + pais,
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha6: LinhaEtiqueta = {
                  texto: data.produto_nome,
                  fontSize: 13,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.centro
                };

                //#################################################################
                //Armazenando a descrição da embalagem em uma variável temporária.
                this.tempDescricaoEmbalagem.push(data.rastreio_embalagem_nome);

                let etiquetaLinha7: LinhaEtiqueta = {
                  texto: data.rastreio_embalagem_nome,
                  fontSize: 12,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.centro
                };

                var lote = 'LOTE: '
                if (data.lote) {
                  lote = 'LOTE: ' + data.lote;
                } else { lote = 'LOTE: '; }
                let etiquetaLinha8: LinhaEtiqueta = {
                  texto: lote,
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };

                let etiquetaLinha9: LinhaEtiqueta = {
                  texto: "DATA DE ENTREGA :",
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha10: LinhaEtiqueta = {
                  texto: "DATA DE EMBALAGEM :",
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha11: LinhaEtiqueta = {
                  texto: "tracker.thothcompany.com.br",
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha12: LinhaEtiqueta = {
                  texto: "Cod Rastreio : " + data.rastreio_id, //+ " ( " + data.rastreio_id_int + " )"
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                };
                let etiquetaLinha13: LinhaEtiqueta = {
                  texto: "tracker.thothcompany.com.br/rastrear/" + data.rastreio_id,
                  fontSize: 9,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                }
                let etiquetaLinha14: LinhaEtiqueta = {
                  texto: this.userPerfil.user_endereco_latitude + '⠀⠀⠀⠀' + this.userPerfil.user_endereco_longitude,
                  fontSize: 7,
                  negrito: true,
                  alinhamento: LinhaAlinhamento.esquerda
                }
                arrLinhaEtiqueta1.push(etiquetaLinha1);
                arrLinhaEtiqueta1.push(etiquetaLinha2);
                arrLinhaEtiqueta1.push(etiquetaLinha3);
                arrLinhaEtiqueta1.push(etiquetaLinha4);
                arrLinhaEtiqueta1.push(etiquetaLinha5);
                arrLinhaEtiqueta1.push(etiquetaLinha6);
                arrLinhaEtiqueta1.push(etiquetaLinha7);
                arrLinhaEtiqueta1.push(etiquetaLinha8);
                arrLinhaEtiqueta1.push(etiquetaLinha9);
                arrLinhaEtiqueta1.push(etiquetaLinha10);
                arrLinhaEtiqueta1.push(etiquetaLinha11);
                arrLinhaEtiqueta1.push(etiquetaLinha12);
                arrLinhaEtiqueta1.push(etiquetaLinha13);
                arrLinhaEtiqueta1.push(etiquetaLinha14);

                let etiqueta: Etiqueta = {
                  rastreio_id: data.rastreio_id,
                  rastreio_id_int: data.rastreio_id_int,
                  produto_nome: data.produto_nome,
                  produto_quantidade: data.produto_quantidade,
                  produto_unidade_medida: data.produto_unidade_medida,
                  produto_venda_produto_id: data.produto_venda_produto_id,
                  rastreio_embalagem_capacidade: data.rastreio_embalagem_capacidade,
                  rastreio_embalagem_nome: data.rastreio_embalagem_nome,
                  rastreio_embalagem_unidade_medida: data.rastreio_embalagem_unidade_medida,
                  bottom_bar_code: null,
                  produto_plu: data.produto_plu,
                  arrLinhaEtiqueta: arrLinhaEtiqueta1,
                  quantidade: data.produto_quantidade,
                  tipo_codigo_barras: '1',
                  codigo_barras: [],
                  quantidade_impressa: data.quantidade_impressa,
                  vezes_impresso: data.vezes_impresso
                }

                data.produto_plu.map(plu => {
                  etiquetaLinha6.texto = plu.venda_produto_plu_nome_produto_plu;
                  etiqueta.produto_nome = plu.venda_produto_plu_nome_produto_plu;
                  etiquetaLinha8.texto = "DATA DE ENTREGA: " + plu.venda_produto_plu_data_entrega_plu;
                  etiqueta.bottom_bar_code = plu.venda_produto_plu_codigo_barras_plu;
                  etiquetaLinha5.texto = "CÓDIGO DO FORNECEDOR: " + plu.venda_produto_plu_numero_fornecedor;
                  etiqueta.produto_plu[0].venda_produto_plu_tipo_codigo_barras_plu = plu.venda_produto_plu_tipo_codigo_barras_plu;
                });

                data.codigo_barras.map(barcode => {
                  let bc: CodigoBarras = {
                    codigo_barras: barcode.codigo_barras,
                    tipo_codigo_barras: barcode.tipo_codigo_barras
                  }
                  etiqueta.codigo_barras.push(bc);
                  etiqueta.bottom_bar_code = bc.codigo_barras;

                  if (bc.tipo_codigo_barras == 'EAN') {
                    etiqueta.tipo_codigo_barras = '2';
                  } else {
                    etiqueta.tipo_codigo_barras = '3';
                  }

                });
                this.arrEtiqueta.push(etiqueta);

                if (this.userPerfil.user_id_cliente == 34 || this.userPerfil.user_id_cliente == 35) {
                  if (this.arrEtiquetaPersonalizada[0].label == '(12cm x 5cm) x 2 Padrão') {
                    this.arrEtiquetaPersonalizada.splice(0, 1);
                  }
                } else if (this.userPerfil.user_id_cliente == 44) {
                  if (this.arrEtiquetaPersonalizada[0].label == '(12cm x 5cm) x 2 Padrão') {
                    this.arrEtiquetaPersonalizada.splice(0, 1);
                    this.idEtiquetaSelecionada = this.arrEtiquetaPersonalizada[0].value;
                    setTimeout(() => {
                      this.mudarEtiqueta(0);
                    }, 1000);
                  }
                }

                //####################################################
                //####################################################
              }, error => {
                this.dadosCarregados = false;
                this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
                this.progressbarCarregandoEtiqueta = false;
              }),

                this.gerarEtiquetas();
              this.dadosCarregados = true;
            }
            else {
              this.dadosCarregados = false;
              this.adminGlobalService.openSnackBar("Não foi possível encontrar este rastreio!", "fechar");
              this.progressbarCarregandoEtiqueta = false;
            }

          }, error => {
            this.dadosCarregados = false;
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
            this.progressbarCarregandoEtiqueta = false;
          });
        }, error => {
          this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
          this.progressbarCarregandoEtiqueta = false;
          this.dadosCarregados = false;
        });
      }, error => {
        this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
        this.progressbarCarregandoEtiqueta = false;
        this.dadosCarregados = false;
      });
    } else {
      this.httpRastreadorService.getProdutoGerarEtiquetaPorProduto(this.vendaService.arrProdutosImpressao).subscribe((dataEtiquetas: any) => {
        this.httpRastreadorService.getLoggedDataUser().subscribe((userData: any) => {
          this.userPerfil = userData[0];
          let abjEstado: Estado[] = this.adminGlobalService.arrEstados.filter(x => x.estado_id == this.userPerfil.user_estado);
          this.httpRastreadorService.getMunicipioById(this.userPerfil.user_cidade).subscribe((dataMunicipio: any) => {
            dataEtiquetas.map(data => {
              //####################################################
              //####################################################
              //let plu:ProdutoPLU = data.produto_plu;
              //let arrPLU:ProdutoPLU[] = [];
              //arrPLU.push(plu);
              //####################################################
              let arrLinhaEtiqueta1: LinhaEtiqueta[] = [];
              let etiquetaLinha1: LinhaEtiqueta = {
                texto: this.userPerfil.user_nome,
                fontSize: 15,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha2: LinhaEtiqueta = {
                texto: this.userPerfil.user_endereco + " " + this.userPerfil.user_numero,
                fontSize: 12,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha3: LinhaEtiqueta = {
                texto: this.userPerfil.user_bairro + " - " + dataMunicipio[0].municipio_nome + " - " + abjEstado[0].estado_uf,
                fontSize: 12,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha4: LinhaEtiqueta = {
                texto: "CNPJ: " + this.formatCnpjCpf(this.userPerfil.user_cnpj),
                fontSize: 12,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha5: LinhaEtiqueta = {
                texto: "",
                fontSize: 14,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha6: LinhaEtiqueta = {
                texto: data.produto_nome,
                fontSize: 20,
                negrito: true,
                alinhamento: LinhaAlinhamento.centro
              };
              let etiquetaLinha7: LinhaEtiqueta = {
                texto: data.rastreio_embalagem_nome,
                fontSize: 14,
                negrito: true,
                alinhamento: LinhaAlinhamento.centro
              };
              let etiquetaLinha8: LinhaEtiqueta = {
                texto: "DATA DE ENTREGA :",
                fontSize: 12,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha9: LinhaEtiqueta = {
                texto: "tracker.thothcompany.com.br",
                fontSize: 10,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha10: LinhaEtiqueta = {
                texto: "Cod Rastreio : " + data.rastreio_id, // + " ( " + data.rastreio_id_int + " )",
                fontSize: 11,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              };
              let etiquetaLinha11: LinhaEtiqueta = {
                texto: "tracker.thothcompany.com.br/rastrear/" + data.rastreio_id,
                fontSize: 11,
                negrito: true,
                alinhamento: LinhaAlinhamento.esquerda
              }
              arrLinhaEtiqueta1.push(etiquetaLinha1);
              arrLinhaEtiqueta1.push(etiquetaLinha2);
              arrLinhaEtiqueta1.push(etiquetaLinha3);
              arrLinhaEtiqueta1.push(etiquetaLinha4);
              arrLinhaEtiqueta1.push(etiquetaLinha5);
              arrLinhaEtiqueta1.push(etiquetaLinha6);
              arrLinhaEtiqueta1.push(etiquetaLinha7);
              arrLinhaEtiqueta1.push(etiquetaLinha8);
              arrLinhaEtiqueta1.push(etiquetaLinha9);
              arrLinhaEtiqueta1.push(etiquetaLinha10);
              arrLinhaEtiqueta1.push(etiquetaLinha11);

              let quantidadeImpressao = data.produto_quantidade;
              if (data.produto_quantidade > 1000) {
                quantidadeImpressao = 1000;
              }

              let etiqueta: Etiqueta = {
                rastreio_id: data.rastreio_id,
                rastreio_id_int: data.rastreio_id_int,
                produto_nome: data.produto_nome,
                produto_quantidade: data.produto_quantidade,
                produto_unidade_medida: data.produto_unidade_medida,
                produto_venda_produto_id: data.produto_venda_produto_id,
                rastreio_embalagem_capacidade: data.rastreio_embalagem_capacidade,
                rastreio_embalagem_nome: data.rastreio_embalagem_nome,
                rastreio_embalagem_unidade_medida: data.rastreio_embalagem_unidade_medida,
                bottom_bar_code: null,
                produto_plu: data.produto_plu,
                arrLinhaEtiqueta: arrLinhaEtiqueta1,
                quantidade: quantidadeImpressao,
                tipo_codigo_barras: '1',
                codigo_barras: [],
                quantidade_impressa: data.quantidade_impressa,
                vezes_impresso: data.vezes_impresso
              }
              data.produto_plu.map(plu => {
                etiquetaLinha6.texto = plu.venda_produto_plu_nome_produto_plu;
                etiqueta.produto_nome = plu.venda_produto_plu_nome_produto_plu;
                etiquetaLinha8.texto = "DATA DE ENTREGA: " + plu.venda_produto_plu_data_entrega_plu;
                etiqueta.bottom_bar_code = plu.venda_produto_plu_codigo_barras_plu;
                etiquetaLinha5.texto = "CÓDIGO DO FORNECEDOR: " + plu.venda_produto_plu_numero_fornecedor;
                etiqueta.produto_plu[0].venda_produto_plu_tipo_codigo_barras_plu = plu.venda_produto_plu_tipo_codigo_barras_plu;
              });
              data.codigo_barras.map(barcode => {
                let bc: CodigoBarras = {
                  codigo_barras: barcode.codigo_barras,
                  tipo_codigo_barras: barcode.tipo_codigo_barras
                }
                etiqueta.codigo_barras.push(bc);
                etiqueta.bottom_bar_code = bc.codigo_barras;

                if (bc.tipo_codigo_barras == 'EAN') {
                  etiqueta.tipo_codigo_barras = '2';
                } else {
                  etiqueta.tipo_codigo_barras = '3';
                }

              });
              this.arrEtiqueta.push(etiqueta);
              //####################################################
              //####################################################
            }, error => {

              this.dadosCarregados = false;
              this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
              this.progressbarCarregandoEtiqueta = false;
            });

            this.gerarEtiquetas();
            this.dadosCarregados = true;

            this.vendaService.arrProdutosImpressao = [];

          }, error => {

            this.dadosCarregados = false;
            this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
            this.progressbarCarregandoEtiqueta = false;

          });
        }, error => {

          this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
          this.progressbarCarregandoEtiqueta = false;
          this.dadosCarregados = false;

        });
      }, error => {
        console.error(error)
        this.adminGlobalService.openSnackBar("Houve um erro ao tentar buscar os dados referentes a este rastreio!", "fechar");
        this.progressbarCarregandoEtiqueta = false;
        this.dadosCarregados = false;
      });
    }
    //this.calcDigitoCodigoBarras('1789989140217');
  }
  getListaEtiquetas() {
    this.arrEtiquetaPersonalizada = [];
    let etP: EtiquetaPersonalizada = {
      value: '1',
      label: "(12cm x 5cm) x 2 Padrão",
      largura: 119,
      altura: 50,
      itensPorPagina: 2
    }
    this.arrEtiquetaPersonalizada.push(etP);
    this.httpRastreadorService.buscarEtiqueta().subscribe((data: any) => {
      data.map(d => {
        let larguraCm: number = d.largura / 10;
        let lalturaCm: number = d.altura / 10;
        let nome: string = "(" + larguraCm + "cm x " + lalturaCm + "cm) x " + d.itens_por_pagina + " - " + d.nome;
        let e: EtiquetaPersonalizada = {
          value: d.id.toString(),
          label: nome,
          largura: Number(d.largura),
          altura: Number(d.altura),
          itensPorPagina: d.itens_por_pagina
        }
        this.idEtiquetaSelecionada
        console.log('etiqueta sendo lançada: ', e)
        this.arrEtiquetaPersonalizada.push(e);
        this.getAlturaSelecionada();
        this.getLarguraSelecionada();
      });
    });
  }

  setEtiqueta(etiqueta: any) {
    console.log(JSON.stringify(etiqueta));
    /*     this.etiquetaSelecionada = etiqueta;
        console.log("ETIQUETA: " + etiqueta.itensPorPagina);
        this.quantidadePorPagina = Number(etiqueta.itensPorPagina); */
  }

  checkIsPar(numero: any) {
    let resultado = numero % 2;
    if (resultado == 0) {
      return true;
    } else {
      return false;
    }
  }

  checkDivisivelPorQuatro(numero: any) {
    return numero % 4 === 0;
  }

  checkDivisivelPorTres(numero: any) {
    return numero % 3 === 0;
  }

  checkDivisivel(numero) {
    // Imprime o numero de etiquetas por página definido na criação da etiqueta
    return numero % this.quantidadePorPagina === 0;
  }


  public formatCnpjCpf(value) {
    const cnpjCpf = value.replace(/\D/g, '');
    if (cnpjCpf.length === 11) {
      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    }
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
  }
  setLinhaSelecionada(linha) {
    this.linhaSelecionada = linha;
  }
  setNegrito(indexEtiqueta, indexLinha, negrito) {
    this.arrEtiqueta[indexEtiqueta].arrLinhaEtiqueta[indexLinha].negrito = !negrito;
  }
  addLinha(indexEtiqueta) {
    let etiquetaLinha: LinhaEtiqueta = {
      texto: "",
      fontSize: 12,
      negrito: false,
      alinhamento: LinhaAlinhamento.esquerda
    };
    this.arrEtiqueta[indexEtiqueta].arrLinhaEtiqueta.push(etiquetaLinha)
  }

  gerarEtiquetas() {
    this.arrEtiquetasImprimir.length = 0;
    this.arrEtiqueta.map(d => {

      for (let i = 0; i < d.quantidade; i++) {
        this.arrEtiquetasImprimir.push(d);
      }

      try {
        if (this.arrEtiquetasImprimir[0].codigo_barras[0].tipo_codigo_barras == "EAN") {
          this.codigoDeBarraAdicionado();
        }
      } catch (e) {

      }
    });
  }
  getTotalEtiquetasImpressas() {
    let t = 0;
    this.arrEtiqueta.map(d => {
      t = t + d.quantidade;
    });
    return t;
  }
  calcDigitoCodigoBarras(numero: string) {
    let digito: any;
    let factor: number = 3;
    let sum: number = 0;
    for (let index: number = numero.length; index > 0; --index) {
      sum = sum + Number(numero.substring(index - 1, index)) * factor;
      factor = 4 - factor;
    }
    digito = ((1000 - sum) % 10);
    if (numero.length > 17) {
      digito = "";
      return false;
    }
    if (((((numero.length != 7) && (numero.length != 11)) && (numero.length != 12)) && (numero.length != 13)) && (numero.length != 17)) {
      digito = "";
      //alert("O número deve possuir 7 (EAN/UCC-8), 11 (UCC-12), 12 (EAN/UCC-13), 13 (EAN/UCC-14) ou 17 (SSCC) dígitos");
      return false;
    }
    return digito;
  }
  getTipoBarCode(tipo: number, tipoPLU: string) {
    if (tipo == 1) {
      //Padrão da importação do PLU
      if (tipoPLU === 'EAN') {
        return 'EAN';
      } else {
        return 'DUN';
      }
    } else if (tipo == 2) {
      return 'EAN';
    } else if (tipo == 3) {
      return 'DUN';
    } else {
      return 'OUTRO';
    }
  }
  getBarcode(barcode: string, tipo: number, tipoPLU: string) {
    if (barcode != null) {
      if (tipo == 1) {
        //Padrão da importação do PLU
        if (tipoPLU === 'EAN') {
          return barcode.substr(1);
        } else {
          return barcode;
        }
      } else if (tipo == 2) {
        //ALTERAÇÃO PARA CÓDIGO EAN
        if (tipoPLU === 'EAN') {
          return barcode.substr(1);
        } else {

          let removerPrimeiroDigito = barcode.substr(1);
          let removerUltimoDigito = removerPrimeiroDigito.substring(0, removerPrimeiroDigito.length - 1);
          return removerUltimoDigito + this.calcDigitoCodigoBarras(removerUltimoDigito);
        }
      } else if (tipo == 3) {
        return barcode;
        //ALTERAÇÃO PARA CÓDIGO DUN
      } else {
        return barcode;
      }

    } else {
      return 0;
    }
  }
  quandoMargemSuperiorMuda(event: MatSliderChange) {
    localStorage.setItem("margemSuperiorEtiqueta", String(event.value));
  }
  openDialogAdicionarProduto() {
    const dialogRef = this.dialog.open(CriarEtiquetaPersonalizadaComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined && result !== false) {
        this.getListaEtiquetas();
      }
    });
  }
  getLarguraSelecionada(): any {
    let etiqueta: any = this.arrEtiquetaPersonalizada.find(x => x.value === this.idEtiquetaSelecionada);
    try {
      if (this.quantidadePorPagina != etiqueta.itensPorPagina) {
        this.quantidadePorPagina = etiqueta.itensPorPagina;
      }
      return etiqueta.largura;
    } catch {
      return this.arrEtiquetaPersonalizada[0].largura;
    }
  }
  getAlturaSelecionada(): any {
    let etiqueta: any = this.arrEtiquetaPersonalizada.find(x => x.value === this.idEtiquetaSelecionada);
    try {
      return etiqueta.altura;
    } catch {
      return this.arrEtiquetaPersonalizada[0].altura;
    }
  }
  dateChange(dataSelecionada: MatDatepickerInputEvent<Date>) {

    let mes = Number(dataSelecionada.value.getMonth()) + 1;

    this.mostrarDataEntrega = true;

    this.dataEntregaTemp = "DATA DE ENTREGA : " + dataSelecionada.value.getDate() + "/" + mes + "/" + dataSelecionada.value.getFullYear();

    this.arrEtiqueta.map(d => {
      d.arrLinhaEtiqueta.map((l, index) => {
        if (index == 8) {
          l.texto = "DATA DE ENTREGA : " + dataSelecionada.value.getDate() + "/" + mes + "/" + dataSelecionada.value.getFullYear();
        }
      });
    });


    /* 
        console.log("Dia : " + dataSelecionada.value.getDate());
        console.log("Mês : " + dataSelecionada.value.getMonth());
        console.log("Mês : " + dataSelecionada.value.getFullYear()); */
  }

  dateChangeEmbalagem(dataSelecionada: MatDatepickerInputEvent<Date>) {
    let mes = Number(dataSelecionada.value.getMonth()) + 1;

    this.mostrarDataEmbalagem = true;

    this.dataEmbalagemTemp = "DATA DE EMBALAGEM : " + dataSelecionada.value.getDate() + "/" + mes + "/" + dataSelecionada.value.getFullYear();

    this.arrEtiqueta.map(d => {
      d.arrLinhaEtiqueta.map((l, index) => {
        if (index == 9) {
          l.texto = "DATA DE EMBALAGEM : " + dataSelecionada.value.getDate() + "/" + mes + "/" + dataSelecionada.value.getFullYear();
        }
      });
    });
  }

  criarImpressaoEtiqueta() {

    let arrItens: any[] = [];

    this.arrEtiqueta.map(d => {

      d.vezes_impresso = Number(d.vezes_impresso) + 1;
      d.quantidade_impressa = Number(d.quantidade_impressa) + Number(d.quantidade);

      let a: any = {
        'id_venda_produto': d.produto_venda_produto_id,
        'quantidade_impressa': d.quantidade
      }
      arrItens.push(a);

    });
    this.httpRastreadorService.criarImpressaoEtiqueta(arrItens).subscribe(d => {
      console.log("d : " + JSON.stringify(d));
    });


  }
  mudarEtiqueta(indexE: any) {

    console.log(this.getLarguraSelecionada());

    // Padrão 4x6 ou menor
    if (this.getLarguraSelecionada() <= 60 && this.getAlturaSelecionada() <= 40) {
      this.arrEtiquetasImprimir.map((e) => {
        e.arrLinhaEtiqueta.map((d, i) => {
          if (i == 0) {
            d.fontSize = 7;
          } else if (i == 1 || i == 2 || i == 3 || i == 4) {
            d.fontSize = 6;
          } else if (i == 5) {
            d.fontSize = 9;
          } else if (i == 6) {
            d.fontSize = 7;
          } else if (i == 7 || i == 11) {
            d.fontSize = 7;
          } else if (i == 9) {
            d.fontSize = 5;
          } else if (i == 10 || i == 8) {
            d.fontSize = 6;
          } else if (i == 12 || i == 13) {
            d.fontSize = 0;
          }
        });
      });

      // Padrão 4x6 ou maior
    } else if (this.getLarguraSelecionada() <= 60 && this.getAlturaSelecionada() > 40) {
      this.arrEtiquetasImprimir.map((e) => {
        e.arrLinhaEtiqueta.map((d, i) => {
          if (i == 0) {
            d.fontSize = 8;
          } else if (i == 1 || i == 2 || i == 3 || i == 4) {
            d.fontSize = 7;
          } else if (i == 5) {
            d.fontSize = 9;
          } else if (i == 6) {
            d.fontSize = 8;
          } else if (i == 10) {
            d.fontSize = 7;
          } else if (i == 7 || i == 11) {
            d.fontSize = 10;
          } else if (i == 12 || i == 13) {
            d.fontSize = 5;
          } else if (i == 9 || i == 8) {
            d.fontSize = 7;
          }
        });
      });
    } else {
      try {
        if (localStorage.getItem("margemSuperiorEtiqueta") !== null) {
          this.etiquetaPaddingTop = Number(localStorage.getItem('margemSuperiorEtiqueta'));
        }
      } catch (error) {
      }


      this.arrEtiquetasImprimir.map((e) => {
        e.arrLinhaEtiqueta.map((d, i) => {
          if (i == 0) {
            d.fontSize = 11;
          } else if (i == 1 || i == 2 || i == 3 || i == 4) {
            d.fontSize = 9;
          } else if (i == 5) {
            d.fontSize = 13;
          } else if (i == 6) {
            d.fontSize = 12;
          } else if (i == 7 || i == 8 || i == 9 || i == 10 || i == 11 || i == 12) {
            d.fontSize = 9;
          } else if (i == 13) {
            d.fontSize = 7;
          }
        });
      });
    }

    if (this.arrEtiquetasImprimir[0].tipo_codigo_barras != "1") {
      this.codigoDeBarraAdicionado();
    }

  }

  codigoDeBarraAdicionado() {
    if (this.getLarguraSelecionada() <= 60) {
      this.arrEtiquetasImprimir.map((e) => {
        e.arrLinhaEtiqueta.map((d, i) => {
          if (i == 0) {
            d.fontSize = 7;
          } else if (i == 1 || i == 2 || i == 3 || i == 4) {
            d.fontSize = 6;
          } else if (i == 5) {
            d.fontSize = 8;
          } else if (i == 6) {
            d.fontSize = 7;
          } else if (i == 7 || i == 8 || i == 9 || i == 10 || i == 11 || i == 12) {
            d.fontSize = 6;
          } else if (i == 13) {
            d.fontSize = 0;
          }
        });
      });
    }
  }

  removerHabilitarDataEntrega() {
    if (!this.mostrarDataEntrega) {
      this.arrEtiqueta.map(d => {
        d.arrLinhaEtiqueta.map((l, index) => {
          if (index == 7) {
            l.texto = "";
          }
        });
      });
    } else {
      this.arrEtiqueta.map(d => {
        d.arrLinhaEtiqueta.map((l, index) => {
          if (index == 7) {
            l.texto = this.dataEntregaTemp;
          }
        });
      });
    }
  }

  removerHabilitarDataEmbalagem() {
    if (!this.mostrarDataEmbalagem) {
      this.arrEtiqueta.map(d => {
        d.arrLinhaEtiqueta.map((l, index) => {
          if (index == 8) {
            l.texto = "";
          }
        });
      });
    } else {
      this.arrEtiqueta.map(d => {
        d.arrLinhaEtiqueta.map((l, index) => {
          if (index == 8) {
            l.texto = this.dataEmbalagemTemp;
          }
        });
      });
    }
  }

  removerDescricaoEmbalagem() {
    if (this.mostrarDescricaoEmbalagem) {
      this.arrEtiqueta.map((etiqueta, index) => {
        etiqueta.arrLinhaEtiqueta[6].texto = this.tempDescricaoEmbalagem[index];
      });
    } else {
      this.arrEtiqueta.map((etiqueta, index) => {

        etiqueta.arrLinhaEtiqueta[6].texto = etiqueta.rastreio_embalagem_capacidade + "KG";

      });
    }
  }

}