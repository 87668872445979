import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { FornecedorCompra } from 'src/app/model/compra.model';
import { EditarFornecedorCompraProdutoComponent } from '../editar-fornecedor-compra-produto/editar-fornecedor-compra-produto.component';
import { HttpAdminService } from '../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';

@Component({
  selector: 'app-ver-fornecedores-compra-produto',
  templateUrl: './ver-fornecedores-compra-produto.component.html',
  styleUrls: ['./ver-fornecedores-compra-produto.component.css']
})
export class VerFornecedoresCompraProdutoComponent implements OnInit {

  constructor(
    public adminGlobalService:AdminGlobalService,
    public httpAdminService:HttpAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VerFornecedoresCompraProdutoComponent>,
    @Inject(MAT_DIALOG_DATA) public fornecedor_compra:FornecedorCompra[]
  ) { }

  ngOnInit() {
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close();
  }
  pesoTotal(fornecedor_compra:FornecedorCompra){
    return Number(fornecedor_compra.quantidade_produto) * Number(fornecedor_compra.peso_individual);
  }
  openEditNFCompra(index){
    const dialogRef = this.dialog.open(EditarFornecedorCompraProdutoComponent, {
      width: '560px',
      data: this.fornecedor_compra[index].nota_fiscal_compra
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false && result != undefined){
        this.fornecedor_compra[index].nota_fiscal_compra = result;
        this.salvarDadosAtualizacao(this.fornecedor_compra[index].id, result);
      }
    });
  }
  salvarDadosAtualizacao(id_compra_produto_fornecedor:number, nf:string){
    this.httpAdminService.atualizarNFCompraProduto(id_compra_produto_fornecedor, nf).subscribe(d=>{
      this.adminGlobalService.openSnackBar("Nota Fiscal Atualizada com sucesso!", "fechar");
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível atualizar os dados, verifique sua conexão com a internet!", "fechar");
    });
  }



}
