import { ConfirmDialogComponent } from 'src/app/admin/admin-home/confirm-dialog/confirm-dialog.component';
import { Produto } from 'src/app/model/produto.model';
import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';

import { Component, OnInit } from '@angular/core';
import { AdminGlobalService } from '../services/admin-global.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';

import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { Venda, VendaProduto } from 'src/app/model/venda.model';

import { Fornecedor } from 'src/app/model/fornecedor.model';
import { Cliente } from 'src/app/model/cliente.model';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompraVendaAddProdutoDialogComponent } from '../compra-venda-add-produto-dialog/compra-venda-add-produto-dialog.component';
import { VendaService } from 'src/app/admin/admin-home/services/venda.service';
import {formatDate } from '@angular/common';
import { CompraVendaAtualizarProdutoDialogComponent } from '../compra-venda-atualizar-produto-dialog/compra-venda-atualizar-produto-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { BuscarClientesDialogComponent } from '../buscar-clientes-dialog/buscar-clientes-dialog.component';
import { Embalagem } from 'src/app/model/embalagem.model';
import { RemoverEmbalagemComponent } from '../remover-embalagem/remover-embalagem.component';
import { CadastrarEmbalagemComponent } from '../cadastrar-embalagem/cadastrar-embalagem.component';
import { EditarEmbalagemComponent } from '../editar-embalagem/editar-embalagem.component';
import { HttpAdminService } from '../services/http-admin.service';
import { BuscarProdutoEstoqueDialogComponent } from '../buscar-produto-estoque-dialog/buscar-produto-estoque-dialog.component';
import { ProdutoEstoque, NovaVenda, PLUData, EmbalagemEstoque } from 'src/app/teste/teste/lista.model';
import { UploadArquivoPluComponent, PluDados } from '../upload-arquivo-plu/upload-arquivo-plu.component';

@Component({
  selector: 'app-nova-venda',
  templateUrl: './nova-venda.component.html',
  styleUrls: ['./nova-venda.component.css']
})
export class NovaVendaComponent implements OnInit {

  today:number = Date.now();
  jstoday = '';

  progressbarDadosCompra:boolean = false;
  progressbarFinalizar:boolean = false;
  progressbarClientes:boolean = false;
  termoBuscaCliente:string = '';
  fornecedorSelecionado:boolean = false;
  showResultadoFornecedor:boolean = false;

  arrEmbalagens:Embalagem[] = [];
  arrEmbalagensEstoque:EmbalagemEstoque[] = [];

  pluImportado:boolean = false;

  cliente:Cliente = {
    cliente_id:null,
    cliente_razao_social:null,
    cliente_nome:null,
    cliente_cnpj: null,
    cliente_inscricao_estadual:null,
    cliente_endereco_longitude:null,
    cliente_endereco_latitude:null,
    cliente_pais: null,
    cliente_estado: null,
    cliente_cidade: null,
    cliente_bairro: null,
    cliente_endereco: null,
    cliente_numero: null,
    cliente_complemento: null,
    cliente_observacao: null,
    cliente_cep: null,
    cliente_tipoPessoa: null
  };

  idCompra:any = null;

  arrProduto:ProdutoEstoque[] = [];

  plu_data:PLUData = {
    plu_id_pedido:"",
    plu_data_entrega:"",
    plu_numero_fornecedor:""
  }
  novaVenda:NovaVenda = {
    notaFiscal:null,
    nPedido:null,
    cliente:null,
    arrProduto:null,
    plu_data:this.plu_data
  }
  emailFormControl:any;

  constructor(
    private router: Router,
    public vendaService:VendaService,
    public dialog: MatDialog,
    public adminGlobalService:AdminGlobalService,
    private _formBuilder: FormBuilder,
    public httpRastreadorService:HttpRastreadorService,
    public httpAdminService:HttpAdminService,
    private route: ActivatedRoute
    ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Nova venda');
    //#####################################################


    this.emailFormControl = new FormControl('', [
      Validators.required
    ]);

    this.idCompra = this.route.snapshot.paramMap.get("idCompra");



  }
  ngOnInit() {
    this.getListaEmbalagens();
/*       try {
        if (localStorage.getItem("novaVenda") !== null) {
          this.novaVenda = JSON.parse(localStorage.getItem('novaVenda'));
          this.arrProduto = this.novaVenda.arrProduto;
          this.cliente = this.novaVenda.cliente;
        }
      } catch (error) {
      } */
  }
  openDialogBuscarCliente():void{
    const dialogRef = this.dialog.open(BuscarClientesDialogComponent, {
      width: '600px',
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      if( result!=undefined && result !=false ){
        this.cliente = result;
        this.adminGlobalService.openSnackBar("Cliente selecionado com sucesso!", "fechar");
        this.salvarStorage();
      }
    });
  }
  openDialogBuscarProduto(){
    const dialogRef = this.dialog.open(BuscarProdutoEstoqueDialogComponent, {
      width: '600px',
      data: this.arrProduto
    });
    dialogRef.afterClosed().subscribe((result:any) => {

      let embalagem:Embalagem = {
        embalagem_id:"0",
        embalagem_nome:"----",
        embalagem_capacidade:0,
        embalagem_unidade_medida:0,
        label:null
      }

      if( result!=undefined && result !=false ){
        let prod:ProdutoEstoque = {
          estoque:result.estoque,
          quantidade_venda:null,
          id_lote:result.id_lote,
          nota_fiscal:result.nota_fiscal,
          id_produto:result.id_produto,
          imagem_produto:result.imagem_produto,
          peso_individual:result.peso_individual,
          produto_nome:result.produto_nome,
          rastreio_id:result.rastreio_id,
          unidade_medida_compra:result.unidade_medida_compra,
          fornecedor_nome:result.fornecedor_nome,
          embalagem:embalagem,
          id_compra_produto:result.id_compra_produto,
          plu_importado:false,
          plu_produto:null
        }
        this.arrProduto.unshift(prod);
        this.setEmbalagem(0, result.id_embalagem);
        this.arrProduto[0].quantidade_venda = result.quantidade_produto;
        this.adminGlobalService.openSnackBar("Produto Adicionado ao Carrinho!", "fechar");
        this.salvarStorage();
      }
    });
  }
  removerCliente(){
    this.showResultadoFornecedor = true;
    this.fornecedorSelecionado = false;
    this.cliente = {
      cliente_id:0,
      cliente_razao_social: "",
      cliente_nome:"",
      cliente_cnpj: "",
      cliente_inscricao_estadual: "",
      cliente_endereco_longitude: "",
      cliente_endereco_latitude: "",
      cliente_pais: "",
      cliente_estado: "",
      cliente_cidade: "",
      cliente_bairro:"",
      cliente_endereco: "",
      cliente_numero: "",
      cliente_complemento: "",
      cliente_observacao: "",
      cliente_cep: "",
      cliente_tipoPessoa: ""
    };
  }
  openDialogAdicionarProduto(){
    const dialogRef = this.dialog.open(CompraVendaAddProdutoDialogComponent, {
      width: '600px',
      data: {tipo:"venda"}
    });
    dialogRef.afterClosed().subscribe((result:Produto) => {

    });
  }
  getTotalIndividual(index){
    return this.vendaService.venda.venda_produtos[index].venda_produto_quantidade * this.vendaService.venda.venda_produtos[index].produto_vendido_valor;
  }
  getTotal(){
    let total = 0;
    this.vendaService.venda.venda_produtos.map((produto,index)=>{
      total += this.getTotalIndividual(index);
    });
    return total;
  }
  removerProduto(index){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover produto', 'pergunta':'Você realmente deseja remover este produto?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.arrProduto.splice(index, 1);
        this.adminGlobalService.openSnackBar("Produto removido!", "fechar");
        this.salvarStorage();
      }
    });
  }
  removerPluProduto(index){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover PLU', 'pergunta':'Você realmente deseja remover este vinculo?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.arrProduto[index].plu_importado = false;
        this.arrProduto[index].plu_produto = null;
        this.adminGlobalService.openSnackBar("Removido com sucesso!", "fechar");
        this.salvarStorage();
      }
    });
  }
  removetTodosProdutos(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Remover todos os produtos', 'pergunta':'Você realmente deseja remover todos os produtos?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
      this.vendaService.venda.venda_produtos = [];
      this.vendaService.openSnackBar("Todos os produtos foram removidos!", "fechar");
      this.vendaService.salvarStorage();
      }
    });
  }
  atualizaProduto(vendaProduto:VendaProduto, index:any){
    const dialogRef = this.dialog.open(CompraVendaAtualizarProdutoDialogComponent, {
      width: '600px',
      data: {'vendaProduto':vendaProduto, 'index':index, 'tipo':'venda'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
    });
  }
  finalizarVenda(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      data: {'titulo':'Finalizar Venda', 'pergunta':'Você realmente deseja finalizar esta venda?'}
    });
    dialogRef.afterClosed().subscribe((result:boolean) => {
      if(result){
        this.progressbarFinalizar = true;
        this.httpRastreadorService.salvarNovaVenda(this.novaVenda).subscribe((d:any)=>{
          this.progressbarFinalizar = false;
          if(d.status){

            this.router.navigate(['/admin/geraretiqueta/'+d.id_venda]);


            this.adminGlobalService.openSnackBar("Venda realizada com sucesso", "fechar");
            this.novaVenda.notaFiscal = null;
            this.novaVenda.nPedido = null;
            this.cliente = {
              cliente_id:null,
              cliente_razao_social:null,
              cliente_nome:null,
              cliente_cnpj: null,
              cliente_inscricao_estadual:null,
              cliente_endereco_longitude:null,
              cliente_endereco_latitude:null,
              cliente_pais: null,
              cliente_estado: null,
              cliente_cidade: null,
              cliente_bairro: null,
              cliente_endereco: null,
              cliente_numero: null,
              cliente_complemento: null,
              cliente_observacao: null,
              cliente_cep: null,
              cliente_tipoPessoa: null
            };
            this.arrProduto = [];
          }else{
            this.progressbarFinalizar = false;
            this.adminGlobalService.openSnackBar("Venda realizada com sucesso!", "fechar");
          }
        }, error=>{
          this.adminGlobalService.openSnackBar("Não foi possível salvar sua venda, por favor tente novamente em instantes!", "fechar");
          this.progressbarFinalizar = false;
        });
      }
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível salvar sua venda, por favor tente novamente em instantes!", "fechar");
      this.progressbarFinalizar = false;
    });
  }
  cadastrarEmbalagem(index){
    const dialogRef = this.dialog.open(CadastrarEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        this.arrEmbalagens.push(result);
        //this.vendaSimples.venda_produtos[index].produto_embalagem = result;
      }
    });
  }
  atualizarEmbalagem(){
    const dialogRef = this.dialog.open(EditarEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result != false){
        
        
      }
    });
  }
  removerEmbalagem(){
    const dialogRef = this.dialog.open(RemoverEmbalagemComponent, {
      width: '540px',
      data: {},
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getListaEmbalagens();
    });
  }
  getListaEmbalagens(){
    this.httpAdminService.getListaEmbalagem().subscribe((data:any)=>{

      this.arrEmbalagens = data;

      if(this.idCompra != null){
        this.progressbarDadosCompra = true;
        this.httpRastreadorService.getProdutoEstoquePorCompra(this.idCompra).subscribe((d:any)=>{

          d.map(result=>{
            let embalagem:Embalagem = {
              embalagem_id:"0",
              embalagem_nome:"----",
              embalagem_capacidade:0,
              embalagem_unidade_medida:0,
              label:null
            }
            let prod:ProdutoEstoque = {
              estoque:result.estoque,
              quantidade_venda:null,
              id_lote:result.id_lote,
              nota_fiscal:result.nota_fiscal,
              id_produto:result.id_produto,
              imagem_produto:result.imagem_produto,
              peso_individual:result.peso_individual,
              produto_nome:result.produto_nome,
              rastreio_id:result.rastreio_id,
              unidade_medida_compra:result.unidade_medida_compra,
              fornecedor_nome:result.fornecedor_nome,
              embalagem:embalagem,
              id_compra_produto:result.id_compra_produto,
              plu_importado:false,
              plu_produto:null
            }
            this.arrProduto.unshift(prod);
            this.setEmbalagem(0, result.id_embalagem);
            this.arrProduto[0].quantidade_venda = result.quantidade_produto;
          });
          this.openDialogBuscarCliente();
          this.progressbarDadosCompra = false;
        });
      }
    }, error=>{
      this.adminGlobalService.openSnackBar("Não foi possível obter a lista de embalagens para os produtos, por favor verifique sua conexão com a internet", "fechar");
    });



  }




  salvarStorage(){
    this.novaVenda.arrProduto = this.arrProduto;
    this.novaVenda.cliente = this.cliente;
    localStorage.setItem('novaVenda', JSON.stringify(this.novaVenda));
  }
  checkValor(index:any){
    if(this.calcularPeso(this.arrProduto[index].quantidade_venda,this.arrProduto[index].embalagem.embalagem_capacidade) > this.arrProduto[index].estoque){
      //this.arrProduto[index].quantidade_venda = Math.floor(this.arrProduto[index].estoque / this.arrProduto[index].embalagem.embalagem_capacidade);
      this.adminGlobalService.openSnackBar("Quantidade informada maior que o presente no estoque!", "fechar");
    }
    if(this.arrProduto[index].quantidade_venda == null){
      this.arrProduto[index].quantidade_venda = 1;
    }
    if(this.arrProduto[index].quantidade_venda == 0){
      this.arrProduto[index].quantidade_venda = 1;
      this.adminGlobalService.openSnackBar("Você não pode informar um valor menor que 1!", "fechar");
    }
    this.salvarStorage();
  }
  verificaQuantidade(index:any){
    if(this.calcularPeso(this.arrProduto[index].quantidade_venda,this.arrProduto[index].embalagem.embalagem_capacidade) > this.arrProduto[index].estoque){
      return false;
    }else{
      return true;
    }
  }
  atualizarNF(){
    this.salvarStorage();
  }
  checkQuantidadeProduto():boolean{
    let count = 0;
    this.arrProduto.map(data=>{
      if(data.quantidade_venda === 0 || data.quantidade_venda === null || data.quantidade_venda === undefined){
        count++;
      }
    });
    if(count==0){
      return true;
    }else{
      return false;
    }
  }
  checkEmbalagemSelecionada():boolean{
    let count = 0;
    this.arrProduto.map(data=>{
      if(data.embalagem === undefined || data.embalagem === null){
        count++;
      }else{
        if(data.embalagem.embalagem_id == "0"){
          count++;
        }
      }
    });
    if(count==0){
      return false;
    }else{
      return true;
    }

  }
  checkErroVenda(){
    let erro:number = 0;
    if(!this.checkQuantidadeProduto()){
      erro = 1;
    }
    if(this.checkEmbalagemSelecionada()){
      erro = 2;
    }
    if(this.arrProduto.length==0){
      erro = 3;
    }
    if(this.cliente.cliente_id === null){
      erro = 4;
    }
    if(this.checkPLUImportado() == true){
      erro = 5;
    }

    if(erro==0){
      return false;
    }else{
      return true;
    }
  }
  setEmbalagem(index:number, embalagemId:any){
    console.log("embalagemId : " + JSON.stringify(embalagemId));
    this.arrEmbalagens.map(d=>{
      if(d.embalagem_id === embalagemId){
        this.arrProduto[index].embalagem.embalagem_nome = d.embalagem_nome;
        this.arrProduto[index].embalagem.embalagem_capacidade = d.embalagem_capacidade;
        this.arrProduto[index].embalagem.embalagem_unidade_medida = d.embalagem_unidade_medida;
        this.arrProduto[index].embalagem.embalagem_id = d.embalagem_id;
        this.checkValor(index);
        this.salvarStorage();
      }
    });
  }
  numberOnly(event): boolean {
    try {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }

  }
  calcularPeso(quantidade:any, pesoIndividual:any){
    return quantidade * pesoIndividual;
  }
  openDialogImportarPLU(){
    const dialogRef = this.dialog.open(UploadArquivoPluComponent, {
      width: '700px',
      disableClose:true,
      data:this.novaVenda
    });
    dialogRef.afterClosed().subscribe((result:NovaVenda) => {
      try {
          if(result != undefined && result != null ){
            this.novaVenda = result;
            this.pluImportado = true;
          }
      } catch (error) {
        
      }
    });
  }
  checkPLUImportado(){

    let countImportado:number = 0; 
    let countNaoImportado:number = 0;

    try {
      this.novaVenda.arrProduto.map(d=>{
        if(d.plu_importado){
          countImportado++;
        }else{
          countNaoImportado++;
        }
      });
      
    } catch (error) {
      

    }


    if(countImportado == 0){
      return false;
    }else{
      if(countNaoImportado == 0){
        return false;
      }{
        return true;
      }
    }
  }

}
