import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-editar-embalagem',
  templateUrl: './editar-embalagem.component.html',
  styleUrls: ['./editar-embalagem.component.css']
})
export class EditarEmbalagemComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditarEmbalagemComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }
}
