import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { HttpAdminService } from '../services/http-admin.service';
import { AdminGlobalService } from '../services/admin-global.service';
import { Embalagem } from 'src/app/model/embalagem.model';

@Component({
  selector: 'app-cadastrar-embalagem',
  templateUrl: './cadastrar-embalagem.component.html',
  styleUrls: ['./cadastrar-embalagem.component.css']
})
export class CadastrarEmbalagemComponent implements OnInit {

  
  arrUnidMedida:any = [
    {value:1,label:"KG", checked:true},
    {value:2,label:"UN", checked:false},
    {value:3,label:"L", checked:false}
  ];

  nomeEmbalagem:string = "";
  unidMedidaSelecionado:any = 2;

  embalagem:Embalagem;
  capacidadeEmbalagem:number;
  progressBar:boolean = false;

  constructor(
    public adminGlobalService:AdminGlobalService,
    public httpAdminService:HttpAdminService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CadastrarEmbalagemComponent>,
    @Inject(MAT_DIALOG_DATA) public compra:any
  ) { }

  ngOnInit() {
  }
  onNoClick(status:boolean): void {
    this.dialogRef.close(status);
  }
  cadastrarEmbalagem(){
    this.embalagem = {
      embalagem_id:null,
      embalagem_nome:this.nomeEmbalagem,
      embalagem_capacidade:this.capacidadeEmbalagem,
      embalagem_unidade_medida:this.unidMedidaSelecionado,
      label:null
    }
    this.progressBar = true;
    this.httpAdminService.criarNovaEmbalagem(this.embalagem).subscribe((data:any)=>{
      this.progressBar = false;
      if(data.status == true){
        this.embalagem.embalagem_id = data.id_embalagem;
        this.adminGlobalService.openSnackBar("Embalagem criada com sucesso!", "fechar");
        this.dialogRef.close(this.embalagem);
        
      }
    }, error=>{
      this.progressBar = false;
    });
  }

  checkForm(){
    if(this.nomeEmbalagem != "" && this.capacidadeEmbalagem > 0){
      return true;
    }else{
      return false;
    }
  }

  numberOnly(event): boolean {
    try {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }

  }
}
