import { HttpRastreadorService } from 'src/app/admin/admin-home/services/http-rastreador.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute,Router,NavigationEnd } from "@angular/router";
import { AdminGlobalService } from '../services/admin-global.service';
import { Compra, CompraProduto } from 'src/app/model/compra.model';
import { Location } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out', style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('0.3s ease-in', style({opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class CompraComponent implements OnInit {

  arrCompra:Compra[] = [];

  orderAsc = 'ASC';
  orderDesc = 'DESC';
  order:any = 'DESC';
  orderBy:any = 'id';
  itensPorPagina:string = '10';
  paginaAtual:any = 1;
  ultimaPagina:any = 1;

  termoBusca:string = '';

  paginasAnteriores:any[] = [];
  paginasProximas:any[] = [];

  linksPorPagina:any = 3;
  exibirPaginacao:boolean = false;

  buscaRealizada:boolean = false;
  progressbarAtivo:boolean = false;
  totalCompra:any;
  showFabButton:boolean = false;
  picker:any;
  date:any;
  dateRangeDisp:any
  tipoFiltro:string = "padrao";

  dataBuscaInicial:string = "";
  dataBuscaFinal:string = "";

  requestAtiva:boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public httpRastreadorService:HttpRastreadorService,
    public adminGlobalService:AdminGlobalService,
    private location: Location
    ) {
    //#####################################################
    //Set Titulo da página com o serviço compartilhado.
    this.adminGlobalService.setPageTitle('Compra');
    //#####################################################
    let current = new Date();
    let dataFinal = new Date(current.getTime() + 86400000).toISOString().slice(0, 10);
    let dataInicial = new Date().toISOString().slice(0, 10);

    let final = dataFinal.split("-");
    let inicial = dataInicial.split("-");

    this.dataBuscaFinal = final[0] + "-" + final[1] + "-" + (final[2]);
    this.dataBuscaInicial = inicial[0] + "-" + inicial[1] + "-" + (inicial[2]);


/*  today = today.replace("-", "/");
    today = today.replace("-", "/");
    console.log("today: " + today); */
    
  }
  ngOnInit() {
    let paginaRota = this.route.snapshot.paramMap.get("pagina");
    let qtdPorPagina = this.route.snapshot.paramMap.get("qtdPorPagina");
    //verificamos se a rota com a paginação é nula, caso seja o
    //valor não é atribuido a variavel pagina attual.
    if(qtdPorPagina != null){
      this.itensPorPagina = qtdPorPagina;
    }
    if(paginaRota != null){
      this.paginaAtual = paginaRota;
      this.getListaCompra(paginaRota);
    }else{
      this.getListaCompra(1);
    }

/*     this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      console.log(event.url);

      let d = event.url.split('/');

      let paginaN = d[3];
      let qtd = d[4];

      this.itensPorPagina = qtd;
      this.paginaAtual = paginaN;
      console.log("d : " + event.url);
      if(d[2]==="compras"){
        this.getListaCompra(this.paginaAtual);
      }
    }); */



  }
  public getListaCompra(paginaAtual){

    if(!this.requestAtiva){
      this.requestAtiva = true;
      this.progressbarAtivo = true;

      this.httpRastreadorService.getListaCompra(this.tipoFiltro, this.dataBuscaInicial, this.dataBuscaFinal, paginaAtual, this.itensPorPagina, this.termoBusca, this.orderBy, this.order).subscribe((data)=>{
        let d:any = data;
        this.arrCompra = d.data;
        this.buscaRealizada = true;
        this.progressbarAtivo = false;
        this.totalCompra = d.total;
        this.paginasProximas = [];
        this.paginasAnteriores = [];
        //###################################################
        //Criação das próximas páginas
        let prox = Number(paginaAtual) + 1;

        for( let i = prox; i <= d.last_page; i++){
          if(this.paginasProximas.length < this.linksPorPagina){
            this.paginasProximas.push(i);
          }
        }
        //###################################################
        //###################################################
        //###################################################
        //Criação das páginas anteriores
        let ant = paginaAtual - this.linksPorPagina;
        for( let j = ant; j < paginaAtual; j++){
          if(j>0){
            this.paginasAnteriores.push(j);
          }
        }
        //###################################################
        //###################################################
        this.ultimaPagina = d.last_page;
        this.paginaAtual = paginaAtual;
        this.buscaRealizada = true;
        this.scrollTop();

        this.arrCompra.map((d, index)=>{
          this.getProdutosCompra(d.compra_id, index);
        });

        this.requestAtiva = false;

      }, error=>{
        this.requestAtiva = false;
      });
    }
  }
  orderList(){
    if(this.order == this.orderAsc){
      this.order= this.orderDesc;
    }else{
      this.order= this.orderAsc;
    }
    this.getListaCompra(this.paginaAtual);
  }
  setQtdItensPorPagina(quantidade){
    this.itensPorPagina = quantidade;
    this.changeRoute(this.paginaAtual);
    this.getListaCompra(1);
  }
  changeRoute(paginaAtual:any) {
    /*this.router.navigate(['admin/compras/'+paginaAtual]); */
    this.location.go( 'admin/compras/' + paginaAtual + '/' + this.itensPorPagina);
  }
  buscaNovaLista(paginaAtual:any){
    if(paginaAtual > 0){
      this.changeRoute(paginaAtual);
      this.getListaCompra(paginaAtual);
    }
  }
  scrollTop(){
    document.getElementById("mat-drawer-content").scrollTop -= 10000;
  }
  buscaProximaPagina(){
    let pag = Number(this.paginaAtual) + 1;
    if(pag <= this.ultimaPagina){
      this.changeRoute(pag)
      this.getListaCompra(pag);
    }
  }
  buscaPaginaAnterior(paginaAtual:any){
    let pag = Number(paginaAtual) -1;
    if(pag > 0){
      this.changeRoute(pag);
      this.getListaCompra(pag);
    }
  }
  checkIsPar(numero:any){
    let resultado = numero % 2;
    if(resultado == 0){
      return true;
    }else{
      return false;
    }
  }

@ViewChild("myInputSearch", {static:false}) inputEl: ElementRef;
  focusInputSearch() {
    this.inputEl.nativeElement.focus()
  }
  saveDate(event: any) {
    // look at how the date is emitted from save 
    try {
      this.dataBuscaInicial = event.target.value.begin._i.year + "-" + (event.target.value.begin._i.month + 1) + "-" + event.target.value.begin._i.date;
      this.dataBuscaFinal = event.target.value.end._i.year + "-" + (event.target.value.end._i.month + 1) + "-" + (event.target.value.end._i.date +1);
      this.getListaCompra(this.paginaAtual);
    } catch (error) {
      
    }
  
  }
  getProdutosCompra(idVenda:any, indexData:any){

    this.progressbarAtivo = true;
    this.httpRastreadorService.getProdutosCompra(idVenda).subscribe((data:any)=>{


      let arrProd:CompraProduto[] = [];


      data.map((d, index)=>{
        let prod:CompraProduto = {
          compra_produto_id:d.id_produto,
          compra_produto_id_compra:null,
          compra_produto_quantidade:null,
          compra_produto_unidade_medida:null,
          compra_venda_produto_id:null,
          produto_comprado_id:null,
          produto_comprado_nome:d.produto_nome,
          produto_comprado_imagem:null,
          produto_comprado_valor:null,
          produto_comprado_cod_rastreio:d.rastreio_id,
          produto_plu:null,
          fornecedor_compra:d.fornecedor_nome,
          compra_produto_lote:null
        }
        
        arrProd.push(prod);

/*         if(this.arrCompra[indexData].compra_fornecedor_nome === null){
         this.arrCompra[indexData].compra_fornecedor_nome = d.fornecedor_nome;
        }else{
          this.arrCompra[indexData].compra_fornecedor_nome = this.arrCompra[indexData].compra_fornecedor_nome + ", " + d.fornecedor_nome;
        } */


        if(this.arrCompra[indexData].compra_nota_fiscal === null){
          this.arrCompra[indexData].compra_nota_fiscal = d.nota_fiscal_compra;
         }else{
           this.arrCompra[indexData].compra_nota_fiscal = this.arrCompra[indexData].compra_nota_fiscal + ", " + d.nota_fiscal_compra;
         }


      });


      this.arrCompra[indexData].compra_produtos = arrProd;

      this.progressbarAtivo = false;
    }, error=>{
      this.progressbarAtivo = false;
    });
  }
  displayHideFabButton(){
    this.showFabButton = !this.showFabButton;
  }
}
